import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Phone from "./Phone";
import ShortText from "./ShortText";
import React from "react";
import ProductList from "./ProductList";
import Address from "./Address";
import LongText from "./LongText";

const SpiritWearOrderForm = () =>{


    const productData = [
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '10000',
            quantity: 1,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '20000',
            quantity: 2,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '30000',
            quantity: 3,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '40000',
            quantity: 4,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '50000',
            quantity: 5,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '60000',
            quantity: 6,
        },
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم سفارش لباس تیمی'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <ProductList header={'فروشگاه مدرسه'} product={productData}/>
                <FullName header={'نام دانش آموز'}/>
                <ShortText header={'پردیس'}/>
                <ShortText header={'بخش'}/>
                <ShortText header={'کلاس'}/>
                <FullName header={'نام معلم'}/>
                <FullName header={'نام والدین/سرپرست'}/>
                <Phone header={'شماره تماس'}/>
                <Address header={'آدرس حمل و نقل'}/>
                <LongText header={'نظرات'}/>
            </Grid>
        </Grid>
    )
}

export default SpiritWearOrderForm