import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import Email from './Email'
import Phone from './Phone'
import ShortText from './ShortText'
import Appointment from './Appointment'
import DropDown from './DropDown'
import LongText from './LongText'

const ReservationForm = () => {


    const typeResevation = [
        'شام',
        'VIP',
        'تولد / سالگرد',
        'زندگی شبانه',
        'خصوصی',
        'عروسی',
        'شرکتی',
        'تعطیلات',
        'دیگر',
    ]

    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم رزرو'} subHeader={'لطفاً فرم زیر را به دقت پر کنید تا بتوانیم خدمات بهتری به شما ارائه دهیم!.. خوش آمدید!'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>

                <FullName header={'نام و نام خانوادگی'} />
                <Email header={'ایمیل'} />
                <Phone header={'شماره تلفن'} />
                <ShortText header={'#مهمانان'} />
                <Appointment header={'رزرو'} />
                <DropDown header={'نوع رزرو'} options={typeResevation} />
                <ShortText header={'اگر موارد دیگر در بالا، لطفا مشخص کنید'} />
                <LongText header={'هر گونه درخواست خاص'} />

            </Grid>
        </Grid>
    )
}

export default ReservationForm