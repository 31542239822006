import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import Email from './Email'
import Phone from './Phone'
import Address from './Address'
import DatePickers from './DatePicker'
import SingleChoice from './SingleChoice'
import LongText from './LongText'

const MedicalQuestionnaire = () => {


    const yesOrNoData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'پرسشنامه پزشکی'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>

                <FullName header={'نام و نام خانوادگی'} />
                <Email header={'ایمیل'} />
                <Phone header={'شماره تماس'} />
                <Address header={'آدرس'}/>
                <DatePickers header={'تاریخ تولد'}/>
                <SingleChoice header={'آیا پزشک شما تا به حال گفته است که فشار خون شما خیلی بالا یا خیلی پایین است؟'} options={yesOrNoData}/>
                <LongText header={'لطفا جزئیات را ارائه دهید.'}/>
                <SingleChoice header={'آیا مشکلات قلبی عروقی شناخته شده ای دارید (ECG غیر طبیعی، حمله قلبی قبلی و غیره)؟'} options={yesOrNoData}/>
                <SingleChoice header={'آیا پزشکتان تا به حال به شما گفته است که کلسترول شما خیلی بالاست؟'} options={yesOrNoData}/>
                <SingleChoice header={'آیا تا به حال به شما (یا یکی از اعضای خانواده) گفته شده که دیابت دارید؟'} options={yesOrNoData}/>
                <SingleChoice header={'آیا آسیب یا مشکلات ارتوپدی (کمر، زانو و غیره) دارید؟'} options={yesOrNoData}/>
                <SingleChoice header={'آیا مفاصل سفت یا متورم دارید؟'} options={yesOrNoData}/>
                <SingleChoice header={'آیا در ناحیه ای تنش یا درد دارید؟'} options={yesOrNoData}/>
                <SingleChoice header={'آیا از داروهای تجویز شده یا مکمل های غذایی استفاده می کنید؟'} options={yesOrNoData}/>
                <SingleChoice header={'آیا تا به حال مشکل خواب داشته اید'} options={yesOrNoData}/>
                <SingleChoice header={'آیا باردار هستید یا پس از زایمان (<6 هفته)؟'} options={yesOrNoData}/>
                <SingleChoice header={'آیا تا به حال توسط پزشک، پزشک یا متخصص به شما توصیه شده است که هیچ نوع ورزش/فعالیتی را انجام ندهید؟'} options={yesOrNoData}/>
                <SingleChoice header={'آیا وضعیت پزشکی، آسیب یا هر چیز دیگری دارید که باید از آن آگاه باشیم که به آن اشاره نکرده ایم؟'} options={yesOrNoData}/>

            </Grid>
        </Grid>
    )
}

export default MedicalQuestionnaire