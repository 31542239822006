import React from 'react'
import Heading from './Heading'
import { Grid } from '@mui/material'
import FullName from './FullName'
import Email from './Email'
import DropDown from './DropDown'
import Number from './Number'
import DatePickers from './DatePicker'
import Time from './Time';
import SingleChoice from './SingleChoice';
import ShortText from './ShortText';
import LongText from './LongText'

const HotelBookingForm = () => {


    const roomData = 
    [
        'اتاق استاندارد (1 تا 2 نفر)',
        'اتاق خانوادگی (1 تا 4 نفر)',
        'اتاق خصوصی (1 تا 3 نفر)',
        'اتاق خوابگاه مختلط (6 نفر)',
        'اتاق خوابگاه زنانه (6 نفر)',
        'اتاق خوابگاه مردانه (6 نفر)',
    ]


    const freeCarData = [
        { id: 1, label: 'بله لطفا! - در بدو ورود مرا ببر', checked: false },
        { id: 2, label: '"نه، ممنون. خودم راه رو پیدا می‌کنم."', checked: false },
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'رزرو هتل'} subHeader={'هر لحظه چیز جدیدی را تجربه کنید'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'} />
                <Email header={'ایمیل'} />
                <DropDown header={'نوع اتاق'} options={roomData} />
                <Number header={'تعداد مهمانان'} />
                <DatePickers header={'تاریخ ورود'}/>
                <Time header={'زمان ورود'}/>
                <DatePickers header={'تاریخ حرکت'}/>
                <SingleChoice header={'ماشین رایگان؟'} options={freeCarData}/>
                <ShortText header={'شماره پرواز'}/>
                <LongText header={'درخواست های ویژه'}/>
            </Grid>
        </Grid>
    )
}

export default HotelBookingForm