import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import SingleChoice from './SingleChoice'
import MultiChoice from './MultiChoice'
import LongText from './LongText'
import ScaleRating from './ScaleRating'

const SoftwareSurveyForm = () => {



    const hearAboutSoftwareData = [
        {
            id: 1,
            label: "ایمیل",
            checked: false
        },
        {
            id: 2,
            label: 'شفاهی',
            checked: false
        },
        {
            id: 3,
            label: 'رسانه های اجتماعی',
            checked: false
        },
        {
            id: 4,
            label: 'تبلیغات',
            checked: false
        },
        {
            id: 5,
            label: 'جستجو',
            checked: false
        },
    ]


    const osData = [
        {
            id: 1,
            label: "Windows",
            checked: false
        },
        {
            id: 2,
            label: 'Mac OS',
            checked: false
        },
        {
            id: 3,
            label: "Linux",
            checked: false
        },
        {
            id: 4,
            label: 'سایر',
            checked: false
        },
    ]


    const yesOrNoData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]



    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'بررسی نرم افزار'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>

                <SingleChoice header={'چگونه درباره {نام نرم افزار} ... شنیدید؟'} options={hearAboutSoftwareData}/>
                <MultiChoice header={'از کدام پلتفرم استفاده می کنید؟'} options={osData}/>
                <ScaleRating header={'به {نام نرم افزار} ... چه امتیازی می دهید؟'} />
                <SingleChoice header={'آیا {نام نرم افزار} را خریداری کردید؟'} options={yesOrNoData}/>
                <LongText header={'لطفا نظرات منفی/مثبت را با ما در میان بگذارید'}/>

            </Grid>
        </Grid>
    )
}

export default SoftwareSurveyForm