import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import Email from './Email'
import Phone from './Phone'
import MultiChoice from './MultiChoice'
import SingleChoice from './SingleChoice'
import ShortText from './ShortText'
import LongText from './LongText'
import DropDown from './DropDown'
import Address from './Address'
import Captcha from './Captcha'

const RealEstateContactForm = () => {


    const yesOrNoData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]



    const callData = [
        {
            id: 1,
            label: "صبح",
            checked: false
        },
        {
            id: 2,
            label: "بعد از ظهر",
            checked: false
        },
        {
            id: 2,
            label: "عصر",
            checked: false
        },
    ]


    const soonSellData = [
        {
            id: 1,
            label: "در اسرع وقت",
            checked: false
        },
        {
            id: 2,
            label: 'ظرف 30 روز',
            checked: false
        },
        {
            id: 3,
            label: "ظرف 90 روز",
            checked: false
        },
        {
            id: 4,
            label: "من در حال بررسی گزینه ها هستم",
            checked: false
        },
    ]


    const typeEstateData = [
        'خانه خانواده مجردی',
        'خانه شهر',
        'خانه تولید شده',
        'سایر',
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم اطلاعات فروشنده'} subHeader={'برای دریافت پیشنهاد خود این فرم را تکمیل کنید!'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>

                <FullName header={'نام و نام خانوادگی'} />
                <Email header={'ایمیل'} />
                <Phone header={'شماره تلفن'} />
                <MultiChoice header={'بهترین زمان برای تماس با شما'} options={callData}/>
                <MultiChoice header={'چقدر زود به فروش نیاز دارید'} options={soonSellData}/>
                <SingleChoice header={'آیا در حال حاضر در خانه زندگی می کنید؟'} options={yesOrNoData}/>
                <ShortText header={'چقدر زود باید حرکت کنید'}/>
                <LongText header={'چرا میفروشید؟'} />
                <DropDown header={'چه نوع ملکی'} options={typeEstateData}/>
                <Address />
                <Captcha header={'پیام را همانطور که نشان داده شده وارد کنید*'}/>

            </Grid>
        </Grid>
    )
}

export default RealEstateContactForm