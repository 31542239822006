import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import Phone from "./Phone";
import DatePickers from "./DatePicker";
import SingleChoice from "./SingleChoice";
import ShortText from "./ShortText";
import Number from "./Number";
import React from "react";
import Address from "./Address";

const TravelInquiryForm = () =>{

    const yesOrNoData = [
        {
            id: 1,
            label: "بله، لطفا بیمه مسافرتی را اضافه کنید",
            checked: false
        },
        {
            id: 2,
            label: "نه، بیمه مسافرتی را رد می کنم",
            checked: false
        },
    ]

    return (

        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم درخواست سفر'} subHeader={'از اینکه ما را برای برنامه ریزی تعطیلات بعدی خود انتخاب کردید متشکریم. لطفاً این فرم را تکمیل کنید تا بتوانیم سفر عالی را انجام دهیم.'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <Phone header={'شماره تلفن'} />
                <Email header={'ایمیل'}/>
                <Address header={'آدرس'}/>
                <ShortText header={'مقصد مورد علاقه'}/>
                <ShortText header={'بودجه تعطیلات'}/>
                <Number header={'تعداد مسافران'}/>
                <ShortText header={'شهر مبدأ'}/>
                <DatePickers header={'تاریخ سفر'}/>
                <DatePickers header={'تاریخ پایان سفر'}/>
                <SingleChoice header={'آیا بیمه مسافرتی می خواهید؟'} options={yesOrNoData}/>
            </Grid>
        </Grid>

    )
}

export default TravelInquiryForm;