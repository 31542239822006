import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import ShortText from "./ShortText";
import Phone from "./Phone";
import Email from "./Email";
import Captcha from "./Captcha";
import React from "react";
import Address from "./Address";
import DatePickers from "./DatePicker";

const SignupForm = ()=>{
    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'درخواست عضویت'} subHeader={'برای درخواست عضویت لطفا تمام سوالات را تکمیل کنید.'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <Address/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره خانه'}/>
                <Phone header={'شماره تلفن همراه'}/>
                <Phone header={'شماره کار'}/>
                <ShortText header={'وب سایت'}/>
                <ShortText header={'مدت عضویت'}/>
                <ShortText header={'سطح عضویت'}/>
                <Captcha header={'لطفاً تأیید کنید که انسان هستید'}/>
                <DatePickers header={'تاریخ عضویت'}/>
            </Grid>
        </Grid>
    )
}

export default SignupForm;