import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import React from "react";
import ShortText from "./ShortText";
import Phone from "./Phone";
import MultiChoice from "./MultiChoice";
import FileUpload from "./FileUpload";
import LongText from "./LongText";

const ClientQuestionnaire = () => {


    const servicesData = [
        {
            id: 1,
            label: 'خدمات مشتری',
            checked: false
        },
        {
            id: 2,
            label: 'حسابداری',
            checked: false
        },
        {
            id: 3,
            label: 'استخدام',
            checked: false
        },
        {
            id: 4,
            label: 'منابع انسانی',
            checked: false
        },
        {
            id: 5,
            label: 'مدیریت فایل',
            checked: false
        },
        {
            id: 6,
            label: 'امنیت',
            checked: false
        },

    ]



    const hearAboutUsData = [
        {
            id: 1,
            label: 'مراجعه',
            checked: false
        },
        {
            id: 2,
            label: 'پست مستقیم',
            checked: false
        },
        {
            id: 3,
            label: 'تبلیغات آنلاین',
            checked: false
        },
        {
            id: 4,
            label: 'چاپ آگهی',
            checked: false
        },
        {
            id: 5,
            label: 'سایر',
            checked: false
        },

    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'پرسشنامه مشتری'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={"نام و نام خانوادگی"}/>
                <ShortText header={'عنوان'}/>
                <ShortText header={'بخش'}/>
                <ShortText header={'نام شرکت'}/>
                <ShortText header={'صنعت'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن'}/>
                <MultiChoice header={'به کدام یک از خدمات ما علاقه دارید؟'} options={servicesData}/>
                <MultiChoice header={'چطور در مورد ما شنیدی؟'} options={hearAboutUsData}/>
                <FileUpload header={'پیوست'}/>
                <LongText header={'یادداشت شما'}/>
            </Grid>
        </Grid>
    )
}


export default ClientQuestionnaire;