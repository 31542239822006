import {Grid} from "@mui/material";
import Heading from "./Heading";
import React from "react";
import FullName from "./FullName";
import SingleChoice from "./SingleChoice";
import Number from "./Number";
import MultiChoice from "./MultiChoice";
import DatePickers from "./DatePicker";
import Address from "./Address";
import Email from "./Email";
import Phone from "./Phone";
import DropDown from "./DropDown";
import ShortText from "./ShortText";

const MedicalInsuranceApplication = ()=>{


    const genderData =
        [
        {
            id: 1,
            label: "آقا",
            checked: false
        },
            {
                id: 2,
                label: "خانم",
                checked: false
            },
        ]



    const patientStatusData = [
        {
            id: 1,
            label: "مجرد",
            checked: false
        },
        {
            id: 2,
            label: "متاهل",
            checked: false
        },
        {
            id: 3,
            label: "دانشجو",
            checked: false
        },
        {
            id: 4,
            label: "استخدام",
            checked: false
        },
        {
            id: 5,
            label: "سایر",
            checked: false
        },
    ]



    const partnerOrChildrenData = ['همسر' , 'فرزندان']


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'درخواست بیمه پزشکی'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
               <FullName header={'نام و نام خانوادگی'}/>
                <SingleChoice header={'جنسیت'} options={genderData}/>
                <Number header={'سن'}/>
                <MultiChoice header={'وضعیت بیمار'} options={patientStatusData}/>
                <DatePickers header={'تاریخ تولد'}/>
                <Address header={'آدرس'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن (خانه)'}/>
                <Phone header={'شماره تلفن (موبایل)'}/>
                <Heading header={'سایر متقاضیان تحت پوشش - شریک / فرزندان'}/>
                <DropDown header={'عنوان'} options={partnerOrChildrenData}/>
                <ShortText header={'نام و نام خانوادگی'}/>
                <SingleChoice header={'جنسیت'} options={genderData}/>
                <DatePickers header={'تاریخ تولد'}/>
                <DropDown header={'عنوان'} options={patientStatusData}/>
                <ShortText header={'نام و نام خانوادگی'}/>
                <SingleChoice header={'جنسیت'} options={genderData}/>
                <DatePickers header={'تاریخ تولد'}/>
            </Grid>
        </Grid>

    )

}



export default MedicalInsuranceApplication;
