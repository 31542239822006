import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Number from "./Number";
import ShortText from "./ShortText";
import Email from "./Email";
import Phone from "./Phone";
import DropDown from "./DropDown";
import LongText from "./LongText";
import Captcha from "./Captcha";
import React from "react";
import DatePickers from "./DatePicker";
import Time from "./Time";
import Spinner from "./Spinner";
import MultiChoice from "./MultiChoice";


const TrainingRoomBookingForm = ()=>{


    const EquipementRequired = [
        {
            id: 1, label: 'پروژکتور', checked: false
        },
        {
            id: 2, label: 'کامپیوتر و دسترسی به اینترنت', checked: false
        },
        {
            id: 3, label: 'ناهار - اعداد و نیازهای غذایی را اضافه کنید لطفاً در زیر اضافه کنید', checked: false
        },
        {
            id: 4, label: 'سایر', checked: false
        },
    ]



    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم رزرو اتاق آموزشی'} subHeader={'برای رزرو اتاق اصلی آموزشی در چسترفیلد، لطفاً از این فرم استفاده کنید'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'} />
                <Email header={'ایمیل'} />
                <ShortText header={'بخش'} />
                <Heading header={'تاریخ شروع جلسه'}/>
                <DatePickers header={'تاریخ شروع جلسه'}/>
                <Time header={'زمان شروع جلسه'}/>
                <Heading header={'تاریخ پایان جلسه'}/>
                <DatePickers header={'تاریخ پایان جلسه'}/>
                <Time header={'زمان پایان جلسه'}/>
                <Spinner header={'تعداد حاضرین'}/>
                <MultiChoice header={'تجهیزات مورد نیاز'} options={EquipementRequired}/>
                <LongText header={'اگر تیک ناهار یا موارد دیگر را زده اید، لطفا تکمیل کنید'} />
            </Grid>
        </Grid>

    )


}



export default TrainingRoomBookingForm