import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Phone from "./Phone";
import Email from "./Email";
import LongText from "./LongText";
import React from "react";

const InformationRequestForm = ()=>{
    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'درخواست اطلاعات'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن'}/>
                <LongText header={'درخواست اطلاعات درباره'}/>
                <LongText header={'دستورالعمل های اضافی'}/>
            </Grid>
        </Grid>
    )
}


export default InformationRequestForm;