import React from 'react'
import { Grid } from '@mui/material';
import Heading from './Heading';
import FullName from './FullName';
import Phone from './Phone';
import Email from './Email';
import DropDown from './DropDown';
import Appointment from './Appointment';

const TutorAppointmentForm = () => {



    const TypeOfTutoringData = [
        'تدریس خصوصی آنلاین',
        'آموزش خصوصی در آدرس توافق شده',
        'تدریس خصوصی در منزل',
    ]


    const SubjectForTutoring = [
        'ریاضی',
        'فیزیک',
        'شیمی',
        'ادبیات',
        'زیست شناسی',
        'تاریخ',
    ] 


    const grade = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={"فرم قرار ملاقات معلم خصوصی"} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام دانش آموز'} />
                <Phone header={'شماره تلفن والدین'}/>
                <Email header={'ایمیل والدین'} />
                <DropDown header={'نوع تدریس خصوصی'} options={TypeOfTutoringData} />
                <DropDown header={'موضوع برای تدریس خصوصی'} options={SubjectForTutoring} />
                <DropDown header={'درجه'} options={grade} />
                <Appointment header={'قرار ملاقات'} />
            </Grid>
        </Grid>
    )
}

export default TutorAppointmentForm