import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import Phone from "./Phone";
import React from "react";
import Address from "./Address";
import ProductList from "./ProductList";
import SingleChoice from "./SingleChoice";
import DatePickers from "./DatePicker";
import LongText from "./LongText";

const OnlineShoppingForm = ()=>{


    const productListData = [

        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'محصول 1',
            description: 'مواد تشکیل دهنده',
            image: null,
            price: '150000',
            quantity: 3,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'محصول 2',
            description: 'مواد تشکیل دهنده',
            image: null,
            price: '130000',
            quantity: 1,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'محصول 3',
            description: 'مواد تشکیل دهنده',
            image: null,
            price: '150000',
            quantity: 4,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'محصول 4',
            description: 'مواد تشکیل دهنده',
            image: null,
            price: '80000',
            quantity: 1,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'محصول 5',
            description: 'مواد تشکیل دهنده',
            image: null,
            price: '90000',
            quantity: 2,
        },

    ]


    const paymentData = [
        {
            id: 1,
            label: "پرداخت در محل",
            checked: false
        },
        {
            id: 2,
            label: "پرداخت آنلاین",
            checked: false
        },
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم خرید آنلاین'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <Heading header={'اطلاعات مشتری:'}/>
                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'ایمیل'}/>
                <Phone header={' شماره تلفن'} />
                <Address header={'آدرس حمل و نقل'}/>
                <ProductList header={'انتخاب محصول'} product={productListData}/>
                <SingleChoice header={'روش پرداخت'} options={paymentData}/>
                <DatePickers header={'تاریخ تحویل ترجیحی'}/>
                <LongText header={'دستورالعمل های ویژه'}/>
                <DatePickers header={'تاریخ سفارش'}/>
            </Grid>
        </Grid>
    )
}


export default OnlineShoppingForm;