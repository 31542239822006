import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import ShortText from "./ShortText";
import DatePickers from "./DatePicker";
import React from "react";
import DropDown from "./DropDown";
import Phone from "./Phone";
import SingleChoice from "./SingleChoice";
import Address from "./Address";


const SchoolRegistrationForm = () => {


    const genderData = ['آقا', 'خانم']



    const semesterData = [
        'آفریقایی آمریکایی',
        'اسپانیایی/لاتینی',
        'آسیایی',
        'قفقازی',
        'بومی آمریکا/آلاسکا',
        'ساکن جزیره هاوایی/اقیانوس آرام',
        'خاورمیانه',
        'ترجیح می دهید پاسخ ندهید',
        'سایر'
    ]



    const yesOrNoData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'ثبت نام مدرسه'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'} />
                <DatePickers header={'تاریخ تولد'}/>
                <DropDown header={'جنسیت'} options={genderData}/>
                <DropDown header={'قومیت'} options={semesterData}/>
                <Email header={'ایمیل'} />
                <Phone header={'شماره تلفن'}/>
                <ShortText header={'رتبه'} />
                <ShortText header={'ترم'} />
                <SingleChoice header={'آیا قبلاً برای این مدرسه درخواست داده اید یا در آن شرکت کرده اید؟'} options={yesOrNoData}/>
                <Heading header={'اطلاعات محل سکونت فعلی'}/>
                <Address />
                <Phone header={'شماره تلفن خانه'}/>
                <Heading header={'اطلاعات محل سکونت اولیه (در صورت متفاوت بودن با بالا)'}/>
                <Address />
                <Heading header={'اطلاعات محل اقامت والدین/سرپرست (در صورت متفاوت بودن با بالا)'}/>
                <Address />
            </Grid>
        </Grid>
    )
}





export default SchoolRegistrationForm