import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import DatePickers from "./DatePicker";
import Email from "./Email";
import Phone from "./Phone";
import React from "react";
import Number from "./Number";
import {Address, LongText} from "./Icons";
import ShortText from "./ShortText";

const EmergencyContactFormTemplate = () => {
    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم تماس اضطراری'} subHeader={'ارائه اطلاعات حیاتی برای ایمنی و رفاه کارکنان'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <Heading header={'اطلاعات شخصی'}/>
                <FullName header={'نام و نام خانوادگی'}/>
                <Number header={'شماره کارمند'}/>
                <Phone header={'تلفن خانه'}/>
                <Phone header={'تلفن همراه'}/>
                <Email header={'ایمیل'}/>
                <DatePickers header={'تاریخ تولد'}/>
                <Address header={'آدرس'}/>

                <Heading header={'تماس اضطراری'}/>
                <FullName header={'نام و نام خانوادگی'}/>
                <ShortText header={'نسبت'}/>
                <Phone header={'تلفن خانه'}/>
                <Phone header={'تلفن همراه'}/>
                <Phone header={'تلفن محل کار'}/>
                <Email header={'ایمیل'}/>

                <Heading header={'تماس اضطراری ثانویه'}/>
                <FullName header={'نام و نام خانوادگی'}/>
                <ShortText header={'نسبت'}/>
                <Phone header={'تلفن خانه'}/>
                <Phone header={'تلفن همراه'}/>
                <Phone header={'تلفن محل کار'}/>
                <Email header={'ایمیل'}/>

                <Heading header={'اطلاعات پزشکی'}/>
                <FullName header={'پزشک اولیه'}/>
                <ShortText header={'مرکز پزشکی'}/>
                <Phone header={'شماره تلفن'}/>
                <Address header={'آدرس'}/>
                <LongText header={'اطلاعات تکمیلی'}/>
            </Grid>
        </Grid>
    )
}


export default EmergencyContactFormTemplate;