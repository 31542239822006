import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Phone from "./Phone";
import Email from "./Email";
import Address from "./Address";
import React from "react";
import LongText from "./LongText";

const ProductSurveyForm = ()=>{
    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'اطلاعات مشتری:'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام مشتری'}/>
                <Address header={'آدرس'}/>
                <Phone header={'شماره تماس'}/>
                <Email header={'ایمیل'}/>
                <LongText header={'چه مدت از این محصول استفاده می کنید و چرا؟'}/>
                <LongText header={'نظرات و پیشنهادات خود را در مورد محصولات ما در مقایسه با سایر رقبا بنویسید:'}/>
                <LongText header={'آیا از عملکرد محصول ما راضی هستید؟ نظرات خود را به اشتراک بگذارید:'}/>
                <LongText header={'درباره تجارب خرید خود برای خرید محصول ما چیزی به ما بگویید:'}/>
                <LongText header={'آیا می خواهید با محصول ما ادامه دهید؟ اگر نه چرا:'}/>
                <LongText header={'دوست دارید چه نوع تغییراتی را در محصولات ما مشاهده کنید تا سطح رضایت شما را افزایش دهد؟'}/>
            </Grid>
        </Grid>
    )
}


export default ProductSurveyForm;