import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import Phone from './Phone'
import Email from './Email'
import ShortText from './ShortText'
import DropDown from './DropDown'
import Appointment from './Appointment'
import LongText from './LongText'

const FreeClientConsultation = () => {


    const  consultationInterest  = [
        'راه حل های شبکه',
        'راهکارهای سرور',
        'توسعه کسب و کار',
        'تدارکات',
        'تلفن',
        'طراحی وب',
        'پشتیبانی',
        'دیگر',
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم مشاوره رایگان'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'} />
                <Phone header={'شماره تلفن'} />
                <Email header={'ایمیل'} />
                <ShortText header={'نام شرکت یا سازمان'}/>
                <DropDown header={'تمایل به مشاوره'} options={consultationInterest} />
                <Appointment header={"لطفاً تاریخ و زمان قرار ملاقات را انتخاب کنید"} />
                <LongText header={"اطلاعات/توضیحات اضافی"}/>
            </Grid>
        </Grid>
    )
}

export default FreeClientConsultation