import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import DatePickers from './DatePicker'
import Time from './Time'
import FullName from './FullName'
import Address from './Address'
import Email from './Email'
import Phone from './Phone'
import ShortText from './ShortText'
import LongText from './LongText'
import Number from './Number'
import SingleChoice from './SingleChoice'

const OnlineStoreOrderForm = () => {


    const howAboutUs = [
        {
            id: 1,
            label: "وب سایت",
            checked: false
        },
        {
            id: 2,
            label: "رسانه های اجتماعی",
            checked: false
        },
        {
            id: 2,
            label: "ارجاع شده توسط یکی از دوستان",
            checked: false
        },
    ]

    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم سفارش فروشگاه اینترنتی'} subHeader={'لطفا تمام فیلدها را پر کنید ممکن است سفارشات شما با اطلاعات ناقص پردازش نشوند.'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flexstart'} justifyContent={'flexstart'}>

                <DatePickers header={'تاریخ ثبت سفارش'}/>
                <Time header={'زمان ثبت سفارش'}/>
                <FullName header={'نام و نام خانوادگی'} />
                <Address header={'آدرس حمل و نقل'} />
                <Phone header={'شماره تماس'} />
                <Email header={'ایمیل'} />
                <ShortText header={'حساب بانکی'}/>
                <LongText header={'سفارش/واحد'}/>
                <Number header={'مقدار'} placeholder={23}/>
                <ShortText header={'قیمت واحد / کل پرداخت ها'}/>
                <LongText header={'مشخصات (رنگ، ​​اندازه و ...)'}/>
                <LongText header={'لینک سفارش (برای مشخص کردن سفارش خود URL را کپی کنید)'}/>
                <ShortText header={'نحوه پرداخت'}/>
                <SingleChoice header={'چطور درباره ما شنیدید'} options={howAboutUs}/>

            </Grid>
        </Grid>
    )
}

export default OnlineStoreOrderForm