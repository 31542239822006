import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import Phone from "./Phone";
import Appointment from "./Appointment";
import LongText from "./LongText";
import React from "react";
import DropDown from "./DropDown";

const AppointmentForm = () => {

    const methodContactingData = [
        'ایمیل',
        'تلفن'
    ]


    const timeReachData = [
        'صبح',
        'بعد از ظهر',
        'عصر',
        'شب',
    ]

    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم قرار ملاقات'} subHeader={'برای تعیین وقت، لطفا اطلاعات زیر را پر کنید.'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <Heading header={'جزئیات قرار ملاقات'}/>
                <Appointment header={'لطفاً تاریخ ملاقات را انتخاب کنید'}/>
                <Heading header={'اطلاعات تماس'}/>
                <FullName header={'نام و نام خانوادگی'}/>
                <Phone header={'شماره تماس'}/>
                <Email header={' ایمیل'}/>
                <DropDown header={'بهترین روش برای تماس با شما؟'} options={methodContactingData}/>
                <DropDown header={'بهترین زمان برای تماس با شما؟'} options={timeReachData}/>
                <LongText header={'چگونه می توانیم به شما کمک کنیم؟'}/>
                <LongText header={'نکات تکمیلی:'}/>
            </Grid>
        </Grid>
    )
}

export default AppointmentForm;