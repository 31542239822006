import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import ShortText from "./ShortText";
import React from "react";
import Phone from "./Phone";
import Address from "./Address";
import MultiChoice from "./MultiChoice";

const WebinarRegistrationForm = () => {

    const howAboutUsData = [
        {
            id: 1,
            label: "رسانه های اجتماعی",
            checked: false
        },
        {
            id: 2,
            label: "دوستان و آشنایان",
            checked: false
        },
        {
            id: 3,
            label: "تبلیغات",
            checked: false
        },
    ]

    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم ثبت نام وبینار'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'تلفن'} />
                <ShortText header={'شرکت'}/>
                <Address header={'آدرس شرکت'}/>
                <ShortText header={'وب سایت شرکت'}/>
                <MultiChoice header={'چطور در مورد ما شنیدی؟'} options={howAboutUsData}/>
            </Grid>
        </Grid>
    )
}


export default WebinarRegistrationForm