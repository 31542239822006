
import React from "react";
import {Grid} from "@mui/material";
import Heading from "./Heading";
import DropDown from "./DropDown";
import Email from "./Email";
import Phone from "./Phone";
import ShortText from "./ShortText";
import SingleChoice from "./SingleChoice";
import Number from "./Number";
import LongText from "./LongText";


const DetailedRegistrationEventForm = ()=>{


    const positionData = [
        'دانشمند پژوهشگر',
        'پروفسور',
        'دکتر پزشکی (MD)',
        'دانشجوی دکتری (PhD)',
        'دانشجوی کارشناسی ارشد (کارشناسی ارشد)',
        'دانشجوی کارشناسی (لیسانس)',
        'دانش آموز دبیرستان',
        'سایر',
    ]


    const attendanceData = [
        'حسابرس (90 TL/45 €/50 $)',
        'دانشجوی ITU (40 TL/15 €/25 $)',
    ]


    const  accommodationData = [
        'خیر',
        '2 شب (27 الی 28 مرداد)',
        '3 شب (26-27-28 مرداد)',
        '4 شب (26-27-28-29 مرداد)',
    ]


    const workshopData = [
            {
                id: 1,
                label: "Q-PCR (20 نفر)",
                checked: false
            },
            {
                id: 2,
                label: "بیوانفورماتیک (20 نفر)",
                checked: false
            },
        ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم ثبت نام'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <Heading header={'مشخصات فردی'}/>
                <ShortText header={'نام'}/>
                <ShortText header={' نام خانوادگی'}/>
                <Number header={'شناسه پاسپورت/شماره شهروندی'}/>
                <Heading header={'وابستگی'}/>
                <DropDown header={'موقعیت'} options={positionData}/>
                <ShortText header={'دانشگاه/موسسه'}/>
                <ShortText header={'بخش'}/>
                <Heading header={'آدرس'}/>
                <ShortText header={'شهر'}/>
                <ShortText header={'کشور'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن'}/>
                <Heading header={'اطلاعات کنفرانس'}/>
                <DropDown header={'وضعیت حضور و غیاب'} options={attendanceData}/>
                <DropDown header={'اسکان'} options={accommodationData}/>
                <Heading header={'کارگاه های آموزشی'}/>
                <SingleChoice header={'کارگاه گروه اول'} options={workshopData}/>
                <LongText header={'نظرات'}/>
            </Grid>
        </Grid>
    )
}


export default DetailedRegistrationEventForm