import { createContext, useContext } from 'react';
import useLocalStorage from 'use-local-storage';

const TitleContext = createContext();

export const useTitleContext = () => useContext(TitleContext);

export const TitleProvider = ({ children }) => {

  const [title, setTitle] = useLocalStorage('title', 'فرم'); // 'فرم' is the default value

  return (
      <TitleContext.Provider value={{ title, setTitle }}>
        {children}
      </TitleContext.Provider>
  );
};
