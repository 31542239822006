import React, { createContext, useContext, useEffect } from 'react';
import useLocalStorage from 'use-local-storage';

const UniqueTitleContext = createContext();

export const UniqueTitleProvider = ({ children }) => {
    const [uniqueTitle, setUniqueTitle] = useLocalStorage('UniqueTitle', '');

    const generateTitle = () => {
        return `Form_${new Date().toISOString().replace(/[-:.T]/g, '').slice(0, 15)}`;
    };

    useEffect(() => {
        if (!uniqueTitle) {
            const newTitle = generateTitle();
            setUniqueTitle(newTitle);
        }
    }, [uniqueTitle, setUniqueTitle]);

    return (
        <UniqueTitleContext.Provider value={{ uniqueTitle }}>
            {children}
        </UniqueTitleContext.Provider>
    );
};

export const useUniqueTitle = () => {
    return useContext(UniqueTitleContext);
};
