import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import NewCustomerCard from '../Component/NewCustomerCard';
import ITServiceTicket from '../Component/ITServiceTicket';
import CustomSimpleOrderForm from '../Component/CustomSimpleOrderForm';
import CourseRegistrationForm from '../Component/CourseRegistrationForm';
import ClassRegistration from '../Component/ClassRegistration';
import BusinessRegistrationForm from '../Component/BusinessRegistrationForm';
import NewHardwareRequest from '../Component/NewHardwareRequest';
import FootballRegistrationForm from '../Component/FootballRegistrationForm';
import TourReservationForm from '../Component/TourReservationForm';
import HomeLoanApplication from '../Component/HomeLoanApplication';
import SelfEvaluationTemplate from '../Component/SelfEvaluationTemplate';
import EmployeeRegistrationForm from '../Component/EmployeeRegistrationForm';
import StudentRepresentativeNominationForm from '../Component/StudentRepresentativeNominationForm';
import OnlineDoctorAppointmentForm from '../Component/OnlineDoctorAppointmentForm';
import MedicalAppointmentForm from '../Component/MedicalAppointmentForm';
import PizzaOrderForm from '../Component/PizzaOrderForm';
import OnlineOrderFormWithoutPayment from '../Component/OnlineOrderFormWithoutPayment';
import HairdressersForm from '../Component/HairdressersForm';
import OnlineInterviewQuestionnaireForm from '../Component/OnlineInterviewQuestionnaireForm';
import HospitalPatientRegistrationForm from '../Component/HospitalPatientRegistrationForm';
import HotelBookingForm from '../Component/HotelBookingForm';
import SendFileWithCaptchaForm from '../Component/SendFileWithCaptchaForm';
import RestaurantEvaluationForm from '../Component/RestaurantEvaluationForm';
import TutorAppointmentForm from '../Component/TutorAppointmentForm';
import FreeClientConsultation from '../Component/FreeClientConsultation';
import MedicalQuestionnaire from '../Component/MedicalQuestionnaire';
import ReservationForm from '../Component/ReservationForm';
import RealEstateContactForm from '../Component/RealEstateContactForm';
import SoftwareSurveyForm from '../Component/SoftwareSurveyForm';
import HealthSurvey from '../Component/HealthSurvey';
import ProductRegistration from '../Component/ProductRegistration';
import OnlineStoreOrderForm from '../Component/OnlineStoreOrderForm';
import TrainingRoomBookingForm from "../Component/TrainingRoomBookingForm";
import SchoolRegistrationForm from "../Component/SchoolRegistrationForm";
import DetailedRegistrationEventForm from "../Component/DetailedRegistrationEventForm";
import MedicalInsuranceApplication from "../Component/MedicalInsuranceApplication";
import TravelContactForm from "../Component/TravelContactForm";
import DocumentUploadForm from "../Component/DocumentUploadForm";
import WebinarRegistrationForm from "../Component/WebinarRegistrationForm";
import TechnologySurveyForRemoteLearning from "../Component/TechnologySurveyForRemoteLearning";
import AirportPickupReservationForm from "../Component/AirportPickupReservationForm";
import ProductPurchaseOrderForm from "../Component/ProductPurchaseOrderForm";
import NewServerRequestForm from "../Component/NewServerRequestForm";
import ClientQuestionnaire from "../Component/ClientQuestionnaire";
import RealEstateMarketingQuestionnaire from "../Component/RealEstateMarketingQuestionnaire";
import InvestorsQuestionnaire from "../Component/InvestorsQuestionnaire";
import RequestAnAppointmentForm from "../Component/RequestAnAppointmentForm";
import PetServiceAppointmentForm from "../Component/PetServiceAppointmentForm";
import ProductSurveyForm from "../Component/ProductSurveyForm";
import TradeRegistrationForm from "../Component/TradeRegistrationForm";
import SignupForm from "../Component/SignupForm";
import WebsiteDesignAndDevelopmentForm from "../Component/WebsiteDesignAndDevelopmentForm";
import EducationClassRegistrationForm from "../Component/EducationClassRegistrationForm";
import EmergencyContactFormTemplate from "../Component/EmergencyContactFormTemplate";
import InformationRequestForm from "../Component/InformationRequestForm";
import AppointmentRequestForm from "../Component/AppointmentRequestForm";
import AppointmentForm from "../Component/AppointmentForm";
import ITServiceTicketForm from "../Component/ITServiceTicketForm";
import ServiceEnquiryForm from "../Component/ServiceEnquiryForm";
import ClientCallLog from "../Component/ClientCallLog";
import CopyrightRequestForm from "../Component/CopyrightRequestForm";
import TimeOffRequestForm from "../Component/TimeOffRequestForm";
import StudentProgressReportTemplate from "../Component/StudentProgressReportTemplate";
import CleaningServiceAgreementForm from "../Component/CleaningServiceAgreementForm";
import TelemedicineAppointmentForm from "../Component/TelemedicineAppointmentForm";
import PropertyInquiryForm from "../Component/PropertyInquiryForm";
import BakeryOrderInquiryForm from "../Component/BakeryOrderInquiryForm";
import HiringInquiry from "../Component/HiringInquiryForm";
import BakeryOrderInquiryFrom from "../Component/BakeryOrderInquiryForm";
import HiringInquiryForm from "../Component/HiringInquiryForm";
import GraphicDesignInquiryForm from "../Component/GraphicDesignInquiryForm";
import TransportationOrderInquiryForm from "../Component/TransportationOrderInquiryForm";
import OnlineShoppingForm from "../Component/OnlineShoppingForm";
import SpiritWearOrderForm from "../Component/SpiritWearOrderForm";
import ContractUploadForm from "../Component/ContractUploadForm";
import MedicalVolunteerApplication from "../Component/MedicalVolunteerApplication";
import MedicalHistoryForm from "../Component/MedicalHistoryForm";
import BookingEnquiryForm from "../Component/BookingEnquiryForm";
import OnlineJobApplicationForm from "../Component/OnlineJobApplicationForm";
import GenericProductOrderForm from "../Component/GenericProductOrderForm";
import TravelInquiryForm from "../Component/TravelInquiryForm";
import DeliveryOrderForm from "../Component/DeliveryOrderForm";




const cardTemplateData = [
    {
        id: 1,
        title: 'فرم ثبت نام مشتری جدید',
        src: '/assets/images/NewCustomerCard.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم ثبت نام مشتری جدید',
                    subHeadingValue: 'جزئیات مشتری:'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "چطور در مورد ما شنیدید؟",
                    subDropDownValue: "",
                    option: ['روزنامه', 'اینترنت', 'مجله', 'دیگر'],
                    textareaValue: "",
                    mainTextareaValue: ['روزنامه', 'اینترنت', 'مجله', 'دیگر'],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "بازخورد درباره ما:",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "پیشنهاداتی در صورت وجود برای بهبود بیشتر:",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: "آیا حاضرید به ما توصیه ای کنید؟",
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "شاید",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
        ],
        modalContent: <NewCustomerCard />
    },
    {
        id: 2,
        title: 'فرم بلیط خدمات فناوری اطلاعات',
        src: '/assets/images/ItService.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'بلیط خدمات فناوری اطلاعات',
                    subHeadingValue: 'لطفا جزئیات مشکل را ارائه دهید'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "بخش",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "شناسه کامپیوتر",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: "آپلود فایل",
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "مشکل را شرح دهید",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <ITServiceTicket />
    },
    {
        id: 3,
        title: 'فرم سفارش ساده سفارشی',
        src: '/assets/images/CustomSimpleOrderForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'سفارش خود را در اینجا ثبت کنید',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "ProductList",
                state:
                {
                    products: [],
                    productValue: "لیست محصولات",
                    totalPrice: 10000,
                    totalQuantity: 1,
                    mainProducts: [
                        {
                            id: 608,
                            name: "نام محصولات",
                            description: "توضیحات را وارد کنید",
                            image: null,
                            price: "10000",
                            quantity: 1
                        }
                    ],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "یک عنوان وارد کنید",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "یک عنوان وارد کنید",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس تحویل",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "دستورالعمل های ویژه تحویل",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <CustomSimpleOrderForm />
    },
    {
        id: 4,
        title: 'فرم ثبت نام دوره',
        src: '/assets/images/course.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم ثبت نام',
                    subHeadingValue: 'برای ثبت نام فرم را با دقت پر کنید'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام دانش آموز",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ تولد",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "جنسیت",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: ['آقا', 'خانم', 'N/A'],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل دانشجویی",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره موبایل",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره کاری",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "شرکت",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "دوره ها",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: [
                        'Windows 8',
                        'مقدمه ای بر لینوکس',
                        'انگلیسی 101',
                        'انگلیسی 102',
                        'نوشتن خلاق 1',
                        'نوشتن خلاق 2',
                        'تاریخ 101',
                        'تاریخ 102',
                        'ریاضی 101',
                        'ریاضی 102'
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "نظرات اضافی",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <CourseRegistrationForm />
    },
    {
        id: 5,
        title: 'ثبت نام کلاس',
        src: '/assets/images/ClassRegistration.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'ثبت نام کلاس',
                    subHeadingValue: 'برای ثبت نام فرم را با دقت پر کنید'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام دانش آموز",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "جنسیت",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: ['آقا', 'خانم', 'N/A'],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل دانشجویی",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "شناسه دانشجویی",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "لیست کلاس ها",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: [
                        'انگلیسی 101',
                        'انگلیسی 202',
                        'انگلیسی 302',
                        'انگلیسی 402',
                        'علوم 101',
                        'علوم 201',
                        'علوم 301',
                        'علوم 401',
                        'ریاضی 101',
                        'ریاضی 202',
                        'ریاضی 302',
                        'ریاضی 402',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
        ],
        modalContent: <ClassRegistration />
    },
    {
        id: 6,
        title: 'فرم ثبت نام تجاری',
        src: '/assets/images/BusinessRegistrationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'کسب و کار خود را ثبت کنید',
                    subHeadingValue: 'لطفا تمام مشخصات مورد نیاز برای ثبت کسب و کار خود را با ما ارائه دهید'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "صاحب کسب و کار",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "نام کسب و کار",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تماس",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "نوع کسب و کار",
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: [
                        'فروشگاه/کافه',
                        'وام دادن',
                        'فروشگاه',
                        'اجاره ها',
                        'بقیه را لطفا در زیر مشخص کنید.',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "پیام",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <BusinessRegistrationForm />
    },
    {
        id: 7,
        title: 'درخواست سخت افزار جدید',
        src: '/assets/images/HardwareRequest.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درخواست سخت افزار جدید',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام درخواست کننده",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل درخواست کننده",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "بخش برای خرید",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "نوع کامپیوتر",
                    subDropDownValue: "",
                    option: [
                        'دسکتاپ جدید',
                        'لپ تاپ جدید',
                        'تبلت',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'دسکتاپ جدید',
                        'لپ تاپ جدید',
                        'تبلت',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: "نرم افزار را برای نصب انتخاب کنید",
                    multiData: [
                        {
                            id: 1, label: 'Microsoft Office', checked: false
                        },
                        {
                            id: 2, label: 'Adobe Acrobat', checked: false
                        },
                        {
                            id: 3, label: 'Syteline', checked: false
                        },
                        {
                            id: 4, label: 'Lotus Notes', checked: false
                        },
                        {
                            id: 5, label: 'Doc Aces', checked: false
                        },
                        {
                            id: 6, label: 'Google Chrome', checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "سایر نرم افزارها",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "دلیل درخواست (مشخص باشد)",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ ارسال",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ درخواست ",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
        ],
        modalContent: <NewHardwareRequest />
    },
    {
        id: 8,
        title: 'فرم ثبت نام فوتبال',
        src: '/assets/images/FootballRegistrationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم ثبت نام فوتبال',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام بازیکن',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'تاریخ تولد',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: 'آدرس منزل',
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'جنسیت',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "شاید",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'لطفا عکس خود را آپلود کنید',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل بازیکن',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'گروه تیم بازیکنان',
                    subDropDownValue: "",
                    option: [
                        'مهد کودک',
                        'راهنمایی',
                        'دبیرستان',
                        'دانشگاه',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'مهد کودک',
                        'راهنمایی',
                        'دبیرستان',
                        'دانشگاه',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'حمله یا دفاع',
                    singleData: [
                        {
                            id: 1,
                            label: 'حمله',
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'دفاع',
                            checked: false
                        },
                        {
                            id: 3,
                            label: "نمی دانم",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'قد',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'وزن',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "نظرات",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <FootballRegistrationForm />
    },
    {
        id: 9,
        title: 'فرم رزرو تور',
        src: '/assets/images/TourReservationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم رزرو تور',
                    subHeadingValue: 'بیایید بدانیم که علاقه مند به دیدن چه چیزی هستید!'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'چه زمانی قصد بازدید دارید؟',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: "شماره",
                    readOnly: false,
                    subNumberValue: ""
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'به کدام تورها یا رویدادها بیشتر علاقه دارید؟',
                    multiData: [
                        { id: 1, label: 'انقلاب تازه شروع بود', checked: false },
                        { id: 2, label: 'ماورایی', checked: false },
                        { id: 3, label: 'سنت', checked: false },
                        { id: 5, label: 'دروازه‌ها، انگورها و قبرستان‌ها', checked: false },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'بهترین راه برای تماس با شما چیست؟',
                    multiData: [
                        { id: 1, label: 'تلفن', checked: false },
                        { id: 2, label: 'ایمیل', checked: false },
                        { id: 3, label: 'هر دو', checked: false },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'در صورت تلفن، بهترین زمان روز برای تماس چه زمانی است؟',
                    singleData: [
                        { id: 1, label: '8-11 صبح', checked: false },
                        { id: 2, label: '12-4 بعد از ظهر', checked: false },
                        { id: 3, label: '6-10 شب', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "چیز دیگری که باید بدانیم؟",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'و در آخر، چگونه در مورد ما شنیدید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <TourReservationForm />
    },
    {
        id: 10,
        title: 'درخواست وام مسکن',
        src: '/assets/images/HomeLoanApplication.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درخواست وام مسکن',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا شما صاحب خانه هستید یا اجاره می دهید؟',
                    singleData: [
                        { id: 1, label: 'صاحب خانه', checked: false },
                        { id: 2, label: 'اجاره', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا قبل از خرید خانه جدید باید خانه خود را بفروشید؟',
                    singleData: [
                        { id: 1, label: 'بله', checked: false },
                        { id: 2, label: 'خیر', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'نوع خرید',
                    subDropDownValue: "",
                    option: [
                        'اقامتگاه اولیه',
                        'اقامتگاه ثانویه',
                        'سرمایه گذاری',
                        'مطمئن نیستم',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'اقامتگاه اولیه',
                        'اقامتگاه ثانویه',
                        'سرمایه گذاری',
                        'مطمئن نیستم',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا از قبل واجد شرایط وام هستید؟',
                    singleData: [
                        { id: 1, label: 'بله', checked: false },
                        { id: 2, label: 'خیر', checked: false },
                        { id: 3, label: 'خریدار نقدی', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا شما توسط یک مشاور املاک نمایندگی دارید؟',
                    singleData: [
                        { id: 1, label: 'بله', checked: false },
                        { id: 2, label: 'خیر', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'نظرات یا سوالات:',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <HomeLoanApplication />
    },
    {
        id: 11,
        title: 'الگوی خود ارزیابی',
        src: '/assets/images/SelfEvaluationTemplate.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'الگوی خود ارزیابی',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'بخش',
                    subDropDownValue: "",
                    option: [
                        'اداره',
                        'امور مالی',
                        'توسعه',
                        'منابع انسانی',
                        'IT',
                        'فروش',
                        'بازاریابی',
                        'تولید',
                    ],
                    textareaValue: [
                        'اداره',
                        'امور مالی',
                        'توسعه',
                        'منابع انسانی',
                        'IT',
                        'فروش',
                        'بازاریابی',
                        'تولید',
                    ],
                    mainTextareaValue: [
                        'اداره',
                        'امور مالی',
                        'توسعه',
                        'منابع انسانی',
                        'IT',
                        'فروش',
                        'بازاریابی',
                        'تولید',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'عنوان شغلی',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'لطفا آخرین تاریخ ارزیابی خود را انتخاب کنید.',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: '1. مهمترین دستاوردهای مرتبط با موقعیت خود را از دوره قبل به ترتیب اهمیت تعریف کنید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: '2. بزرگترین چالش های خود را تعریف کنید و چگونه آنها را در این مدت حل کردید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: '3. حوزه هایی را که فکر می کنید باید بهبود ببخشید و برنامه بهبود خود را تعریف کنید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: '4. بیان کنید که در طول انجام وظایف خود از چه جنبه هایی بیشترین و کمترین لذت را می برید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'موافقم',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '5. وظایفم را در بازه زمانی از پیش تعیین شده انجام دادم.',
                    subScaleValue: "",
                    worthValue: 'مخالفم'
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'موافقم',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '6. هیچ وقت در طول دوره هیچ مهلتی را از دست نمی دهم.',
                    subScaleValue: "",
                    worthValue: 'مخالفم'
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'موافقم',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '7. من همیشه با سایر اعضای تیم به وضوح ارتباط برقرار می کنم.',
                    subScaleValue: "",
                    worthValue: 'مخالفم'
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'موافقم',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '8. من به جای اینکه در طول پروژه ها واکنشی باشم، فعال هستم.',
                    subScaleValue: "",
                    worthValue: 'مخالفم'
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'موافقم',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '9. من به تمام اهدافم که در ارزیابی قبلی تعریف کرده ام دست یافته ام.',
                    subScaleValue: "",
                    worthValue: 'مخالفم'
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: '10. اهداف زیر را که می خواهید تا تاریخ ارزیابی بعدی به آن برسید مشخص کنید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: '11. بیان کنید که چگونه مدیرتان می تواند به شما کمک کند تا رضایت شما را افزایش دهید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <SelfEvaluationTemplate />
    },
    {
        id: 12,
        title: 'فرم ثبت نام کارمندان',
        src: '/assets/images/EmployeeRegistrationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'الگوی خود ارزیابی',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'تاریخ تولد',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'درخواست برای موقعیت :',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'نوع کار',
                    singleData: [
                        { id: 1, label: 'دائمی', checked: false },
                        { id: 2, label: 'موقت', checked: false },
                        { id: 2, label: 'هر دو', checked: false },
                        { id: 2, label: 'دیگر', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'لطفا رزومه را آپلود کنید',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'اطلاعات تکمیلی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'وضعیت جانبازی (لطفاً گزینه ای را انتخاب کنید که وضعیت شما را به بهترین شکل توصیف می کند)',
                    singleData: [
                        { id: 1, label: 'من یک جانباز هستم', checked: false },
                        { id: 2, label: 'من یک جانباز معلول ویژه هستم', checked: false },
                        { id: 3, label: "من یک جانباز اخیرا جدا شده ام", checked: false },
                        { id: 4, label: "من یک جانباز حفاظت شده دیگر هستم", checked: false },
                        { id: 5, label: "من جانباز نیستم", checked: false },
                        { id: 6, label: "ترجیح میدهم پاسخ ندهم ", checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'جنسیت',
                    singleData: [
                        { id: 1, label: 'زن', checked: false },
                        { id: 2, label: 'مرد', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'معلولیت ها',
                    singleData: [
                        { id: 1, label: 'من هیچ معلولیتی ندارم', checked: false },
                        { id: 2, label: 'من دو یا چند اختلال و/یا شرایط پزشکی ناتوان کننده دارم.', checked: false },
                        { id: 3, label: 'من یک مشکل یادگیری خاص دارم (مثلاً نارساخوانی/دیسپراکسی/AD(H)D', checked: false },
                        { id: 4, label: 'من یک اختلال اجتماعی / ارتباطی مانند سندرم آسپرگر / سایر اختلالات طیف اوتیسم دارم', checked: false },
                        { id: 5, label: 'من یک بیماری طولانی مدت یا وضعیت سلامتی مانند سرطان، HIV، دیابت، بیماری مزمن قلبی یا صرع دارم.', checked: false },
                        { id: 6, label: 'من یک نقص جسمی یا مشکلات حرکتی دارم (مثلاً مشکل در استفاده از بازوها/استفاده از ویلچر یا عصا)', checked: false },
                        { id: 7, label: 'من ناشنوا هستم یا اختلال شنوایی جدی دارم', checked: false },
                        { id: 8, label: 'من نابینا هستم یا یک نقص بینایی جدی دارم که با عینک اصلاح نشده است', checked: false },
                        { id: 9, label: 'ترجیح می دهید پاسخ ندهید', checked: false },
                        { id: 10, label: 'من یک معلولیت، نقص یا وضعیت پزشکی دارم که در اینجا ذکر نشده است', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'نژاد',
                    singleData: [
                        { id: 1, label: 'بومی آمریکایی یا بومی آلاسکا', checked: false },
                        { id: 2, label: 'آسیایی', checked: false },
                        { id: 3, label: 'سیاه پوست یا آفریقایی آمریکایی', checked: false },
                        { id: 4, label: 'بومی هاوایی یا سایر ساکنان جزایر اقیانوس آرام', checked: false },
                        { id: 5, label: 'سفید پوست', checked: false },
                        { id: 6, label: 'دو یا چند نژاد', checked: false },
                        { id: 7, label: 'دیگر', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'یادداشت های اضافی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <EmployeeRegistrationForm />
    },
    {
        id: 13,
        title: 'فرم نامزدی نماینده دانشجو',
        src: '/assets/images/StudentRepresentativeNominationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم نامزدی نماینده دانشجو',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام شما',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: 'شناسه دانشجویی',
                    readOnly: false,
                    subNumberValue: ""
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'کلاس',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'آدرس ایمیل مدرسه',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تماس',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'به نامی که می خواهید او نماینده دانشجو باشد رای دهید.',
                    subDropDownValue: "",
                    option: [
                        'دانش آموز اول',
                        'دانش آموز دوم',
                        'دانش آموز سوم',
                        'دانش آموز چهارم',
                        'دانش آموز پنجم',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'دانش آموز اول',
                        'دانش آموز دوم',
                        'دانش آموز سوم',
                        'دانش آموز چهارم',
                        'دانش آموز پنجم',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'به نماینده معاون دانشجویی رای دهید.',
                    subDropDownValue: "",
                    option: [
                        'دانش آموز اول',
                        'دانش آموز دوم',
                        'دانش آموز سوم',
                        'دانش آموز چهارم',
                        'دانش آموز پنجم',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'دانش آموز اول',
                        'دانش آموز دوم',
                        'دانش آموز سوم',
                        'دانش آموز چهارم',
                        'دانش آموز پنجم',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'برای نامزدی خود دلیل بیاورید',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Captcha',
                state: {}
            },
        ],
        modalContent: <StudentRepresentativeNominationForm />
    },
    {
        id: 14,
        title: 'فرم نوبت دهی آنلاین پزشک',
        src: '/assets/images/OnlineDoctorAppointmentForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم نوبت دهی آنلاین پزشک',
                    subHeadingValue: 'فرم زیر را پر کنید و ما به زودی برای به روز رسانی های بیشتر و برنامه ریزی قرار ملاقات با شما تماس خواهیم گرفت.'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ تولد",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'به نامی که می خواهید او نماینده دانشجو باشد رای دهید.',
                    subDropDownValue: "",
                    option: [
                        'آقا',
                        'خانم',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'آقا',
                        'خانم',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تلفن',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا قبلاً برای تسهیلات ما درخواست داده اید؟',
                    singleData: [
                        { id: 1, label: 'بله', checked: false },
                        { id: 2, label: 'نه', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'دوست دارید از کدام بخش وقت ملاقات بگیرید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'برای کدام روش می خواهید وقت بگیرید؟',
                    subDropDownValue: "",
                    option: [
                        'معاینه پزشکی',
                        'چک کردن توسط دکتر',
                        'تجزیه و تحلیل نتایج',
                        'معاینه عمومی',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'معاینه پزشکی',
                        'چک کردن توسط دکتر',
                        'تجزیه و تحلیل نتایج',
                        'معاینه عمومی',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: "تاریخ مورد نظر برای ملاقات",
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },
        ],
        modalContent: <OnlineDoctorAppointmentForm />
    },
    {
        id: 15,
        title: 'فرم قرار ملاقات پزشکی',
        src: '/assets/images/MedicalAppointmentForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم قرار ملاقات پزشکی',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'به نامی که می خواهید او نماینده دانشجو باشد رای دهید.',
                    subDropDownValue: "",
                    option: [
                        'آقا',
                        'خانم',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'آقا',
                        'خانم',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تلفن',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ تولد",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا تا به حال به ما سر زده اید؟',
                    singleData: [
                        { id: 1, label: 'بله', checked: false },
                        { id: 2, label: 'نه', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
        ],
        modalContent: <MedicalAppointmentForm />
    },
    {
        id: 16,
        title: 'فرم سفارش پیتزا',
        src: '/assets/images/PizzaOrderForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم سفارش پیتزا',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "ProductList",
                state:
                {
                    products: [],
                    productValue: 'منو',
                    totalPrice: 10000,
                    totalQuantity: 1,
                    mainProducts: [

                        {
                            id: Math.floor(Math.random() * 1000) + 100,
                            name: 'پیتزا ناپلی',
                            description: 'مواد تشکیل دهنده',
                            image: null,
                            price: '150000',
                            quantity: 3,
                        },
                        {
                            id: Math.floor(Math.random() * 1000) + 100,
                            name: 'پیتزا پپرونی',
                            description: 'مواد تشکیل دهنده',
                            image: null,
                            price: '130000',
                            quantity: 1,
                        },
                        {
                            id: Math.floor(Math.random() * 1000) + 100,
                            name: 'پیتزا ماره مونتی',
                            description: 'مواد تشکیل دهنده',
                            image: null,
                            price: '150000',
                            quantity: 4,
                        },
                        {
                            id: Math.floor(Math.random() * 1000) + 100,
                            name: 'آب پرتقال',
                            description: 'مواد تشکیل دهنده',
                            image: null,
                            price: '80000',
                            quantity: 1,
                        },
                        {
                            id: Math.floor(Math.random() * 1000) + 100,
                            name: 'لیموناد',
                            description: 'مواد تشکیل دهنده',
                            image: null,
                            price: '90000',
                            quantity: 2,
                        },

                    ],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'تاریخ تحویل',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'یادداشت های اضافی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
        ],
        modalContent: <PizzaOrderForm />
    },
    {
        id: 17,
        title: 'فرم سفارش آنلاین بدون پرداخت',
        src: '/assets/images/OnlineOrderFormWithoutPayment.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم سفارش آنلاین بدون پرداخت',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'حساب / نام فیس بوک',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: 'آدرس حمل و نقل',
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'سفارش/ها',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تلفن',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نحوه پرداخت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'تاریخ دقیق پرداخت',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'پیک',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'یادداشت های اضافی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Captcha',
                state: {}
            },
        ],
        modalContent: <OnlineOrderFormWithoutPayment />
    },
    {
        id: 18,
        title: 'فرم آرایشگاه ها',
        src: '/assets/images/HairdressersForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم آرایشگاه ها',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام و نام خانوادگی',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تلفن',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'کدام روش ارتباطی را ترجیح میدهید؟',
                    singleData: [
                        { id: 1, label: 'ایمیل', checked: false },
                        { id: 2, label: 'تلفن', checked: false },
                        { id: 2, label: 'هر دو', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'خدمات مورد نیاز',
                    subDropDownValue: "",
                    option: [
                        'کوتاه کردن و سشوار کردن مو',
                        'شامپو و سشوار',
                        'رنگ - نیمه دائمی',
                        'رنگ - دائمی',
                        'رنگ - مد',
                        'رنگ - سفید کننده و تونر',
                        'های لایت - نیمه',
                        'های لایت - کامل',
                        'های لایت - فویل و رنگ',
                        'پرم',
                        'صاف کردن مو',
                        'ریلکس کننده مو',
                        'دیگر',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'کوتاه کردن و سشوار کردن مو',
                        'شامپو و سشوار',
                        'رنگ - نیمه دائمی',
                        'رنگ - دائمی',
                        'رنگ - مد',
                        'رنگ - سفید کننده و تونر',
                        'های لایت - نیمه',
                        'های لایت - کامل',
                        'های لایت - فویل و رنگ',
                        'پرم',
                        'صاف کردن مو',
                        'ریلکس کننده مو',
                        'دیگر',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: 'قرار ملاقات',
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'جزئیات دیگری که ممکن است بخواهید برجسته کنید',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'من را به لیست ایمیل خود اضافه کنید',
                    singleData: [
                        { id: 1, label: 'بله لطفا', checked: false },
                        { id: 2, label: 'نه ممنون', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
        ],
        modalContent: <HairdressersForm />
    },
    {
        id: 19,
        title: 'پرسشنامه مصاحبه',
        src: '/assets/images/OnlineInterviewQuestionnaireForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'پرسشنامه مصاحبه',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام و نام خانوادگی',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: 'سن',
                    readOnly: false,
                    subNumberValue: ""
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'جنسیت',
                    subDropDownValue: "",
                    option: ['آقا', 'خانم'],
                    textareaValue: "",
                    mainTextareaValue: ['آقا', 'خانم'],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تلفن',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'خودتان را در چند کلمه توصیف کنید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'مهارت های اصلی شما در مورد شغلی که برای آن درخواست داده اید چیست؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'مدرک تحصیلی شما چیست؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'چرا شغل قبلی خود را رها کردید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'جذاب ترین ویژگی این پروفایل شغلی چیست؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'چرا می خواهید به سازمان ما بپیوندید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'بعد از چند سال حضور در این صنعت خود را کجا می بینید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'چرا برای این موقعیت اقدام می کنید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'لطفا در صورت داشتن هر گونه یادداشت اضافی احساس راحتی کنید',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },

        ],
        modalContent: <OnlineInterviewQuestionnaireForm />
    },
    {
        id: 20,
        title: 'فرم ثبت نام بیماران بیمارستانی',
        src: '/assets/images/HospitalPatientRegistrationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'ثبت نام بیمار جدید',
                    subHeadingValue: 'لطفا فرم زیر را پر کنید'
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'تاریخ ثبت نام',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "Time",
                state:
                {
                    inputValue: "",
                    mainInputValue: "",
                    readOnly: false,
                    selectedOption: "ق.ظ",
                    subTimeValue: "ساعت دقیقه",
                    timeValue: 'زمان ثبت نام'
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: 'شماره مراقبت های بهداشتی',
                    readOnly: false,
                    subNumberValue: ""
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام بیمار',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'جنسیت',
                    subDropDownValue: "",
                    option: ['آقا', 'خانم'],
                    textareaValue: "",
                    mainTextareaValue: ['آقا', 'خانم'],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'تاریخ تولد',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تلفن',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'وضعیت تأهل',
                    subDropDownValue: "",
                    option: [
                        'مجرد',
                        'متاهل',
                        'طلاق گرفته',
                        'قانونی جدا شده است',
                        'همسر فوت شده',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'مجرد',
                        'متاهل',
                        'طلاق گرفته',
                        'قانونی جدا شده است',
                        'همسر فوت شده',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا بیمار کمتر از 18 سال دارد؟',
                    singleData: [
                        { id: 1, label: 'بله', checked: false },
                        { id: 2, label: 'خیر', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'تماس اضطراری',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'تماس اضطراری',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نسبت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تماس',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام پزشک خانواده',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تلفن پزشک خانواده',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'داروخانه انتخاب شده',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تلفن داروخانه',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'تاریخچه سلامت',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'دلیل ثبت نام',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'یادداشت های اضافی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'در حال حاضر دارویی مصرف می کنید؟',
                    singleData: [
                        { id: 1, label: 'بله', checked: false },
                        { id: 2, label: 'خیر', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات بیمه',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'شرکت بیمه',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'شناسه بیمه',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام دارنده بیمه نامه',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ تولد",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },

        ],
        modalContent: <HospitalPatientRegistrationForm />
    },
    {
        id: 21,
        title: 'فرم رزرو هتل',
        src: '/assets/images/HotelBookingForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'رزرو هتل',
                    subHeadingValue: 'هر لحظه چیز جدیدی را تجربه کنید'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام و نام خانوادگی',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'نوع اتاق',
                    subDropDownValue: "",
                    option: [
                        'اتاق استاندارد (1 تا 2 نفر)',
                        'اتاق خانوادگی (1 تا 4 نفر)',
                        'اتاق خصوصی (1 تا 3 نفر)',
                        'اتاق خوابگاه مختلط (6 نفر)',
                        'اتاق خوابگاه زنانه (6 نفر)',
                        'اتاق خوابگاه مردانه (6 نفر)',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'اتاق استاندارد (1 تا 2 نفر)',
                        'اتاق خانوادگی (1 تا 4 نفر)',
                        'اتاق خصوصی (1 تا 3 نفر)',
                        'اتاق خوابگاه مختلط (6 نفر)',
                        'اتاق خوابگاه زنانه (6 نفر)',
                        'اتاق خوابگاه مردانه (6 نفر)',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: 'تعداد مهمانان',
                    readOnly: false,
                    subNumberValue: ""
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'تاریخ ورود',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "Time",
                state:
                {
                    inputValue: "",
                    mainInputValue: "",
                    readOnly: false,
                    selectedOption: "ق.ظ",
                    subTimeValue: "ساعت دقیقه",
                    timeValue: 'زمان ورود'
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'تاریخ حرکت',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'ماشین رایگان؟',
                    singleData: [
                        { id: 1, label: 'بله لطفا! - در بدو ورود مرا ببر', checked: false },
                        { id: 2, label: '"نه، ممنون. خودم راه رو پیدا می‌کنم."', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'شماره پرواز',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'درخواست های ویژه',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },


        ],
        modalContent: <HotelBookingForm />
    },
    {
        id: 22,
        title: 'یک فایل با فرم کپچا ارسال کنید',
        src: '/assets/images/SendFileWithCaptchaForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'ارسال یک فایل',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام مشتری',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'توضیحات',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'لطفا فایل(های) خود را انتخاب کنید:',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Captcha',
                state: {}
            },


        ],
        modalContent: <SendFileWithCaptchaForm />
    },
    {
        id: 23,
        title: 'فرم ارزیابی رستوران',
        src: '/assets/images/RestaurantEvaluationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'لطفا ما را مرور کنید',
                    subHeadingValue: 'لطفا به ما اطلاع دهید که غذا و خدمات چگونه بود.'
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'روز بازدید',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: "صرف غذا در رستوران / بیرون‌بر",
                    singleData: [
                        { id: 1, label: "صرف غذا در رستوران", checked: false },
                        { id: 2, label: 'بیرون‌بر', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'کیفیت غذا',
                    singleData: [
                        { id: 1, label: 'عالی', checked: false },
                        { id: 2, label: 'خوب', checked: false },
                        { id: 3, label: 'متوسط', checked: false },
                        { id: 4, label: 'ناراضی', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'کیفیت خدمات کلی',
                    singleData: [
                        { id: 1, label: 'عالی', checked: false },
                        { id: 2, label: 'خوب', checked: false },
                        { id: 3, label: 'متوسط', checked: false },
                        { id: 4, label: 'ناراضی', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'پاکیزگی',
                    singleData: [
                        { id: 1, label: 'عالی', checked: false },
                        { id: 2, label: 'خوب', checked: false },
                        { id: 3, label: 'متوسط', checked: false },
                        { id: 4, label: 'ناراضی', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'دقت سفارش',
                    singleData: [
                        { id: 1, label: 'عالی', checked: false },
                        { id: 2, label: 'خوب', checked: false },
                        { id: 3, label: 'متوسط', checked: false },
                        { id: 4, label: 'ناراضی', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'سرعت خدمات',
                    singleData: [
                        { id: 1, label: 'عالی', checked: false },
                        { id: 2, label: 'خوب', checked: false },
                        { id: 3, label: 'متوسط', checked: false },
                        { id: 4, label: 'ناراضی', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'ارزش',
                    singleData: [
                        { id: 1, label: 'عالی', checked: false },
                        { id: 2, label: 'خوب', checked: false },
                        { id: 3, label: 'متوسط', checked: false },
                        { id: 4, label: 'ناراضی', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'تجربه کلی',
                    singleData: [
                        { id: 1, label: 'عالی', checked: false },
                        { id: 2, label: 'خوب', checked: false },
                        { id: 3, label: 'متوسط', checked: false },
                        { id: 4, label: 'ناراضی', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'توضیحات',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'سن',
                    subDropDownValue: "",
                    option: [
                        'زیر 13',
                        '13-17',
                        '18-24',
                        '25-34',
                        '35-44',
                        '45-54',
                        '55 یا بیشتر',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'زیر 13',
                        '13-17',
                        '18-24',
                        '25-34',
                        '35-44',
                        '45-54',
                        '55 یا بیشتر',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },


        ],
        modalContent: <RestaurantEvaluationForm />
    },
    {
        id: 24,
        title: "فرم قرار ملاقات معلم خصوصی",
        src: '/assets/images/TutorAppointmentForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: "فرم قرار ملاقات معلم خصوصی",
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام دانش آموز',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تلفن والدین',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل والدین',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'نوع تدریس خصوصی',
                    subDropDownValue: "",
                    option: [
                        'تدریس خصوصی آنلاین',
                        'آموزش خصوصی در آدرس توافق شده',
                        'تدریس خصوصی در منزل',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'تدریس خصوصی آنلاین',
                        'آموزش خصوصی در آدرس توافق شده',
                        'تدریس خصوصی در منزل',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'موضوع برای تدریس خصوصی',
                    subDropDownValue: "",
                    option: [
                        'ریاضی',
                        'فیزیک',
                        'شیمی',
                        'ادبیات',
                        'زیست شناسی',
                        'تاریخ',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'ریاضی',
                        'فیزیک',
                        'شیمی',
                        'ادبیات',
                        'زیست شناسی',
                        'تاریخ',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'درجه',
                    subDropDownValue: "",
                    option: [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: 'قرار ملاقات',
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },

        ],
        modalContent: <TutorAppointmentForm />
    },
    {
        id: 25,
        title: 'فرم مشاوره رایگان',
        src: '/assets/images/FreeClientConsultation.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم مشاوره رایگان',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام و نام خانوادگی',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تلفن',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نام شرکت یا سازمان',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'تمایل به مشاوره',
                    subDropDownValue: "",
                    option: [
                        'راه حل های شبکه',
                        'راهکارهای سرور',
                        'توسعه کسب و کار',
                        'تدارکات',
                        'تلفن',
                        'طراحی وب',
                        'پشتیبانی',
                        'دیگر',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'راه حل های شبکه',
                        'راهکارهای سرور',
                        'توسعه کسب و کار',
                        'تدارکات',
                        'تلفن',
                        'طراحی وب',
                        'پشتیبانی',
                        'دیگر',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: "لطفاً تاریخ و زمان قرار ملاقات را انتخاب کنید",
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "اطلاعات/توضیحات اضافی",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <FreeClientConsultation />
    },
    {
        id: 26,
        title: 'پرسشنامه پزشکی',
        src: '/assets/images/MedicalQuestionnaire.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'پرسشنامه پزشکی',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام و نام خانوادگی',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تماس',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ تولد",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا پزشک شما تا به حال گفته است که فشار خون شما خیلی بالا یا خیلی پایین است؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "اطلاعات/توضیحات اضافی",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا مشکلات قلبی عروقی شناخته شده ای دارید (ECG غیر طبیعی، حمله قلبی قبلی و غیره)؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا پزشکتان تا به حال به شما گفته است که کلسترول شما خیلی بالاست؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا تا به حال به شما (یا یکی از اعضای خانواده) گفته شده که دیابت دارید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا آسیب یا مشکلات ارتوپدی (کمر، زانو و غیره) دارید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا مفاصل سفت یا متورم دارید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا در ناحیه ای تنش یا درد دارید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا از داروهای تجویز شده یا مکمل های غذایی استفاده می کنید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا تا به حال مشکل خواب داشته اید',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا باردار هستید یا پس از زایمان (<6 هفته)؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا تا به حال توسط پزشک، پزشک یا متخصص به شما توصیه شده است که هیچ نوع ورزش/فعالیتی را انجام ندهید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا وضعیت پزشکی، آسیب یا هر چیز دیگری دارید که باید از آن آگاه باشیم که به آن اشاره نکرده ایم؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

        ],
        modalContent: <MedicalQuestionnaire />
    },
    {
        id: 27,
        title: 'فرم رزرو',
        src: '/assets/images/ReservationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم مشاوره رایگان',
                    subHeadingValue: 'لطفاً فرم زیر را به دقت پر کنید تا بتوانیم خدمات بهتری به شما ارائه دهیم!.. خوش آمدید!'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام و نام خانوادگی',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تلفن',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: '#مهمانان',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: 'رزرو',
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'نوع رزرو',
                    subDropDownValue: "",
                    option: [
                        'شام',
                        'VIP',
                        'تولد / سالگرد',
                        'زندگی شبانه',
                        'خصوصی',
                        'عروسی',
                        'شرکتی',
                        'تعطیلات',
                        'دیگر',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'شام',
                        'VIP',
                        'تولد / سالگرد',
                        'زندگی شبانه',
                        'خصوصی',
                        'عروسی',
                        'شرکتی',
                        'تعطیلات',
                        'دیگر',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'اگر موارد دیگر در بالا، لطفا مشخص کنید',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'هر گونه درخواست خاص',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <ReservationForm />
    },
    {
        id: 28,
        title: 'فرم تماس با املاک و مستغلات',
        src: '/assets/images/RealEstateContactForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم اطلاعات فروشنده',
                    subHeadingValue: 'برای دریافت پیشنهاد خود این فرم را تکمیل کنید!'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام و نام خانوادگی',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: 'شماره تلفن',
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'بهترین زمان برای تماس با شما',
                    multiData: [
                        {
                            id: 1,
                            label: "صبح",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "بعد از ظهر",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "عصر",
                            checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'چقدر زود به فروش نیاز دارید',
                    multiData: [
                        {
                            id: 1,
                            label: "در اسرع وقت",
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'ظرف 30 روز',
                            checked: false
                        },
                        {
                            id: 3,
                            label: "ظرف 90 روز",
                            checked: false
                        },
                        {
                            id: 4,
                            label: "من در حال بررسی گزینه ها هستم",
                            checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا در حال حاضر در خانه زندگی می کنید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'چقدر زود باید حرکت کنید',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'چرا میفروشید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'چه نوع ملکی',
                    subDropDownValue: "",
                    option: [
                        'خانه خانواده مجردی',
                        'خانه شهر',
                        'خانه تولید شده',
                        'سایر',
                    ]
                    ,
                    textareaValue: "",
                    mainTextareaValue: [
                        'خانه خانواده مجردی',
                        'خانه شهر',
                        'خانه تولید شده',
                        'سایر',
                    ]
                    ,
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Captcha',
                state: {}
            },


        ],
        modalContent: <RealEstateContactForm />
    },
    {
        id: 29,
        title: 'فرم نظرسنجی نرم افزار',
        src: '/assets/images/SoftwareSurveyForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'بررسی نرم افزار',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'چگونه درباره {نام نرم افزار} ... شنیدید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "ایمیل",
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'شفاهی',
                            checked: false
                        },
                        {
                            id: 3,
                            label: 'رسانه های اجتماعی',
                            checked: false
                        },
                        {
                            id: 4,
                            label: 'تبلیغات',
                            checked: false
                        },
                        {
                            id: 5,
                            label: 'جستجو',
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'از کدام پلتفرم استفاده می کنید؟',
                    multiData: [
                        {
                            id: 1,
                            label: "Windows",
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'Mac OS',
                            checked: false
                        },
                        {
                            id: 3,
                            label: "Linux",
                            checked: false
                        },
                        {
                            id: 4,
                            label: 'سایر',
                            checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'موافقم',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: 'به {نام نرم افزار} ... چه امتیازی می دهید؟',
                    subScaleValue: "",
                    worthValue: 'مخالفم'
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا {نام نرم افزار} را خریداری کردید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'لطفا نظرات منفی/مثبت را با ما در میان بگذارید',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },


        ],
        modalContent: <SoftwareSurveyForm />
    },
    {
        id: 30,
        title: 'بررسی سلامت',
        src: '/assets/images/HealthSurvey.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'بررسی سلامت',
                    subHeadingValue: 'لطفا این نظرسنجی سلامت را پر کنید'
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'تاریخ',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'تماس‌های ترجیحی',
                    multiData: [
                        {
                            id: 1, label: 'تماس تلفنی', checked: false
                        },
                        {
                            id: 2, label: 'پیام تلفنی', checked: false
                        },
                        {
                            id: 3, label: 'ایمیل', checked: false
                        },
                        {
                            id: 4, label: 'سایر', checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: "تاریخ تولد",
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: 'سن',
                    readOnly: false,
                    subNumberValue: ""
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'جنسیت',
                    subDropDownValue: "",
                    option: ['آقا', 'خانم'],
                    textareaValue: "",
                    mainTextareaValue: ['آقا', 'خانم'],
                    mainOption: null,
                    readOnly: false
                }
            },







            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'پزشکی',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'آیا شما یکی از موارد زیر را دارید؟',
                    multiData: [
                        {
                            id: 1, label: 'فشار خون بالا', checked: false
                        },
                        {
                            id: 2, label: 'دیابت - نوع 1', checked: false
                        },
                        {
                            id: 3, label: 'دیابت - نوع 2', checked: false
                        },
                        {
                            id: 4, label: 'نقرس', checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'آیا دارویی مصرف می کنید',
                    multiData: [
                        {
                            id: 1, label: 'دیابت', checked: false
                        },
                        {
                            id: 2, label: 'فشار خون بالا', checked: false
                        },
                        {
                            id: 3, label: 'کلسترول بالا', checked: false
                        },
                        {
                            id: 4, label: 'تیروئید', checked: false
                        },
                        {
                            id: 5, label: 'لیتیوم', checked: false
                        },
                        {
                            id: 6, label: 'کومادین (وارفارین)', checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا شما باردار هستید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا شما پرستاری می کنید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا آلرژی غذایی دارید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'لطفا توضیح دهید',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },



            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'BMI',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: 'قد',
                    readOnly: false,
                    subNumberValue: ""
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: 'وزن',
                    readOnly: false,
                    subNumberValue: ""
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: 'عدد BMI فعلی شما چند است؟',
                    readOnly: false,
                    subNumberValue: ""
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: 'مقدار BMI برای وزنی که می خواهید در آن حفظ کنید چقدر است؟',
                    readOnly: false,
                    subNumberValue: ""
                }
            },





            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'خواب',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'به طور کلی در چه ساعتی به رختخواب می روید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'کلا کی بیدار میشیوید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'به طور متوسط ​​چند ساعت می خوابید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا وقتی بیدار می‌شوید احساس شادابی می‌کنید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "شاید",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "بازخورد درباره ما:",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },



            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'هیدراتاسیون',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'در روز معمولا چقدر آب می نوشید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'معمولاً در روز چقدر قهوه می نوشید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'در یک هفته معمولا چقدر الکل مصرف می کنید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'حرکت',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'چند بار در هفته ورزش می کنید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'در چه نوع تمرینی شرکت می کنید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'آیا کارهایی وجود دارد که دوست دارید انجام دهید و در حال حاضر قادر به انجام آن نیستید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'بیشترین',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: 'سطح انرژی روزانه خود را چگونه ارزیابی می کنید؟',
                    subScaleValue: "",
                    worthValue: 'کمترین'
                }
            },


            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'استرس',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'برای کار چه می کنید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'آیا از کاری که انجام می دهید لذت می برید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'آیا استرس دیگری در زندگی شما وجود دارد؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'بیشترین',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: 'سطح کلی استرس خود را ارزیابی کنید',
                    subScaleValue: "",
                    worthValue: 'کمترین'
                }
            },



            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'عادات غذایی',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'اولین وعده غذایی خود را چه زمانی می خورید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'آخرین وعده غذایی خود را چه زمانی می خورید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'چند وعده غذایی در روز می خورید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'میان وعده می خورید؟ اگر بله، چه میان وعده ای میل می کنید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'چند بار در یک هفته بیرون غذا می خورید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "همیشه",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "معمولا",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "گاهی اوقات",
                            checked: false
                        },
                        {
                            id: 4,
                            label: "به ندرت",
                            checked: false
                        },
                        {
                            id: 5,
                            label: "هرگز",
                            checked: false
                        },
                        {
                            id: 6,
                            label: "سایر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'معمولاً کجا بیرون غذا می خورید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'وزن',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'وزن فعلی',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'وزن هدف',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'قد',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا قبلاً برای کاهش وزن تلاش کرده اید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "شاید",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'در گذشته سخت ترین قسمت کاهش وزن را چه چیزی می دانستید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'سیگار میکشید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "1 بسته در روز",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "2 بسته در روز",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "3 بسته در روز",
                            checked: false
                        },
                        {
                            id: 4,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا ورزش انجام می دهید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "1-3 در هفته",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "3-5 در هفته",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "هر روز",
                            checked: false
                        },
                        {
                            id: 4,
                            label: "هرگز",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'چند ساعت میخوابید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "کمتر از 5 ساعت",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "5-6 ساعت",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "7-8 ساعت",
                            checked: false
                        },
                        {
                            id: 4,
                            label: "9-10 ساعت",
                            checked: false
                        },
                        {
                            id: 5,
                            label: "بیش از 10 ساعت",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },



        ],
        modalContent: <HealthSurvey />
    },
    {
        id: 31,
        title: 'ثبت محصول',
        src: '/assets/images/ProductRegistration.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'ثبت محصول',
                    subHeadingValue: 'محصول خود را در فرم زیر ثبت کنید.'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات محصول',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: 'نام مدل',
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: 'شناسه مدل',
                    readOnly: false,
                    subNumberValue: ""
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: "چطور در مورد ما شنیدید؟",
                    subDropDownValue: "",
                    option: [
                        'آذربایجان شرقی ',
                        'آذربایجان غربی ',
                        'اردبیل ',
                        'اصفهان',
                        'البرز',
                        'ایلام',
                        'بوشهر',
                        'تهران',
                        'چهارمحال و بختیاری ',
                        'خراسان جنوبی ',
                        'خراسان رضوی ',
                        ' خراسان شمالی ',
                        'خوزستان  ',
                        'زنجان',
                        'سمنان',
                        'سیستان و بلوچستان ',
                        'فارس',
                        'قزوین',
                        'قم',
                        'کردستان',
                        'کرمان',
                        'کرمانشاه',
                        'کهگیلویه و بویراحمد ',
                        'گلستان',
                        'گیلان',
                        'لرستان',
                        'مازندران',
                        'مرکزی',
                        'هرمزگان',
                        'همدان',
                        'یزد'
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'آذربایجان شرقی ',
                        'آذربایجان غربی ',
                        'اردبیل ',
                        'اصفهان',
                        'البرز',
                        'ایلام',
                        'بوشهر',
                        'تهران',
                        'چهارمحال و بختیاری ',
                        'خراسان جنوبی ',
                        'خراسان رضوی ',
                        ' خراسان شمالی ',
                        'خوزستان  ',
                        'زنجان',
                        'سمنان',
                        'سیستان و بلوچستان ',
                        'فارس',
                        'قزوین',
                        'قم',
                        'کردستان',
                        'کرمان',
                        'کرمانشاه',
                        'کهگیلویه و بویراحمد ',
                        'گلستان',
                        'گیلان',
                        'لرستان',
                        'مازندران',
                        'مرکزی',
                        'هرمزگان',
                        'همدان',
                        'یزد'
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'تاریخ خرید',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: "آیا حاضرید به ما توصیه ای کنید؟",
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },


        ],
        modalContent: <ProductRegistration />
    },
    {
        id: 32,
        title: 'فرم سفارش فروشگاه اینترنتی',
        src: '/assets/images/OnlineStoreOrderForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم سفارش فروشگاه اینترنتی',
                    subHeadingValue: 'لطفا تمام فیلدها را پر کنید ممکن است سفارشات شما با اطلاعات ناقص پردازش نشوند.'
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                {
                    date: null,
                    dateValue: 'تاریخ ثبت سفارش',
                    subHeader: "",
                    mainDate: "Invalid date",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "Time",
                state:
                {
                    inputValue: "",
                    mainInputValue: "",
                    readOnly: false,
                    selectedOption: "ق.ظ",
                    subTimeValue: "ساعت دقیقه",
                    timeValue: 'زمان ثبت سفارش'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                {
                    nameValue: "نام و نام خانوادگی",
                    firstNameValue: "نام",
                    lastNameValue: "نام خانوادگی",
                    firstNameInput: "",
                    lastNameInput: "",
                    mainFirstNameInput: "",
                    mainLastNameInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: 'آدرس حمل و نقل',
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                {
                    phoneValue: "شماره تلفن",
                    phoneInput: null,
                    alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                    mainPhoneInput: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'حساب بانکی',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'سفارش/واحد',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                {
                    mainNumberInputValue: null,
                    numberInputValue: null,
                    numberValue: 'مقدار',
                    readOnly: false,
                    subNumberValue: ""
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'قیمت واحد / کل پرداخت ها',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'مشخصات (رنگ، ​​اندازه و ...)',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'لینک سفارش (برای مشخص کردن سفارش خود URL را کپی کنید)',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نحوه پرداخت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'چطور درباره ما شنیدید',
                    singleData: [
                        {
                            id: 1,
                            label: "وب سایت",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "رسانه های اجتماعی",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "ارجاع شده توسط یکی از دوستان",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },


        ],
        modalContent: <OnlineStoreOrderForm />
    },
    {
        id: 33,
        title: 'فرم رزرو اتاق آموزشی',
        src: '/assets/images/TrainingRoomBookingForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم رزرو اتاق آموزشی',
                    subHeadingValue: 'برای رزرو اتاق اصلی آموزشی در چسترفیلد، لطفاً از این فرم استفاده کنید'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: "نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'بخش',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue:'تاریخ شروع جلسه',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ شروع جلسه',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "Time",
                state:
                    {
                        inputValue: "",
                        mainInputValue: "",
                        readOnly: false,
                        selectedOption: "ق.ظ",
                        subTimeValue: "ساعت دقیقه",
                        timeValue: 'زمان شروع جلسه'
                    }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'تاریخ پایان جلسه',
                    subHeadingValue:null
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue:'تاریخ پایان جلسه',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "Time",
                state:
                    {
                        inputValue: "",
                        mainInputValue: "",
                        readOnly: false,
                        selectedOption: "ق.ظ",
                        subTimeValue: "ساعت دقیقه",
                        timeValue: 'زمان پایان جلسه'
                    }
            },


            {
                id:uuidv4(),
                title:"Spinner",
                state:{
                    mainSpinnerInputValue: null,
                    readOnly: false,
                    spinnerInputValue: null,
                    spinnerValue: 'تعداد حاضرین',
                    subSpinnerValue: ""
                }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'تجهیزات مورد نیاز',
                    multiData: [
                        {
                            id: 1, label: 'پروژکتور', checked: false
                        },
                        {
                            id: 2, label: 'کامپیوتر و دسترسی به اینترنت', checked: false
                        },
                        {
                            id: 3, label: 'ناهار - اعداد و نیازهای غذایی را اضافه کنید لطفاً در زیر اضافه کنید', checked: false
                        },
                        {
                            id: 4, label: 'سایر', checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'اگر تیک ناهار یا موارد دیگر را زده اید، لطفا تکمیل کنید',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <TrainingRoomBookingForm />
    },
    {
        id: 34,
        title: 'فرم رزرو اتاق آموزشی',
        src: '/assets/images/SchoolRegistrationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'ثبت نام مدرسه',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: "نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },
            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ تولد',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'جنسیت',
                    subDropDownValue: "",
                    option: ['آقا', 'خانم'],
                    textareaValue: "",
                    mainTextareaValue: ['آقا', 'خانم'],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'قومیت',
                    subDropDownValue: "",
                    option: [
                        'آفریقایی آمریکایی',
                        'اسپانیایی/لاتینی',
                        'آسیایی',
                        'قفقازی',
                        'بومی آمریکا/آلاسکا',
                        'ساکن جزیره هاوایی/اقیانوس آرام',
                        'خاورمیانه',
                        'ترجیح می دهید پاسخ ندهید',
                        'سایر'
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'آفریقایی آمریکایی',
                        'اسپانیایی/لاتینی',
                        'آسیایی',
                        'قفقازی',
                        'بومی آمریکا/آلاسکا',
                        'ساکن جزیره هاوایی/اقیانوس آرام',
                        'خاورمیانه',
                        'ترجیح می دهید پاسخ ندهید',
                        'سایر'
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: "شماره تلفن",
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'رتبه',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'ترم',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا قبلاً برای این مدرسه درخواست داده اید یا در آن شرکت کرده اید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات محل سکونت فعلی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن خانه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },


            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات محل سکونت اولیه (در صورت متفاوت بودن با بالا)',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات محل اقامت والدین/سرپرست (در صورت متفاوت بودن با بالا)',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },



        ],
        modalContent: <SchoolRegistrationForm />
    },
    {
        id: 35,
        title: 'فرم دقیق ثبت نام رویداد',
        src: '/assets/images/DetailedRegistrationEventForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم ثبت نام',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'مشخصات فردی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نام',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: ' نام خانوادگی',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'شناسه پاسپورت/شماره شهروندی',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'وابستگی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'موقعیت',
                    subDropDownValue: "",
                    option: [
                        'دانشمند پژوهشگر',
                        'پروفسور',
                        'دکتر پزشکی (MD)',
                        'دانشجوی دکتری (PhD)',
                        'دانشجوی کارشناسی ارشد (کارشناسی ارشد)',
                        'دانشجوی کارشناسی (لیسانس)',
                        'دانش آموز دبیرستان',
                        'سایر',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'دانشمند پژوهشگر',
                        'پروفسور',
                        'دکتر پزشکی (MD)',
                        'دانشجوی دکتری (PhD)',
                        'دانشجوی کارشناسی ارشد (کارشناسی ارشد)',
                        'دانشجوی کارشناسی (لیسانس)',
                        'دانش آموز دبیرستان',
                        'سایر',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'دانشگاه/موسسه',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'بخش',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'آدرس',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue:'شهر' ,
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'کشور',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: "شماره تلفن",
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },


            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات کنفرانس',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'وضعیت حضور و غیاب',
                    subDropDownValue: "",
                    option: [
                        'حسابرس (90 TL/45 €/50 $)',
                        'دانشجوی ITU (40 TL/15 €/25 $)',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'حسابرس (90 TL/45 €/50 $)',
                        'دانشجوی ITU (40 TL/15 €/25 $)',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'اسکان',
                    subDropDownValue: "",
                    option: [
                        'خیر',
                        '2 شب (27 الی 28 مرداد)',
                        '3 شب (26-27-28 مرداد)',
                        '4 شب (26-27-28-29 مرداد)',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'خیر',
                        '2 شب (27 الی 28 مرداد)',
                        '3 شب (26-27-28 مرداد)',
                        '4 شب (26-27-28-29 مرداد)',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'کارگاه های آموزشی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'کارگاه گروه اول',
                    singleData: [
                        {
                            id: 1,
                            label: "Q-PCR (20 نفر)",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "بیوانفورماتیک (20 نفر)",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'نظرات',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },




        ],
        modalContent: <DetailedRegistrationEventForm />
    },
    {
        id: 36,
        title: 'درخواست بیمه پزشکی',
        src: '/assets/images/MedicalInsuranceApplication.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درخواست بیمه پزشکی',
                    subHeadingValue: null
                }
            },


            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: "نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'جنسیت',
                    singleData:  [
                        {
                            id: 1,
                            label: "آقا",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خانم",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'سن',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },


            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'وضعیت بیمار',
                    multiData: [
                        {
                            id: 1,
                            label: "مجرد",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "متاهل",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "دانشجو",
                            checked: false
                        },
                        {
                            id: 4,
                            label: "استخدام",
                            checked: false
                        },
                        {
                            id: 5,
                            label: "سایر",
                            checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: "تاریخ تولد",
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن (خانه)',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن (موبایل)',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },




            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'سایر متقاضیان تحت پوشش - شریک / فرزندان',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'عنوان',
                    subDropDownValue: "",
                    option: ['همسر' , 'فرزندان'],
                    textareaValue: "",
                    mainTextareaValue: ['همسر' , 'فرزندان'],
                    mainOption: null,
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نام و نام خانوادگی',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'جنسیت',
                    singleData:  [
                        {
                            id: 1,
                            label: "آقا",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خانم",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: "تاریخ تولد",
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },


            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'عنوان',
                    subDropDownValue: "",
                    option: ['همسر' , 'فرزندان'],
                    textareaValue: "",
                    mainTextareaValue: ['همسر' , 'فرزندان'],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نام و نام خانوادگی',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'جنسیت',
                    singleData:  [
                        {
                            id: 1,
                            label: "آقا",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خانم",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: "تاریخ تولد",
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

        ],
        modalContent: <MedicalInsuranceApplication />
    },
    {
        id: 37,
        title: 'فرم تماس سفر',
        src: '/assets/images/TravelContactForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم تماس سفر',
                    subHeadingValue: null
                }
            },


            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: "نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن (خانه)',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "شهر مبدأ",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "شهر مقصد",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ حرکت',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "Time",
                state:
                    {
                        inputValue: "",
                        mainInputValue: "",
                        readOnly: false,
                        selectedOption: "ق.ظ",
                        subTimeValue: "ساعت دقیقه",
                        timeValue: "زمان حرکت"
                    }
            },


            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ برگشت',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "Time",
                state:
                    {
                        inputValue: "",
                        mainInputValue: "",
                        readOnly: false,
                        selectedOption: "ق.ظ",
                        subTimeValue: "ساعت دقیقه",
                        timeValue: 'زمان بازگشت'
                    }
            },





            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'مقدمات سفر مورد نیاز است.',
                    singleData:  [
                        {
                            id: 1,
                            label: "پرواز",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "هتل",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "ماشین کرایه ای",
                            checked: false
                        },
                        {
                            id: 4,
                            label: "سایر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'لطفا تمام فایل های لازم را آپلود کنید',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue:'اطلاعات تکمیلی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <TravelContactForm />
    },
    {
        id: 38,
        title: 'فرم آپلود سند',
        src: '/assets/images/DocumentUploadForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم آپلود سند',
                    subHeadingValue: null
                }
            },


            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: "نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'عنوان سند',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue:'شرح سند',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'لطفا سند خود را آپلود کنید',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },

        ],
        modalContent: <DocumentUploadForm />
    },
    {
        id: 39,
        title: 'فرم ثبت نام وبینار',
        src: '/assets/images/WebinarRegistrationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم ثبت نام وبینار',
                    subHeadingValue: null
                }
            },


            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: "نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: " تلفن",
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'شرکت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: 'آدرس شرکت',
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'وب سایت شرکت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'چطور در مورد ما شنیدی؟',
                    multiData: [
                        {
                            id: 1,
                            label: "رسانه های اجتماعی",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "دوستان و آشنایان",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "تبلیغات",
                            checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },


        ],
        modalContent: <WebinarRegistrationForm />
    },
    {
        id: 40,
        title: 'بررسی فناوری برای آموزش از راه دور',
        src: '/assets/images/TechnologySurveyForRemoteLearning.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'بررسی فناوری برای آموزش از راه دور',
                    subHeadingValue: null
                }
            },


            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: "نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'بسیار مفید',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '1. لطفاً نرم افزار مورد استفاده در حین یادگیری از راه دور را ارزیابی کنید.',
                    subScaleValue: "",
                    worthValue: 'ضعیف'
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'خیلی خوب',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '2. لطفاً کیفیت صدا را در حین یادگیری از راه دور ارزیابی کنید.',
                    subScaleValue: "",
                    worthValue: 'ضعیف'
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'خیلی خوب',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '3. لطفاً کیفیت ویدیو را در حین یادگیری از راه دور ارزیابی کنید.',
                    subScaleValue: "",
                    worthValue: 'ضعیف'
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'موافقم',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '4. می توانم هر موضوعی را که مدرس ذکر می کند بشنوم و پیگیری کنم.',
                    subScaleValue: "",
                    worthValue: 'مخالفم'
                }
            },


            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: '5. لطفا دستگاهی را که بیشتر برای یادگیری از راه دور استفاده می کنید انتخاب کنید.',
                    singleData: [
                        {
                            id: 1,
                            label: "لپ تاپ",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "دسکتاپ",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "تبلت",
                            checked: false
                        },
                        {
                            id: 4,
                            label: "تلفن همراه",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'سریع',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '6. لطفا سرعت اینترنت خود را ارزیابی کنید.',
                    subScaleValue: "",
                    worthValue: 'آرام'
                }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'موافقم',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: '7. من در طول جلسات با مشکلات اتصال مواجه شده ام.',
                    subScaleValue: "",
                    worthValue: 'مخالفم'
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: '8. یادداشت های اضافی / بازخورد',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },


        ],
        modalContent: <TechnologySurveyForRemoteLearning />
    },
    {
        id: 41,
        title: 'فرم رزرو فرودگاهی',
        src: '/assets/images/AirportPickupReservationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'رزرو سفر',
                    subHeadingValue: 'یک پیکاپ رزرو کنید'
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات شخصی',
                    subHeadingValue: null
                }
            },


            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: "نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات سفر',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'نوع سفر',
                    singleData: [
                        {
                            id: 1,
                            label: "یک طرفه",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "رفت و برگشت",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "ساعتی",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ رفت',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "Time",
                state:
                    {
                        inputValue: "",
                        mainInputValue: "",
                        readOnly: false,
                        selectedOption: "ق.ظ",
                        subTimeValue: "ساعت دقیقه",
                        timeValue: 'زمان رفت'
                    }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ بازگشت',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "Time",
                state:
                    {
                        inputValue: "",
                        mainInputValue: "",
                        readOnly: false,
                        selectedOption: "ق.ظ",
                        subTimeValue: "ساعت دقیقه",
                        timeValue: 'زمان بازگشت'
                    }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'تعداد ساعت',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا این یک تحویل فرودگاهی است؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'فرودگاه',
                    subDropDownValue: "",
                    option: ['فرودگاه اول','فرودگاه دوم', 'فرودگاه سوم' , 'سایر'],
                    textareaValue: "",
                    mainTextareaValue: ['فرودگاه اول','فرودگاه دوم', 'فرودگاه سوم' , 'سایر'],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'شرکت هواپیمایی',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'شماره پرواز',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'فرودگاه',
                    subDropDownValue: "",
                    option: [
                        '1 عدد',
                        '2 عدد',
                        '3 عدد',
                        '4 عدد',
                        '5 عدد',
                        '6 عدد',
                        '7 عدد',
                        '8 عدد',
                        '9 عدد',
                        '10 عدد',
                        '10 عدد',
                        'بیش از 10 قطعه'
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        '1 عدد',
                        '2 عدد',
                        '3 عدد',
                        '4 عدد',
                        '5 عدد',
                        '6 عدد',
                        '7 عدد',
                        '8 عدد',
                        '9 عدد',
                        '10 عدد',
                        '10 عدد',
                        'بیش از 10 قطعه'
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'مکان/آدرس دریافت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'محل/آدرس تحویل',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'تعداد مسافران',
                    subDropDownValue: "",
                    option: [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12',
                        '13',
                        '14',
                        'بیش از 14 مسافر'
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12',
                        '13',
                        '14',
                        'بیش از 14 مسافر'
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'توقف های اضافی؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'لطفا توقف(های) را مشخص کنید',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا به صندلی ماشین کودک نیاز دارید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'چه تعداد؟',
                    subDropDownValue: "",
                    option: [
                        '1',
                        '2',
                        '3',
                        '4',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        '1',
                        '2',
                        '3',
                        '4',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'نظر/درخواست های ویژه',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },


        ],
        modalContent: <AirportPickupReservationForm />
    },
    {
        id: 42,
        title: 'فرم سفارش خرید محصول',
        src: '/assets/images/ProductPurchaseOrderForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'سفارش خرید',
                    subHeadingValue: 'دوست دارید چه چیزی بخرید؟'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: 'نام شما',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل شما',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: 'آدرس حمل و نقل',
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: "ProductList",
                state:
                    {
                        products: [],
                        productValue: 'محصولات من',
                        totalPrice: 10000,
                        totalQuantity: 1,
                        mainProducts: [
                            {
                                id: uuidv4(),
                                name: "نام محصولات",
                                description: "توضیحات را وارد کنید",
                                image: null,
                                price: "10000",
                                quantity: 1
                            },
                            {
                                id: uuidv4(),
                                name: "نام محصولات",
                                description: "توضیحات را وارد کنید",
                                image: null,
                                price: "20000",
                                quantity: 2
                            },
                            {
                                id: uuidv4(),
                                name: "نام محصولات",
                                description: "توضیحات را وارد کنید",
                                image: null,
                                price: "30000",
                                quantity: 3
                            },
                            {
                                id: uuidv4(),
                                name: "نام محصولات",
                                description: "توضیحات را وارد کنید",
                                image: null,
                                price: "40000",
                                quantity: 4
                            }
                        ],
                        readOnly: false
                    }
            },


            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'روش های پرداخت',
                    singleData: [
                        {
                            id: 1,
                            label: "کارت بانکی",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "پرداخت در مقابل منزل",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },


        ],
        modalContent: <ProductPurchaseOrderForm />
    },
    {
        id: 43,
        title:'فرم درخواست سرور جدید',
        src: '/assets/images/NewServerRequestForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درخواست سرور جدید',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات عمومی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: 'نام درخواست کننده',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل درخواست کننده',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن درخواست کننده',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue:'نوع سرور (همه موارد اعمال شده را بررسی کنید)',
                    multiData: [
                        {
                            id: 1,
                            label: 'سرور برنامه',
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'سرور پایگاه داده',
                            checked: false
                        },
                        {
                            id: 3,
                            label: 'فایل / سرور چاپ',
                            checked: false
                        },
                        {
                            id: 4,
                            label: 'وب سرور',
                            checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'محیط (یکی را انتخاب کنید)',
                    singleData: [
                        {
                            id: 1,
                            label: 'تولید',
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'توسعه دهنده / تست',
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'سیستم مورد نیاز',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'نوع سرور',
                    singleData: [
                        {
                            id: 1,
                            label: 'ماشین مجازی',
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'سرور فیزیکی',
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نام سرور',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'تعداد CPU ها',
                    singleData: [
                        {
                            id: 1,
                            label: 2,
                            checked: false
                        },
                        {
                            id: 2,
                            label: 4,
                            checked: false
                        },
                        {
                            id: 3,
                            label: 8,
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'مقدار رم (گیگابایت)',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'دستورالعمل ها یا درخواست های ویژه',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فضای دیسک مورد نیاز (گیگابایت)',
                    subHeadingValue: null
                }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'C: (OS-Install) درایو:',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'D (نصب) درایو',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'F (داده) درایو',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'درایو G (گزارشات)',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'درایو H (داده‌های موقت)',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'درایو J (گزارشات موقت)',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },
            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'درایو R (پشتیبان‌ها)',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },



        ],
        modalContent: <NewServerRequestForm />
    },
    {
        id: 44,
        title:'پرسشنامه مشتری',
        src: '/assets/images/ClientQuestionnaire.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'پرسشنامه مشتری',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: 'نام درخواست کننده',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'عنوان',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'بخش',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نام شرکت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'صنعت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'ایمیل درخواست کننده',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن درخواست کننده',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue:'به کدام یک از خدمات ما علاقه دارید؟',
                    multiData: [
                        {
                            id: 1,
                            label: 'خدمات مشتری',
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'حسابداری',
                            checked: false
                        },
                        {
                            id: 3,
                            label: 'استخدام',
                            checked: false
                        },
                        {
                            id: 4,
                            label: 'منابع انسانی',
                            checked: false
                        },
                        {
                            id: 5,
                            label: 'مدیریت فایل',
                            checked: false
                        },
                        {
                            id: 6,
                            label: 'امنیت',
                            checked: false
                        },

                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue:'چطور در مورد ما شنیدی؟',
                    multiData: [
                        {
                            id: 1,
                            label: 'مراجعه',
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'پست مستقیم',
                            checked: false
                        },
                        {
                            id: 3,
                            label: 'تبلیغات آنلاین',
                            checked: false
                        },
                        {
                            id: 4,
                            label: 'چاپ آگهی',
                            checked: false
                        },
                        {
                            id: 5,
                            label: 'سایر',
                            checked: false
                        },

                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'پیوست',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'یادداشت شما',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <ClientQuestionnaire />
    },
    {
        id: 45,
        title:'پرسشنامه بازاریابی املاک و مستغلات',
        src: '/assets/images/RealEstateMarketingQuestionnaire.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'پرسشنامه بازاریابی املاک و مستغلات',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: "نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue:'در حال حاضر از کدام نوع بازاریابی استفاده می کنید؟',
                    multiData: [
                        {
                            id: 1,
                            label: 'روزنامه/چاپ',
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'تبلیغات فیس بوک',
                            checked: false
                        },
                        {
                            id: 3,
                            label: 'تراکت',
                            checked: false
                        },
                        {
                            id: 4,
                            label: 'تبلیغات اینستاگرام',
                            checked: false
                        },
                        {
                            id: 5,
                            label: 'تلویزیون',
                            checked: false
                        },
                        {
                            id: 6,
                            label: 'گوگل',
                            checked: false
                        },
                        {
                            id: 7,
                            label: 'سئو',
                            checked: false
                        },
                        {
                            id: 8,
                            label: 'رادیو',
                            checked: false
                        },
                        {
                            id: 9,
                            label: 'سایر',
                            checked: false
                        },
                        {
                            id: 10,
                            label: 'هیچ کدام',
                            checked: false
                        },

                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'بزرگترین مانع شماره 1 که شما را در حال حاضر از رسیدن به اهداف رشد خود باز می دارد چیست؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'چقدر انگیزه دارید که امسال معاملات ملکی بیشتری داشته باشید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'آیا در حال حاضر به صورت آنلاین سرنخ ایجاد می کنید یا در گذشته داشته اید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'اگر بله، لطفاً می توانید آن تجربه را به طور خلاصه توضیح دهید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'شما/تیم شما در سال گذشته چند واحد فروختید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'چند نفر در تیم شما هستند؟ (در صورت وجود)',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'اگر راه حل مناسبی برای اضافه کردن 2، 3 و حتی 4 یا 5 خانه در ماه به خط لوله خود ارائه شود، در مقیاس 1 تا 10 چقدر علاقه مند خواهید بود که مشتری شوید؟',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },
            {
                id: uuidv4(),
                title: 'ScaleRating',
                state: {
                    active: null,
                    bestValue: 'خیلی علاقه‌مند',
                    mainActive: 0,
                    readOnly: false,
                    scaleValue: 'اگر راه حل مناسبی برای اضافه کردن 2، 3 و حتی 4 یا 5 خانه در ماه به خط لوله خود ارائه شود، در مقیاس 1 تا 10 چقدر علاقه مند خواهید بود که مشتری شوید؟',
                    subScaleValue: "",
                    worthValue: 'علاقه‌مند نیستم'
                }
            },

        ],
        modalContent: <RealEstateMarketingQuestionnaire />
    },
    {
        id: 46,
        title:'پرسشنامه سرمایه گذار',
        src: '/assets/images/InvestorsQuestionnaire.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'پرسشنامه سرمایه گذار',
                    subHeadingValue: 'لطفا فرم زیر را پر کنید.'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: 'نام سرمایه گذار',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن همراه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نام شرکت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'وب سایت شرکت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'معیارهای سرمایه گذاری: ملک سرمایه گذاری ایده آل شما چگونه است؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'مناطق یا جوامعی که به دنبال سرمایه گذاری در آنها هستید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue:'محدوده قیمت تقریبی',
                    multiData: [
                        {
                            id: 1,
                            label: '$1,000 - $50,000',
                            checked: false
                        },
                        {
                            id: 2,
                            label: '$50,001 - $75,000',
                            checked: false
                        },
                        {
                            id: 3,
                            label: '$75,001 - $100,000',
                            checked: false
                        },
                        {
                            id: 4,
                            label: 'بیش از 100000 دلار',
                            checked: false
                        },

                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue:'سالی چند خانه می خرید؟',
                    multiData: [
                        {
                            id: 1,
                            label: '1 - 5',
                            checked: false
                        },
                        {
                            id: 2,
                            label: '6 - 10',
                            checked: false
                        },
                        {
                            id: 3,
                            label: '11 - 15',
                            checked: false
                        },
                        {
                            id: 4,
                            label: 'بالای 15',
                            checked: false
                        },

                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue:'انواع املاک مورد علاقه شما',
                    multiData: [
                        {
                            id: 1,
                            label: 'خانواده مجرد',
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'چند خانواده',
                            checked: false
                        },
                        {
                            id: 3,
                            label: 'خانه شهری',
                            checked: false
                        },
                        {
                            id: 4,
                            label: 'آپارتمان مالکیتی',
                            checked: false
                        },
                        {
                            id: 5,
                            label: 'تجاری دیگر',
                            checked: false
                        },
                        {
                            id: 6,
                            label: 'سایر',
                            checked: false
                        },

                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue:'آیا از فاینانس برای پروژه های خود استفاده می کنید یا پول نقد پرداخت می کنید؟',
                    multiData: [
                        {
                            id: 1,
                            label: 'تأمین مالی',
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'نقد',
                            checked: false
                        },

                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'چه چیزی برای شما یک معامله خوب است؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'آیا حداقل تعداد اتاق خواب و/یا حمام مورد نیاز شما یا حداقل متراژ مربع وجود دارد؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue:'آیا به دنبال املاکی برای بازسازی و فروش مجدد هستید یا قصد خرید و نگهداری دارید؟',
                    multiData: [
                        {
                            id: 1,
                            label: 'بازسازی و فروش مجدد',
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'خرید و نگه داری',
                            checked: false
                        },

                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue:'آیا مایل به انجام توانبخشی کامل هستید یا فقط به دنبال رویه های فیکس کننده آرایشی هستید؟',
                    multiData: [
                        {
                            id: 1,
                            label: 'بازسازی کامل ',
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'تعمیرات ظاهری',
                            checked: false
                        },

                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'چقدر زود به دنبال خرید هستید و معمولاً چقدر زمان برای بستن نیاز دارید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'اطلاعات اضافی که دوست دارید بدانیم',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue:'چطور در مورد ما شنیدید؟',
                    multiData: [
                        {
                            id: 1,
                            label: 'مرجع شخصی',
                            checked: false
                        },
                        {
                            id: 2,
                            label: 'معرفی در روز بازدید',
                            checked: false
                        },
                        {
                            id: 3,
                            label: 'تماس طبقه / ورود بدون قرار قبلی',
                            checked: false
                        },
                        {
                            id: 4,
                            label: 'کارت پستال',
                            checked: false
                        },
                        {
                            id: 5,
                            label: 'رسانه‌های اجتماعی',
                            checked: false
                        },
                        {
                            id: 6,
                            label: 'ایمیل',
                            checked: false
                        },
                        {
                            id: 7,
                            label: 'سایر',
                            checked: false
                        },

                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Captcha',
                state: {}
            },

        ],
        modalContent: <InvestorsQuestionnaire />
    },
    {
        id: 47,
        title:"فرم درخواست وقت ملاقات",
        src: '/assets/images/RequestAnAppointmentForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درخواست قرار ملاقات',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: 'نام سرمایه گذار',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن همراه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'اولین بازدید شماست؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: 'یک قرار ملاقات را انتخاب کنید',
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },



            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'نظرات',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },


        ],
        modalContent: <RequestAnAppointmentForm />
    },
    {
        id: 48,
        title:'فرم قرار ملاقات خدمات حیوانات خانگی',
        src: '/assets/images/PetServiceAppointmentForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم قرار ملاقات خدمات حیوانات خانگی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: 'نام مالک',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن همراه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات حیوان خانگی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: 'نام حیوان خانگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نژاد',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'سن حیوان خانگی',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'جنسیت',
                    singleData:  [
                        {
                            id: 1,
                            label: "نر",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "ماده",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات خدمات',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'دوست دارید کدام سرویس را دریافت کنید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "نظافت",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "گردش",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: 'یک قرار ملاقات را انتخاب کنید',
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },

        ],
        modalContent: <PetServiceAppointmentForm />
    },
    {
        id: 49,
        title:'فرم نظرسنجی محصول',
        src: '/assets/images/ProductSurveyForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات مشتری:',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: 'نام مشتری',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تماس',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'چه مدت از این محصول استفاده می کنید و چرا؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'نظرات و پیشنهادات خود را در مورد محصولات ما در مقایسه با سایر رقبا بنویسید:',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'آیا از عملکرد محصول ما راضی هستید؟ نظرات خود را به اشتراک بگذارید:',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'درباره تجارب خرید خود برای خرید محصول ما چیزی به ما بگویید:',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'آیا می خواهید با محصول ما ادامه دهید؟ اگر نه چرا:',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'دوست دارید چه نوع تغییراتی را در محصولات ما مشاهده کنید تا سطح رضایت شما را افزایش دهد؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <ProductSurveyForm />
    },
    {
        id: 50,
        title:'فرم ثبت تجارت',
        src: '/assets/images/TradeRegistrationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم قرار ملاقات خدمات حیوانات خانگی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: 'صاحب کسب و کار',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نام کسب و کار',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تماس',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نوع کسب و کار',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'مکان مورد نظر',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نرخ ساعتی',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'پیام',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'Captcha',
                state: {}
            },

        ],
        modalContent: <TradeRegistrationForm />
    },
    {
        id: 51,
        title:'درخواست عضویت',
        src: '/assets/images/SignupForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درخواست عضویت',
                    subHeadingValue: 'برای درخواست عضویت لطفا تمام سوالات را تکمیل کنید.'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue: 'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره خانه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن همراه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },
            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره کار',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'وب سایت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'مدت عضویت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'سطح عضویت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Captcha',
                state: {}
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ عضویت',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

        ],
        modalContent: <SignupForm />
    },
    {
        id: 52,
        title:'فرم طراحی و توسعه وب سایت',
        src: '/assets/images/WebsiteDesignAndDevelopmentForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درخواست عضویت',
                    subHeadingValue: 'برای درخواست عضویت لطفا تمام سوالات را تکمیل کنید.'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تماس',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'شرکت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'نوع کسب و کار',
                    subDropDownValue: "",
                    option: [
                        'وزارت',
                        'مشاوره',
                        'غیر انتفاعی',
                        'غذا',
                        'تکنولوژی',
                        'تجارت الکترونیک',
                        'موسیقی',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'وزارت',
                        'مشاوره',
                        'غیر انتفاعی',
                        'غذا',
                        'تکنولوژی',
                        'تجارت الکترونیک',
                        'موسیقی',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'مهلت تکمیل سایت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'بودجه شما چقدر است؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا قبلاً صاحب نام دامنه هستید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'نوع وب سایت مورد نیاز شما؟',
                    subDropDownValue: "",
                    option: [
                        'شخصی',
                        'آموزشی',
                        'ورزش',
                        'غذا، رستوران',
                        'کسب و کار محلی',
                        'کسب و کار کوچک',
                        'کسب و کار متوسط',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'شخصی',
                        'آموزشی',
                        'ورزش',
                        'غذا، رستوران',
                        'کسب و کار محلی',
                        'کسب و کار کوچک',
                        'کسب و کار متوسط',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'چه دو رنگی برای تم سایت خود می خواهید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'موارد را برای نوار پیمایش انتخاب کنید',
                    multiData: [
                        {
                            id: 1,
                            label: "درباره",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "سوالات متداول",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "وبلاگ",
                            checked: false
                        },
                        {
                            id: 4,
                            label: "قیمت گذاری",
                            checked: false
                        },
                        {
                            id: 5,
                            label: "تجارت الکترونیک",
                            checked: false
                        },
                        {
                            id: 6,
                            label: "رزرو",
                            checked: false
                        },
                        {
                            id: 7,
                            label: "رسانه ها",
                            checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'موارد بیشتر',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'چه چیزی ارائه می دهید؟ فهرستی از تمام بخش‌ها/صفحه‌هایی که فکر می‌کنید به آن‌ها نیاز دارید تهیه کنید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'خدمات اضافی',
                    multiData: [
                        {
                            id: 1,
                            label: "طراحی بنر",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "طراحی صفحه لندینگ",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "مدیریت تبلیغات",
                            checked: false
                        },
                        {
                            id: 4,
                            label: "سئو",
                            checked: false
                        },
                        {
                            id: 5,
                            label: "خرید دامنه",
                            checked: false
                        },
                        {
                            id: 6,
                            label: "طراحی لوگو",
                            checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'کاربر هنگام بازدید از سایت شما چه اقداماتی را باید انجام دهد؟',
                    multiData: [
                        {
                            id: 1,
                            label: "تماس با شما",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "فرم تماس را پر کنید",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "خرید محصول",
                            checked: false
                        },
                        {
                            id: 4,
                            label: "رزرو",
                            checked: false
                        },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },



            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'آیا ویژگی های دیگری وجود دارد که می خواهید برای سایت خود یا چیزهایی که می خواهید در آینده اضافه کنید؟ لطفا تا حد امکان دقیق و دقیق بیان کنید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'نظرات اضافی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <WebsiteDesignAndDevelopmentForm />
    },
    {
        id: 53,
        title:'فرم ثبت نام کلاس آموزش',
        src: '/assets/images/EducationClassRegistrationForm.png',
        state: [
            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'پیش ثبت نام کلاس',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام والدین/سرپرست (اگر کمتر از 18 سال باشد)',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: "تاریخ تولد",
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن خانه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن همراه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'بهترین زمان برای تماس چه زمانی است؟',
                    singleData: [
                        {
                            id: 1,
                            label: "صبح ها",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "عصرها",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "آخر هفته ها",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'چقدر زود علاقه مند به شروع دوره آموزشی خود هستید؟',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'G.E.D. کلاس',
                    subDropDownValue: "",
                    option: [
                        '101',
                        '102',
                        '103',
                        '201',
                        '202',
                        '301',
                        '302',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        '101',
                        '102',
                        '103',
                        '201',
                        '202',
                        '301',
                        '302',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'آموزش کاریابی',
                    subDropDownValue: "",
                    option: [
                        'مبتدی',
                        'متوسط',
                        'پیشرفته',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'مبتدی',
                        'متوسط',
                        'پیشرفته',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'مبانی کامپیوتر',
                    subDropDownValue: "",
                    option: [
                        'استفاده از اینترنت',
                        'واژه پردازی پایه',
                        'ایمیل',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'استفاده از اینترنت',
                        'واژه پردازی پایه',
                        'ایمیل',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'معرفی مایکروسافت آفیس',
                    subDropDownValue: "",
                    option: [
                        '101',
                        '102',
                        '103',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        '101',
                        '102',
                        '103',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'تدریس خصوصی سفرهای مجازی',
                    subDropDownValue: "",
                    option: [
                        'گزینه 1',
                        'گزینه 2',
                        'گزینه 3',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'گزینه 1',
                        'گزینه 2',
                        'گزینه 3',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'طرح های ارزیابی فردی و پیش آزمون',
                    subDropDownValue: "",
                    option: [
                        'گزینه 1',
                        'گزینه 2',
                        'گزینه 3',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'گزینه 1',
                        'گزینه 2',
                        'گزینه 3',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

        ],
        modalContent: <EducationClassRegistrationForm />
    },
    {
        id: 54,
        title:'الگوی فرم تماس اضطراری',
        src: '/assets/images/EmergencyContactFormTemplate.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم تماس اضطراری',
                    subHeadingValue: 'ارائه اطلاعات حیاتی برای ایمنی و رفاه کارکنان'
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات شخصی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'شماره کارمند',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن خانه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن همراه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ تولد',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },



            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'تماس اضطراری',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'نسبت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن خانه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن همراه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن محل کار',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },



            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'تماس اضطراری ثانویه',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "بخش",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن خانه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن همراه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن محل کار',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },



            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات پزشکی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'پزشک اولیه',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue:'مرکز پزشکی',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'اطلاعات تکمیلی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <EmergencyContactFormTemplate />
    },

    {
        id: 55,
        title:'درخواست اطلاعات',
        src: '/assets/images/InformationRequestForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درخواست اطلاعات',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن خانه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'درخواست اطلاعات درباره',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'دستورالعمل های اضافی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <InformationRequestForm />
    },

    {
        id: 56,
        title:'فرم درخواست قرار ملاقات',
        src: '/assets/images/AppointmentRequestForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم درخواست قرار ملاقات',
                    subHeadingValue: 'به ما اطلاع دهید که چگونه می توانیم به شما کمک کنیم!'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'آدرس ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تماس',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: 'چه تاریخ و ساعتی برای شما بهتر عمل می کند؟',
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'هر تاریخ و زمان مشخص دیگری، در صورتی که انتخاب فوق مناسب نباشد.',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'به چه خدماتی علاقه دارید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا مایلید از خدمات تبلیغاتی مطلع شوید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },


        ],
        modalContent: <AppointmentRequestForm />
    },

    {
        id: 57,
        title:'فرم قرار ملاقات',
        src: '/assets/images/AppointmentForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم قرار ملاقات',
                    subHeadingValue: 'برای تعیین وقت، لطفا اطلاعات زیر را پر کنید.'
                }
            },

            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: 'لطفاً تاریخ ملاقات را انتخاب کنید',
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'جزئیات قرار ملاقات',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تماس',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: ' ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'بهترین روش برای تماس با شما؟',
                    subDropDownValue: "",
                    option: [
                        'ایمیل',
                        'تلفن'
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'ایمیل',
                        'تلفن'
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'بهترین زمان برای تماس با شما؟',
                    subDropDownValue: "",
                    option: [
                        'صبح',
                        'بعد از ظهر',
                        'عصر',
                        'شب',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'صبح',
                        'بعد از ظهر',
                        'عصر',
                        'شب',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'چگونه می توانیم به شما کمک کنیم؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'نکات تکمیلی:',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },


        ],
        modalContent: <AppointmentForm />
    },

    {
        id: 58,
        title:'درخواست خدمات فناوری اطلاعات',
        src: '/assets/images/ITServiceTicketForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درخواست خدمات فناوری اطلاعات',
                    subHeadingValue: 'لطفا جزئیات مشکل را ارائه دهید'
                }
            },


            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: ' ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },


            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "بخش",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: "شناسه کامپیوتر",
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'آپلود اسکرین شات',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: "مشکل را شرح دهید",
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },


        ],
        modalContent: <ITServiceTicketForm />
    },

    {
        id: 59,
        title:'فرم درخواست خدمات',
        src: '/assets/images/ServiceEnquiryForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'استعلام خدمات',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: ' ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'شناسه سفارش',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'نوع درخواست',
                    subDropDownValue: "",
                    option: [
                        'دریافت نشده است',
                        'ترتیب نادرست',
                        'سفارش آفلاین',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'دریافت نشده است',
                        'ترتیب نادرست',
                        'سفارش آفلاین',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'جزئیات',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },


        ],
        modalContent: <ServiceEnquiryForm />
    },

    {
        id: 60,
        title:'گزارش تماس مشتری',
        src: '/assets/images/ClientCallLog.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'ثبت جزئیات تماس',
                    subHeadingValue: 'لطفاً فرم زیر را پر کنید و به وضوح نوع تماس و بهترین زمان برای تماس را مشخص کنید.'
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'نوع تماس',
                    singleData: [
                        {
                            id: 1,
                            label: "ورودی",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خروجی",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "کنفرانس",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'مشتری',
                    subDropDownValue: "",
                    option: [
                        'نام مشتری 1',
                        'نام مشتری 2',
                        'نام مشتری 3',
                        'نام مشتری 4',
                        'سایر'
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'نام مشتری 1',
                        'نام مشتری 2',
                        'نام مشتری 3',
                        'نام مشتری 4',
                        'سایر'
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'نکات تکمیلی:',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: "تاریخ ",
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "Time",
                state:
                    {
                        inputValue: "",
                        mainInputValue: "",
                        readOnly: false,
                        selectedOption: "ق.ظ",
                        subTimeValue: "ساعت دقیقه",
                        timeValue: 'زمان '
                    }
            },


        ],
        modalContent: <ClientCallLog />
    },

    {
        id: 61,
        title:'فرم درخواست حق چاپ',
        src: '/assets/images/CopyrightRequestForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات درخواست کننده',
                    subHeadingValue: 'لطفاً اطلاعاتی در مورد درخواست کننده مجوز حق نشر ارائه دهید.'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام شما',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'شرکت/سازمان',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'عنوان شغلی',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'آدرس ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تماس',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات محتوا',
                    subHeadingValue: 'لطفا اطلاعاتی در مورد محتوای ارجاع دهید.'
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'مقاله کجا منتشر شده است؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },
            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'عنوان مقاله ای که باید ارجاع داده شود',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'صفحات برای ارجاع',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام نویسنده',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'محل انتشار',
                    subDropDownValue: "",
                    option: [
                        "تهران",
                        "البرز",
                        "مازندران",
                        "گلستان",
                        "خراسان رضوی",
                        "خراسان شمالی",
                        "خراسان جنوبی",
                        "سیستان و بلوچستان",
                        "کرمان",
                        "هرمزگان",
                        "فارس",
                        "بوشهر",
                        "کهگیلویه و بویراحمد",
                        "چهارمحال و بختیاری",
                        "اصفهان",
                        "یزد",
                        "مرکزی",
                        "قم",
                        "قزوین",
                        "زنجان",
                        "گیلان",
                        "اردبیل",
                        "آذربایجان شرقی",
                        "آذربایجان غربی",
                        "کردستان",
                        "همدان",
                        "کرمانشاه",
                        "لرستان",
                        "ایلام",
                        "خوزستان"
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        "تهران",
                        "البرز",
                        "مازندران",
                        "گلستان",
                        "خراسان رضوی",
                        "خراسان شمالی",
                        "خراسان جنوبی",
                        "سیستان و بلوچستان",
                        "کرمان",
                        "هرمزگان",
                        "فارس",
                        "بوشهر",
                        "کهگیلویه و بویراحمد",
                        "چهارمحال و بختیاری",
                        "اصفهان",
                        "یزد",
                        "مرکزی",
                        "قم",
                        "قزوین",
                        "زنجان",
                        "گیلان",
                        "اردبیل",
                        "آذربایجان شرقی",
                        "آذربایجان غربی",
                        "کردستان",
                        "همدان",
                        "کرمانشاه",
                        "لرستان",
                        "ایلام",
                        "خوزستان"
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'مشتری',
                    subDropDownValue: "",
                    option: [
                        'نام مشتری 1',
                        'نام مشتری 2',
                        'نام مشتری 3',
                        'نام مشتری 4',
                        'سایر'
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'نام مشتری 1',
                        'نام مشتری 2',
                        'نام مشتری 3',
                        'نام مشتری 4',
                        'سایر'
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ انتشار',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درباره کار شما',
                    subHeadingValue: 'لطفاً اطلاعاتی در مورد کار خود ارائه دهید که در آن مطالب ذکر شده در بالا را ارجاع دهید.'
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'مقاله فوق در کجا مورد استفاده قرار خواهد گرفت؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نویسنده اثر شما',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'ناشر اثر شما',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'رایگان یا پولی؟',
                    singleData: [
                        {
                            id: 1,
                            label: "رایگان",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "پرداخت",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'قالب کار شما؟',
                    singleData: [
                        {
                            id: 1,
                            label: "چاپ",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "آنلاین",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'قیمت فروش مورد انتظار',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },
            {
                id: uuidv4(),
                title: 'Captcha',
                state: {}
            },


        ],
        modalContent: <CopyrightRequestForm />
    },

    {
        id: 62,
        title:'فرم درخواست زمان استراحت',
        src: '/assets/images/TimeOffRequestForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درخواست زمان استراحت',
                    subHeadingValue: 'درخواست مرخصی از محل کار'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام کارمند',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'آدرس ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تماس',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ شروع',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ پایان',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'شروع به کار کنید',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'دلیل',
                    subDropDownValue: "",
                    option: [
                        'تعطیلات',
                        'مرخصی شخصی',
                        'بیماری',
                        'سایر',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'تعطیلات',
                        'مرخصی شخصی',
                        'بیماری',
                        'سایر',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue:'نظرات اضافی' ,
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },


        ],
        modalContent: <TimeOffRequestForm />
    },

    {
        id: 63,
        title:'الگوی گزارش پیشرفت دانش آموز',
        src: '/assets/images/StudentProgressReportTemplate.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'گزارش پیشرفت دانش آموزان',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام معلم',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'موضوع و سطح',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'سطح کلاس را مشخص کنید.',
                    subDropDownValue: "",
                    option: [
                        'درجه 1',
                        'درجه21',
                        'درجه 3',
                        'درجه 4',
                        'درجه 5',
                        'درجه 6',
                        'درجه 7',
                        'درجه 8',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'درجه 1',
                        'درجه21',
                        'درجه 3',
                        'درجه 4',
                        'درجه 5',
                        'درجه 6',
                        'درجه 7',
                        'درجه 8',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue:'فهرست دانش‌آموزان' ,
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'منطقه / مناطق مورد نظر را انتخاب کنید',
                    subDropDownValue: "",
                    option: [
                        'تحصیلی (توانایی)',
                        'تمرکز (رفتار/نگرش/علاقه)',
                        'دانشگاهی و تمرکز',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'تحصیلی (توانایی)',
                        'تمرکز (رفتار/نگرش/علاقه)',
                        'دانشگاهی و تمرکز',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue:'راه حل پیشنهادی' ,
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },


        ],
        modalContent: <StudentProgressReportTemplate />
    },

    {
        id: 64,
        title:'فرم قرارداد خدمات نظافت',
        src: '/assets/images/CleaningServiceAgreementForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'قرارداد خدمات نظافت منزل',
                    subHeadingValue: 'امیدواریم از خدمات ما لذت ببرید و شما را تشویق می کنیم که هر گونه بازخوردی را در اختیار ما قرار دهید.'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:"نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: 'تاریخ قرار ملاقات',
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن خانه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن همراه',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'تلفن محل کار',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'مساحت خانه',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue:'تعداد اتاق خواب‌های خانه' ,
                    subDropDownValue: "",
                    option: [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12',
                        '13',
                        '14',
                        '15',
                        '16',
                        '17',
                        '18',
                        '19',
                        '20',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12',
                        '13',
                        '14',
                        '15',
                        '16',
                        '17',
                        '18',
                        '19',
                        '20',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue:'تعداد سرویس‌های بهداشتی در خانه' ,
                    subDropDownValue: "",
                    option: [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12',
                        '13',
                        '14',
                        '15',
                        '16',
                        '17',
                        '18',
                        '19',
                        '20',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                        '7',
                        '8',
                        '9',
                        '10',
                        '11',
                        '12',
                        '13',
                        '14',
                        '15',
                        '16',
                        '17',
                        '18',
                        '19',
                        '20',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue:'در صورتی که نیاز باشد در حین انجام نظافت با شما تماس بگیریم، ترجیح می‌دهید از چه راهی با شما ارتباط برقرار کنیم؟' ,
                    subDropDownValue: "",
                    option: [
                        'تلفن همراه',
                        'تلفن محل کار',
                        'تلفن منزل',
                        'ایمیل',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'تلفن همراه',
                        'تلفن محل کار',
                        'تلفن منزل',
                        'ایمیل',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'چه نوع خدماتی شامل حال شما می‌شود؟',
                    singleData: [
                        { id: 1, label: 'قرار ملاقات اولیه', checked: false },
                        { id: 2, label: 'قرار ملاقات یک‌باره', checked: false },
                        { id: 3, label: 'خدمات هفتگی', checked: false },
                        { id: 4, label: 'خدمات دو هفته یک‌بار', checked: false },
                        { id: 5, label: 'خدمات ماهانه', checked: false },
                        { id: 6, label: 'نظافت با فشار بالا', checked: false },
                        { id: 7, label: 'سایر', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'چگونه می‌توانیم وارد شویم؟',
                    singleData: [
                        { id: 1, label: 'کلیدها در دفتر', checked: false },
                        { id: 2, label: 'کلیدها نزد نگهبان ساختمان', checked: false },
                        { id: 3, label: 'شخصی دسترسی را فراهم خواهد کرد', checked: false },
                        { id: 4, label: 'سایر', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'دستورات ویژه',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'در صورتی که نیاز باشد مشاور نظافت شما به طور موقت یا دائمی تغییر کند، ترجیح می‌دهید چگونه به شما اطلاع داده شود؟',
                    singleData: [
                        { id: 1, label: 'لطفاً برای من ایمیل بفرستید', checked: false },
                        { id: 2, label: 'لطفاً با من تماس بگیرید', checked: false },
                        { id: 3, label: 'لطفاً ایمیل یا تماس نگیرید، من با هرگونه جایگزینی مشکلی ندارم', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },



        ],
        modalContent: <CleaningServiceAgreementForm />
    },

    {
        id: 65,
        title:'فرم قرار ملاقات پزشکی از راه دور',
        src: '/assets/images/TelemedicineAppointmentForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue:'فرم قرار ملاقات پزشکی از راه دور',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام بیمار',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'انواع درمان',
                    singleData: [
                        {
                            id: 1,
                            label: "درمان شخص محور (PCT)",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "درمان شناختی رفتاری (CBT)",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "فیزیوتراپی",
                            checked: false
                        },
                        {
                            id: 4,
                            label: "کاردرمانی",
                            checked: false
                        },
                        {
                            id: 5,
                            label: "درمان روانکاوی یا روان پویایی",
                            checked: false
                        },
                        {
                            id: 6,
                            label: "درمان وجودی",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: 'لطفا یک تاریخ قرار را انتخاب کنید',
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue:'تاریخچه پزشکی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'تشخیص/ آلرژی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'داروها در صورت مصرف',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue:'هنوز در حال استفاده هستید؟' ,
                    subDropDownValue: "",
                    option: [
                        'بله',
                        'خیر',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'بله',
                        'خیر',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'یادداشت های اضافی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا بیمه درمانی دارید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'یادداشت های اضافی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <TelemedicineAppointmentForm />
    },

    {
        id: 66,
        title:'فرم استعلام ملک',
        src: '/assets/images/PropertyInquiryForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'استعلام فهرست املاک',
                    subHeadingValue: 'لطفا برای کسب اطلاعات بیشتر در مورد ملک فرم زیر را پر کنید'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:"نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'هر شماره فهرست یا ویژگی های مورد علاقه را در اینجا وارد کنید:',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا قبلاً از ایالت بازدید کرده اید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'چه زمانی قصد بازدید دارید؟',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'چه مدت قصد اقامت دارید؟',
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: [
                        'بیشتر یا کمتر از ساعت',
                        'کمتر از یک هفته',
                        'یک هفته',
                        'یک ماه',
                        'من مطمئن نیستم چقدر طول می‌کشد.',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا می‌توانیم به شما در تنظیم سفر کمک کنیم؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'به چه نوع ملکی علاقه دارید؟',
                    multiData: [
                        { id: 1, label: 'زمین خام', checked: false },
                        { id: 2, label: 'قطعه در یک پروژه توسعه‌یافته', checked: false },
                        { id: 3, label: 'آپارتمان‌های مسکونی', checked: false },
                        { id: 4, label: 'خانه تک‌خانواده‌ای', checked: false },
                        { id: 4, label: 'سایر', checked: false },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'به چه نوع لوکیشنی علاقه دارید؟',
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: [
                        'منظره اقیانوس',
                        'ساحلی (کنار ساحل)',
                        'نزدیک به محل موج‌سواری',
                        'در شهر',
                        'مزرعه یا دامداری',
                        'سایر',
                        'مهم نیست',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'بودجه شما چقدر است؟',
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: [
                        '$0 - $50,000',
                        '$50,000 - $100,000',
                        '$100,00 - $250,000',
                        '$250,000 - $500,000',
                        '$500,000 - $1,000,000',
                        '$1,000,000 plus',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'سؤالات/نظرات:',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <CleaningServiceAgreementForm />
    },

    {
        id: 67,
        title:'فرم استعلام سفارش نانوایی',
        src: '/assets/images/BakeryOrderInquiry.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم استعلام سفارش نانوایی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:"نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تماس',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ مورد نیاز',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'تحویل/دریافت',
                    singleData: [
                        {
                            id: 1,
                            label: "تحویل",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "دریافت",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "Time",
                state:
                    {
                        inputValue: "",
                        mainInputValue: "",
                        readOnly: false,
                        selectedOption: "ق.ظ",
                        subTimeValue: "ساعت دقیقه",
                        timeValue: 'زمان'
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'مناسبت',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'تعداد سرویس‌ها',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'تعداد کاپ‌کیک‌ها',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'تعداد کیک‌ها',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'بسته‌بندی تکی',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'تعداد طبقات',
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: [
                        '1',
                        '2',
                        '3',
                        '4',
                        '5',
                        '6',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'اطلاعات اضافی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'افزودن تصویر',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'آیسینگ',
                    subDropDownValue: "",
                    option: "",
                    textareaValue: "",
                    mainTextareaValue: [
                        'خامه تازه',
                        'کره کرم',
                        'فوندانت',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

        ],
        modalContent: <BakeryOrderInquiryForm />
    },

    {
        id: 68,
        title:'فرم درخواست استخدام',
        src: '/assets/images/HiringInquiry.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم درخواست استخدام',
                    subHeadingValue: 'اگر مایل به کسب اطلاعات بیشتر در مورد موقعیت های موجود هستید، لطفا این فرم درخواست را تکمیل و ارسال کنید'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:"نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'لطفاً منطقه یا مناطق مورد علاقه خود را انتخاب کنید',
                    multiData: [
                        { id: 1, label: 'علاقه‌مندی 1', checked: false },
                        { id: 2, label: 'علاقه‌مندی 2', checked: false },
                        { id: 3, label: 'علاقه‌مندی 3', checked: false },
                        { id: 4, label: 'علاقه‌مندی 4', checked: false },
                        { id: 5, label: 'علاقه‌مندی 5', checked: false },
                        { id: 6, label: 'علاقه‌مندی 6', checked: false },
                        { id: 7, label: 'سایر', checked: false },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ شروع تخمینی',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'یادداشت های اضافی',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'بارگذاری رزومه',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },


        ],
        modalContent: <HiringInquiryForm />
    },

    {
        id: 69,
        title:'فرم درخواست طراحی گرافیک',
        src: '/assets/images/GraphicDesignInquiryForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم درخواست طراحی گرافیک',
                    subHeadingValue: 'هر آنچه را که باید بدانم تا به بهترین شکل به شما خدمت کنم به من بگویید!'
                }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'چه خدمتی را قصد دارید خریداری کنید؟',
                    multiData: [
                        { id: 1, label: 'بروشور الکترونیکی', checked: false },
                        { id: 2, label: 'دعوت نامه', checked: false },
                        { id: 3, label: 'لیست قیمت', checked: false },
                        { id: 4, label: 'کارت ویزیت', checked: false },
                        { id: 5, label: 'کارت تشکر', checked: false },
                        { id: 6, label: 'الگوی برند', checked: false },
                        { id: 7, label: 'الگوی داستان', checked: false },
                        { id: 8, label: 'نکات برجسته داستان', checked: false },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'چقدر زود به طراحی‌های خود نیاز دارید؟',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'آیا شما یک لوگو دارید؟',
                    subDropDownValue: "",
                    option: [
                        'بله',
                        'نه',
                        'من به یک دعوت نامه نیاز دارم - لوگو لازم نیست',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'بله',
                        'نه',
                        'من به یک دعوت نامه نیاز دارم - لوگو لازم نیست',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'لوگو را بارگذاری کنید',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'دوست دارید بروشور/کارت/قالب چه بگوید؟ لطفا هیچ اطلاعاتی را از قلم نیندازید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'آیا تم یا رنگی در ذهن دارید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'هر عکسی را که دوست دارید در طرح خود آپلود کنید',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:"نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

        ],
        modalContent: <GraphicDesignInquiryForm />
    },

    {
        id: 70,
        title:'فرم درخواست سفر',
        src: '/assets/images/TravelInquiryForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم درخواست سفر',
                    subHeadingValue: 'از اینکه ما را برای برنامه ریزی تعطیلات بعدی خود انتخاب کردید متشکریم. لطفاً این فرم را تکمیل کنید تا بتوانیم سفر عالی را انجام دهیم.'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:"نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تلفن',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'مقصد مورد علاقه',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'بودجه تعطیلات',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'تعداد مسافران',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'شهر مبدأ',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ سفر',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ پایان سفر',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },
            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا بیمه مسافرتی می خواهید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله، لطفا بیمه مسافرتی را اضافه کنید",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "نه، بیمه مسافرتی را رد می کنم",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

        ],
        modalContent: <TravelInquiryForm />
    },

    {
        id: 71,
        title:'فرم استعلام سفارش حمل و نقل',
        src: '/assets/images/TransportationOrderInquiryForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم استعلام سفارش حمل و نقل',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:"نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: ' تلفن',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'تعداد مهمانان:',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: 'رزرو',
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'رزرو میز:',
                    subDropDownValue: "",
                    option:  [
                        "بله",
                        "خیر",
                    ]
                    ,
                    textareaValue: "",
                    mainTextareaValue:  [
                        "بله",
                        "خیر",
                    ]
                    ,
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'نوع رزرو:',
                    subDropDownValue: "",
                    option: [
                        'شام',
                        'VIP / میزانسن',
                        'تولد / سالگرد',
                        'زندگی شبانه',
                        'خصوصی',
                        'عروسی',
                        'شرکتی',
                        'تعطیلات',
                        'سایر',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'شام',
                        'VIP / میزانسن',
                        'تولد / سالگرد',
                        'زندگی شبانه',
                        'خصوصی',
                        'عروسی',
                        'شرکتی',
                        'تعطیلات',
                        'سایر',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'اگر گزینه "سایر" را انتخاب کرده‌اید، لطفاً مشخص کنید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'درخواست خاصی دارید؟',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <TransportationOrderInquiryForm />
    },

    {
        id: 72,
        title:'فرم خرید آنلاین',
        src: '/assets/images/OnlineShoppingForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم خرید آنلاین',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات مشتری:',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:"نام و نام خانوادگی",
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: ' شماره تلفن',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: 'آدرس حمل و نقل',
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ProductList",
                state:
                    {
                        products: [],
                        productValue: 'انتخاب محصول',
                        totalPrice: 10000,
                        totalQuantity: 1,
                        mainProducts: [

                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'محصول 1',
                                description: 'مواد تشکیل دهنده',
                                image: null,
                                price: '150000',
                                quantity: 3,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'محصول 2',
                                description: 'مواد تشکیل دهنده',
                                image: null,
                                price: '130000',
                                quantity: 1,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'محصول 3',
                                description: 'مواد تشکیل دهنده',
                                image: null,
                                price: '150000',
                                quantity: 4,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'محصول 4',
                                description: 'مواد تشکیل دهنده',
                                image: null,
                                price: '80000',
                                quantity: 1,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'محصول 5',
                                description: 'مواد تشکیل دهنده',
                                image: null,
                                price: '90000',
                                quantity: 2,
                            },

                        ],
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'روش پرداخت',
                    singleData: [
                        {
                            id: 1,
                            label: "پرداخت در محل",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "پرداخت آنلاین",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ تحویل ترجیحی',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'دستورالعمل های ویژه',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ سفارش',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

        ],
        modalContent: <OnlineShoppingForm />
    },

    {
        id: 73,
        title:'فرم سفارش لباس تیمی',
        src: '/assets/images/SpiritWearOrderForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم سفارش لباس تیمی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: "ProductList",
                state:
                    {
                        products: [],
                        productValue: 'فروشگاه مدرسه',
                        totalPrice: 10000,
                        totalQuantity: 1,
                        mainProducts: [
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '10000',
                                quantity: 1,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '20000',
                                quantity: 2,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '30000',
                                quantity: 3,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '40000',
                                quantity: 4,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '50000',
                                quantity: 5,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '60000',
                                quantity: 6,
                            },
                        ],
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام دانش آموز',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'پردیس',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'بخش',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'کلاس',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام معلم',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام والدین/سرپرست',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تماس',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: 'آدرس حمل و نقل',
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'نظرات',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <SpiritWearOrderForm />
    },

    {
        id: 74,
        title:'فرم بارگذاری قرارداد',
        src: '/assets/images/ContractUploadForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم بارگذاری قرارداد',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'پردیس',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'نظرات',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'لطفاً قرارداد خود را بارگذاری کنید.',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },

        ],
        modalContent: <ContractUploadForm />
    },

    {
        id: 75,
        title:'درخواست داوطلبان پزشکی',
        src: '/assets/images/MedicalVolunteerApplication.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'درخواست داوطلبان پزشکی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: "شماره تلفن",
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: "تاریخ تولد",
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'محل تولد',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'زبان هایی که علاوه بر زبان انگلیسی روان صحبت می شوند',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا شما یک متخصص پزشکی مجاز هستید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'مسئول تماس اضطراری',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تماس اضطراری',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'نوع ارائه دهنده',
                    subDropDownValue: "",
                    option: [

                        'پزشک',
                        'دستیار پزشک',
                        'پرستار متخصص',
                        'پرستار ثبت‌شده',
                        'پرستار عملی دارای مجوز',
                        'متخصص بیهوشی پرستاری دارای گواهی‌نامه',
                        'تکنسین فوریت‌های پزشکی',
                        'متخصص تنفس',
                        'دستیار پرستار دارای گواهی‌نامه',
                        'مددکار اجتماعی',
                        'داروساز',
                        'متخصص فیزیوتراپی',
                        'ارائه‌دهنده خدمات سلامت روانی و رفتاری',
                        'دندانپزشک',
                        'متخصص کاردرمانی',

                    ],
                    textareaValue: "",
                    mainTextareaValue: [

                        'پزشک',
                        'دستیار پزشک',
                        'پرستار متخصص',
                        'پرستار ثبت‌شده',
                        'پرستار عملی دارای مجوز',
                        'متخصص بیهوشی پرستاری دارای گواهی‌نامه',
                        'تکنسین فوریت‌های پزشکی',
                        'متخصص تنفس',
                        'دستیار پرستار دارای گواهی‌نامه',
                        'مددکار اجتماعی',
                        'داروساز',
                        'متخصص فیزیوتراپی',
                        'ارائه‌دهنده خدمات سلامت روانی و رفتاری',
                        'دندانپزشک',
                        'متخصص کاردرمانی',

                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'ناحیه تخصصی یا مراقبتی',
                    subDropDownValue: "",
                    option: [

                        'واحد مراقبت‌های ویژه (ICU)',
                        'بخش اورژانس (ED)',
                        'بیماری‌های عفونی',
                        'بخش جراحی پزشکی (Med-Surg)',
                        'اتاق عمل یا واحد قبل و بعد از عمل (OR or Perioperative unit)',
                        'بخش سرپایی (Ambulatory)',
                        'محیط‌های غیرحاد (خارج از بیمارستان)',
                        'اطفال (Pediatrics)',

                    ],
                    textareaValue: "",
                    mainTextareaValue: [

                        'واحد مراقبت‌های ویژه (ICU)',
                        'بخش اورژانس (ED)',
                        'بیماری‌های عفونی',
                        'بخش جراحی پزشکی (Med-Surg)',
                        'اتاق عمل یا واحد قبل و بعد از عمل (OR or Perioperative unit)',
                        'بخش سرپایی (Ambulatory)',
                        'محیط‌های غیرحاد (خارج از بیمارستان)',
                        'اطفال (Pediatrics)',

                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'چه نوع تجربه مراقبت از بیمار دارید؟',
                    multiData: [
                        { id: 1, label: 'دستگاه‌های تنفس مصنوعی', checked: false },
                        { id: 2, label: 'مراقبت تسکینی', checked: false },
                        { id: 3, label: 'قطره‌های وازوپرسور', checked: false },
                        { id: 4, label: 'لوله‌گذاری تراشه (اینتوباسیون)', checked: false },
                        { id: 5, label: 'دستگاه‌های دیالیز (CRRT یا CVVH)', checked: false },
                        { id: 6, label: 'خطوط مرکزی (سنترال لاین)', checked: false },
                        { id: 7, label: 'جاگذاری سرم وریدی (IVs)', checked: false },
                        { id: 8, label: 'مراقبت‌های پزشکی بستری عمومی', checked: false },
                        { id: 9, label: 'مراقبت‌های پزشکی سرپایی عمومی', checked: false },
                        { id: 10, label: 'زایمان و بخش زایمان', checked: false },
                        { id: 11, label: 'آماده‌سازی دارو / آویزان کردن سرم‌ها', checked: false },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'لطفا بالاترین سطح مراقبت از بیمار را نشان دهید',
                    subDropDownValue: "",
                    option: [
                        'من می توانم تخصیص بیمار و مراقبت از مدارک را در EHR انجام دهم',
                        'من می توانم به ارائه دهندگان دیگر کمک کنم',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'من می توانم تخصیص بیمار و مراقبت از مدارک را در EHR انجام دهم',
                        'من می توانم به ارائه دهندگان دیگر کمک کنم',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'وضعیت کار',
                    subDropDownValue: "",
                    option: [
                        '**در حال فعالیت حرفه‌ای',
                        '**فعالیت حرفه‌ای ندارم، اما بازنشسته نیستم',
                        'بازنشسته',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        '**در حال فعالیت حرفه‌ای',
                        '**فعالیت حرفه‌ای ندارم، اما بازنشسته نیستم',
                        'بازنشسته',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'لطفاً بیشترین تعداد ساعاتی را که می توانید در یک روز کار کنید را مشخص کنید',
                    subDropDownValue: "",
                    option: [
                        '4',
                        '6',
                        '8',
                        '12',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        '4',
                        '6',
                        '8',
                        '12',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا می توانید بصورت تمام وقت داوطلب شوید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "نه فقط پاره وقت",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'لطفاً اگر شرایطی از قبل وجود داشته است، به ویژه مواردی که خطر ابتلا به کووید-19 را افزایش داده است، مشخص کنید',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "نه ",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "سایر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },




        ],
        modalContent: <MedicalVolunteerApplication />
    },

    {
        id: 76,
        title:'فرم تاریخچه پزشکی',
        src: '/assets/images/MedicalHistoryForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم تاریخچه پزشکی',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: "شماره تلفن",
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: "آدرس",
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: "تاریخ تولد",
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'محل تولد',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'زبان هایی که علاوه بر زبان انگلیسی روان صحبت می شوند',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا شما یک متخصص پزشکی مجاز هستید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'مسئول تماس اضطراری',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'سن شما چند سال است؟',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'نوع ارائه دهنده',
                    subDropDownValue: "",
                    option: [
                        'آقا',
                        'خانم',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'آقا',
                        'خانم',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تماس اضطراری',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'آدرس ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'شرایطی را که در مورد شما یا هر یک از اعضای نزدیک خانواده‌تان صدق می‌کند، علامت بزنید:',
                    multiData: [
                        { id: 1, label: 'آسم', checked: false },
                        { id: 2, label: 'سرطان', checked: false },
                        { id: 3, label: 'بیماری قلبی', checked: false },
                        { id: 4, label: 'دیابت', checked: false },
                        { id: 5, label: 'فشار خون بالا', checked: false },
                        { id: 6, label: 'اختلال روانی', checked: false },
                        { id: 7, label: 'صرع', checked: false },
                        { id: 8, label: 'سایر', checked: false },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "MultiChoice",
                state: {
                    titleValue: 'علائمی را که در حال حاضر تجربه می‌کنید، علامت بزنید:',
                    multiData: [
                        { id: 1, label: 'درد قفسه سینه', checked: false },
                        { id: 2, label: 'تنفسی', checked: false },
                        { id: 3, label: 'بیماری قلبی', checked: false },
                        { id: 4, label: 'قلبی عروقی', checked: false },
                        { id: 5, label: 'خون‌شناسی', checked: false },
                        { id: 6, label: 'لنفاوی', checked: false },
                        { id: 7, label: 'عصبی', checked: false },
                        { id: 8, label: 'گوارشی', checked: false },
                        { id: 9, label: 'ادراری تناسلی', checked: false },
                        { id: 10, label: 'روانی', checked: false },
                        { id: 11, label: 'اسکلتی عضلانی', checked: false },
                        { id: 12, label: 'افزایش وزن', checked: false },
                        { id: 13, label: 'کاهش وزن', checked: false },
                        { id: 14, label: 'سایر', checked: false },
                    ],
                    selectedOptions: [],
                    mainSelectedTitles: [],
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا در حال حاضر داروی خاصی مصرف می‌کنید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'لطفا آنها را لیست کنید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا حساسیت دارویی دارید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "مطمئن نیستم",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'لطفا آنها را لیست کنید.',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'آیا از هر نوع تنباکو استفاده می‌کنید یا قبلاً استفاده کرده‌اید؟',
                    subDropDownValue: "",
                    option: [
                        'بله',
                        'خیر',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'بله',
                        'خیر',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'چه نوع محصولات تنباکویی؟ چه مدت است که استفاده کرده‌اید؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'DropDown',
                state: {
                    dropDownValue: 'آیا از هر نوع مواد مخدر غیرقانونی استفاده می‌کنید یا قبلاً استفاده کرده‌اید؟',
                    subDropDownValue: "",
                    option: [
                        'بله',
                        'خیر',
                    ],
                    textareaValue: "",
                    mainTextareaValue: [
                        'بله',
                        'خیر',
                    ],
                    mainOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'هر چند وقت یک بار الکل مصرف می‌کنید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "روزانه",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "هفتگی",
                            checked: false
                        },
                        {
                            id: 3,
                            label: "ماهانه",
                            checked: false
                        },
                        {
                            id: 4,
                            label: "گاهی اوقات",
                            checked: false
                        },
                        {
                            id: 5,
                            label: "هرگز",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },



        ],
        modalContent: <MedicalHistoryForm />
    },

    {
        id: 77,
        title:'فرم سفارش تحویل',
        src: '/assets/images/DeliveryOrderForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم سفارش تحویل',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'اطلاعات مشتری:',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: "شماره تلفن",
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: 'آدرس تحویل',
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'جزئیات سفارش:',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'شماره سفارش',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ سفارش',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ تحویل',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'زمان تحویل ترجیحی',
                    singleData: [
                        { id: 1, label: 'صبح (8 صبح تا 12 بعد از ظهر)', checked: false },
                        { id: 2, label: 'بعد از ظهر (از ساعت 12 تا 16)', checked: false },
                        { id: 3, label: 'عصر (4 بعد از ظهر تا 8 بعد از ظهر)', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "ProductList",
                state:
                    {
                        products: [],
                        productValue: 'اقلام قابل تحویل',
                        totalPrice: 10000,
                        totalQuantity: 4,
                        mainProducts: [
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '10000',
                                quantity: 1,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '10000',
                                quantity: 1,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '10000',
                                quantity: 1,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '10000',
                                quantity: 1,
                            },
                        ],
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'دستورالعمل های ویژه',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'روش پرداخت',
                    singleData: [
                        { id: 1, label: 'پرداخت آنلاین', checked: false },
                        { id: 2, label: 'پرداخت درب منزل', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

        ],
        modalContent: <DeliveryOrderForm />
    },

    {
        id: 78,
        title:'فرم درخواست رزرو',
        src: '/assets/images/BookingEnquiryForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم درخواست رزرو',
                    subHeadingValue: 'پس از دریافت فرم ثبت شده، به زودی برای تایید در دسترس بودن اتاق با شما تماس خواهیم گرفت.'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: "شماره تلفن",
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: 'آدرس تحویل',
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'تعداد بزرگسالان',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'تعداد کودکان 6-15 سال',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: "Number",
                state:
                    {
                        mainNumberInputValue: null,
                        numberInputValue: null,
                        numberValue: 'تعداد کودکان 0-5 ساله',
                        readOnly: false,
                        subNumberValue: ""
                    }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ ورود',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'تاریخ حرکت',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'نظرات؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

        ],
        modalContent: <BookingEnquiryForm />
    },

    {
        id: 79,
        title:'فرم درخواست کار',
        src: '/assets/images/OnlineJobApplicationForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'فرم درخواست کار',
                    subHeadingValue: 'لطفا فرم زیر را برای ارسال درخواست شغلی خود پر کنید!'
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: "ایمیل",
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: "شماره تلفن",
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: "ShortText",
                state: {
                    shortTextValue: 'موقعیت درخواستی',
                    shortTextInputValue: "",
                    subShortTextValue: "",
                    mainShortTextInputValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "DatePicker",
                state:
                    {
                        date: null,
                        dateValue: 'زودترین تاریخ ممکن برای شروع',
                        subHeader: "",
                        mainDate: "Invalid date",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Appointment',
                state: {
                    appointmentValue: 'تاریخ ترجیحی برای مصاحبه',
                    interval: 15,
                    mainPersianDateTime: "سه‌شنبه، ۱۴۰۳/۷/۱۷",
                    mainSavedSelectTime: null,
                    persianDateTime: "",
                    readOnly: false,
                    savedSelectTime: ""
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'نامه همراه',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'آپلود رزومه',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: "FileUpload",
                state: {
                    selectedFile: null,
                    fileInputValue: "",
                    selectedFileName: null,
                    fileValue: 'هر سند دیگری برای آپلود',
                    subFileValue: "",
                    mainFileInputValue: "",
                    mainSelectedFileName: null,
                    readOnly: false
                }
            },

        ],
        modalContent: <OnlineJobApplicationForm />
    },

    {
        id: 80,
        title:'فرم سفارش محصول عمومی',
        src: '/assets/images/GenericProductOrderForm.png',
        state: [

            {
                id: uuidv4(),
                title: 'Heading',
                state: {
                    headingValue: 'محصولات من',
                    subHeadingValue: null
                }
            },

            {
                id: uuidv4(),
                title: "ProductList",
                state:
                    {
                        products: [],
                        productValue: "لیست محصولات",
                        totalPrice: 10000,
                        totalQuantity: 1,
                        mainProducts: [
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '10000',
                                quantity: 1,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '10000',
                                quantity: 1,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '10000',
                                quantity: 1,
                            },
                            {
                                id: Math.floor(Math.random() * 1000) + 100,
                                name: 'نام محصولات',
                                description: 'توضیحات را وارد کنید',
                                image: null,
                                price: '10000',
                                quantity: 1,
                            },
                        ],
                        readOnly: false
                    }
            },


            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام و نام خانوادگی',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Email',
                state: {
                    emailValue: 'آدرس ایمیل',
                    emailInput: "",
                    exEmailValue: "example@example.com",
                    mainEmailInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Phone',
                state:
                    {
                        phoneValue: 'شماره تماس',
                        phoneInput: null,
                        alertValue: "لطفا یک شماره تلفن معتبر وارد کنید.",
                        mainPhoneInput: null,
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: 'آدرس صورتحساب',
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا آدرس صورتحساب با آدرس حمل و نقل یکی است؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'Address',
                state: {
                    addressValue: 'آدرس حمل و نقل',
                    streetInput: "",
                    streetValue: "آدرس خیابان",
                    streetInputSecond: "",
                    streetValueSecond: "آدرس خیابان خط دوم",
                    cityInput: "",
                    city: "شهر",
                    provinceInput: "",
                    province: "ایالت / استان",
                    postalInput: "",
                    postal: "پست / کد پستی",
                    mainStreetInput: "",
                    mainStreetInputSecond: "",
                    mainCityInput: "",
                    mainProvinceInput: "",
                    mainPostalInput: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا می خواهید سفارش خود را به عنوان هدیه ارسال کنید؟',
                    singleData: [
                        {
                            id: 1,
                            label: "بله",
                            checked: false
                        },
                        {
                            id: 2,
                            label: "خیر",
                            checked: false
                        },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'FullName',
                state:
                    {
                        nameValue:'نام گیرنده',
                        firstNameValue: "نام",
                        lastNameValue: "نام خانوادگی",
                        firstNameInput: "",
                        lastNameInput: "",
                        mainFirstNameInput: "",
                        mainLastNameInput: "",
                        readOnly: false
                    }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'آیا یادداشتی برای گیرنده وجود دارد؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'LongText',
                state: {
                    longTextValue: 'درخواست ها، پیام ها یا نگرانی های اضافی؟',
                    textareaValue: "",
                    subLongTextValue: "",
                    mainTextareaValue: "",
                    readOnly: false
                }
            },

            {
                id: uuidv4(),
                title: 'SingleChoice',
                state: {
                    titleValue: 'آیا می خواهید سفارش خود را به عنوان هدیه ارسال کنید؟',
                    singleData: [
                        { id: 1, label: 'پرداخت آنلاین', checked: false },
                        { id: 2, label: 'پرداخت درب منزل', checked: false },
                    ],
                    mainSelectedOption: null,
                    readOnly: false
                }
            },




        ],
        modalContent: <GenericProductOrderForm />
    },





]


export default cardTemplateData;