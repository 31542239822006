import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Phone from "./Phone";
import DropDown from "./DropDown";
import LongText from "./LongText";
import React from "react";
import SingleChoice from "./SingleChoice";
import DatePickers from "./DatePicker";
import Time from "./Time";

const ClientCallLog = ()=>{

    const clientData = [
        'نام مشتری 1',
        'نام مشتری 2',
        'نام مشتری 3',
        'نام مشتری 4',
        'سایر'
    ]


    const typeCallData = [
        {
            id: 1,
            label: "ورودی",
            checked: false
        },
        {
            id: 2,
            label: "خروجی",
            checked: false
        },
        {
            id: 3,
            label: "کنفرانس",
            checked: false
        },
    ]

    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'ثبت جزئیات تماس'} subHeader={'لطفاً فرم زیر را پر کنید و به وضوح نوع تماس و بهترین زمان برای تماس را مشخص کنید.'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <SingleChoice header={'نوع تماس'} options={typeCallData}/>
                <FullName header={'نام شخص'}/>
                <Phone header={'شماره تلفن'}/>
                <DropDown header={'مشتری'} options={clientData}/>
                <LongText header={'یادداشت ها'}/>
                <DatePickers header={'تاریخ'}/>
                <Time header={'زمان'}/>
            </Grid>
        </Grid>
    )
}

export default ClientCallLog;