import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import Phone from "./Phone";
import DropDown from "./DropDown";
import SingleChoice from "./SingleChoice";
import React from "react";
import DatePickers from "./DatePicker";

const EducationClassRegistrationForm = ()=>{

    const timeToCall = [
        {
            id: 1,
            label: "صبح ها",
            checked: false
        },
        {
            id: 2,
            label: "عصرها",
            checked: false
        },
        {
            id: 2,
            label: "آخر هفته ها",
            checked: false
        },
    ]


    const GED = [
        '101',
        '102',
        '103',
        '201',
        '202',
        '301',
        '302',
    ]


    const jobSearchTraining = [
        'مبتدی',
        'متوسط',
        'پیشرفته',
    ]


    const PCBasics = [
        'استفاده از اینترنت',
        'واژه پردازی پایه',
        'ایمیل',
    ]


    const microsoftOffice = [
        '101',
        '102',
        '103',
    ]


    const virtualField = [
        'گزینه 1',
        'گزینه 2',
        'گزینه 3',
    ]


    const individualAssessment = [
        'گزینه 1',
        'گزینه 2',
        'گزینه 3',
    ]



    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'پیش ثبت نام کلاس'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <FullName header={'نام والدین/سرپرست (اگر کمتر از 18 سال باشد)'}/>
                <DatePickers header={'تاریخ تولد'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن خانه'}/>
                <Phone header={'شماره تلفن همراه'}/>
                <SingleChoice header={'بهترین زمان برای تماس چه زمانی است؟'} options={timeToCall}/>
                <DatePickers header={'چقدر زود علاقه مند به شروع دوره آموزشی خود هستید؟'}/>
                <DropDown header={'G.E.D. کلاس'} options={GED}/>
                <DropDown header={'آموزش کاریابی'} options={jobSearchTraining}/>
                <DropDown header={'مبانی کامپیوتر'} options={PCBasics}/>
                <DropDown header={'معرفی مایکروسافت آفیس'} options={microsoftOffice}/>
                <DropDown header={'تدریس خصوصی سفرهای مجازی'} options={virtualField}/>
                <DropDown header={'طرح های ارزیابی فردی و پیش آزمون'} options={individualAssessment}/>
            </Grid>
        </Grid>
    )
}


export default EducationClassRegistrationForm;