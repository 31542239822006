import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import Appointment from './Appointment'
import FullName from './FullName'
import DropDown from './DropDown'
import Phone from './Phone'
import DatePickers from './DatePicker'
import Address from './Address'
import Email from './Email'
import SingleChoice from './SingleChoice'

const MedicalAppointmentForm = () => {


    const genderOptions = [
        'آقا',
        'خانم',
    ]


    const medicalSingleData = [
        { id: 1, label: 'بله', checked: false },
        { id: 2, label: 'نه', checked: false },
    ]



    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم قرار ملاقات پزشکی'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <Appointment header={'تاریخ قرار ملاقات'} />
                <FullName header={'نام'} />
                <DropDown header={'جنسیت'} options={genderOptions} />
                <Phone header={'شماره تلفن'} />
                <DatePickers header={'تاریخ تولد'}/>
                <Address />
                <Email header={'ایمیل'} />
                <SingleChoice header={'آیا تا به حال به ما سر زده اید؟'} options={medicalSingleData}/>
            </Grid>
        </Grid>
    )
}

export default MedicalAppointmentForm