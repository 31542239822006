import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import LongText from "./LongText";
import React from "react";
import ShortText from "./ShortText";
import FileUpload from "./FileUpload";

const ITServiceTicketForm = ()=>{
    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'درخواست خدمات فناوری اطلاعات'} subHeader={'لطفا جزئیات مشکل را ارائه دهید'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={' ایمیل'}/>
                <ShortText header={'بخش'}/>
                <ShortText header={'شناسه کامپیوتر'}/>
                <FileUpload header={'آپلود اسکرین شات'}/>
                <LongText header={'مشکل را شرح دهید'}/>
            </Grid>
        </Grid>
    )
}


export default ITServiceTicketForm