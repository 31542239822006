import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Phone from "./Phone";
import Email from "./Email";
import MultiChoice from "./MultiChoice";
import LongText from "./LongText";
import React from "react";
import Number from "./Number";
import DropDown from "./DropDown";
import SingleChoice from "./SingleChoice";

const MedicalHistoryForm = ()=>{


    const genderData = [
        'آقا',
        'خانم',
    ]


    const conditionsData = [
        { id: 1, label: 'آسم', checked: false },
        { id: 2, label: 'سرطان', checked: false },
        { id: 3, label: 'بیماری قلبی', checked: false },
        { id: 4, label: 'دیابت', checked: false },
        { id: 5, label: 'فشار خون بالا', checked: false },
        { id: 6, label: 'اختلال روانی', checked: false },
        { id: 7, label: 'صرع', checked: false },
        { id: 8, label: 'سایر', checked: false },
    ]


    const symptomsData = [
        { id: 1, label: 'درد قفسه سینه', checked: false },
        { id: 2, label: 'تنفسی', checked: false },
        { id: 3, label: 'بیماری قلبی', checked: false },
        { id: 4, label: 'قلبی عروقی', checked: false },
        { id: 5, label: 'خون‌شناسی', checked: false },
        { id: 6, label: 'لنفاوی', checked: false },
        { id: 7, label: 'عصبی', checked: false },
        { id: 8, label: 'گوارشی', checked: false },
        { id: 9, label: 'ادراری تناسلی', checked: false },
        { id: 10, label: 'روانی', checked: false },
        { id: 11, label: 'اسکلتی عضلانی', checked: false },
        { id: 12, label: 'افزایش وزن', checked: false },
        { id: 13, label: 'کاهش وزن', checked: false },
        { id: 14, label: 'سایر', checked: false },
    ]


    const dropDownYesOrNoData = [
        'بله',
        'خیر',
    ]


    const yesOrNoData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]


    const medicationAllergiesData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
        {
            id: 3,
            label: "مطمئن نیستم",
            checked: false
        },
    ]



    const consumeAlcoholData = [
        {
            id: 1,
            label: "روزانه",
            checked: false
        },
        {
            id: 2,
            label: "هفتگی",
            checked: false
        },
        {
            id: 3,
            label: "ماهانه",
            checked: false
        },
        {
            id: 4,
            label: "گاهی اوقات",
            checked: false
        },
        {
            id: 5,
            label: "هرگز",
            checked: false
        },
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم تاریخچه پزشکی'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>

                <FullName header={'نام و نام خانوادگی'}/>
                <Number header={'سن شما چند سال است؟'}/>
                <DropDown header={'جنسیت شما چیست؟'} options={genderData}/>
                <Phone header={'شماره تماس'}/>
                <Email header={'آدرس ایمیل'}/>
                <MultiChoice header={'شرایطی را که در مورد شما یا هر یک از اعضای نزدیک خانواده‌تان صدق می‌کند، علامت بزنید:'} options={conditionsData}/>
                <MultiChoice header={'علائمی را که در حال حاضر تجربه می‌کنید، علامت بزنید:'} options={symptomsData}/>
                <SingleChoice header={'آیا در حال حاضر داروی خاصی مصرف می‌کنید؟'} options={yesOrNoData}/>
                <LongText header={'لطفا آنها را لیست کنید.'}/>
                <SingleChoice header={'آیا حساسیت دارویی دارید؟'} options={medicationAllergiesData}/>
                <LongText header={'لطفا آنها را لیست کنید.'}/>
                <DropDown header={'آیا از هر نوع تنباکو استفاده می‌کنید یا قبلاً استفاده کرده‌اید؟'} options={dropDownYesOrNoData}/>
                <LongText header={'چه نوع محصولات تنباکویی؟ چه مدت است که استفاده کرده‌اید؟'}/>
                <DropDown header={'آیا از هر نوع مواد مخدر غیرقانونی استفاده می‌کنید یا قبلاً استفاده کرده‌اید؟'} options={dropDownYesOrNoData}/>
                <SingleChoice header={'هر چند وقت یک بار الکل مصرف می‌کنید؟'} options={consumeAlcoholData}/>

            </Grid>
        </Grid>
    )
}


export default MedicalHistoryForm;