import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid, useTheme, Typography } from "@mui/material";
import '../App.css';
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useInputValues } from '../Context/InputValuesContext';
import { useErrorContext } from '../Context/ErrorContext';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';


const Address = ({ id, header }) => {


    const location = useLocation();
    const currentPage = location.pathname;


    const { drawerData } = useFormContext();
    const existingComponentIndex = drawerData.findIndex(component => component.id === id);
    const { inputValues } = useInputValues();
    const { setMistake } = useErrorContext();
    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);




    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });

    let switchHeader = header ? header : drawerData[existingComponentIndex]?.state.addressValue ? drawerData[existingComponentIndex].state.addressValue : "آدرس"

    let switchSubStreet = drawerData[existingComponentIndex]?.state.streetValue ? drawerData[existingComponentIndex].state.streetValue : "آدرس خیابان"

    let switchSubSecondStreet = drawerData[existingComponentIndex]?.state.streetValueSecond ? drawerData[existingComponentIndex].state.streetValueSecond : "آدرس خیابان خط دوم"

    let switchSubCity = drawerData[existingComponentIndex]?.state.city ? drawerData[existingComponentIndex].state.city : "شهر"

    let switchSubProvince = drawerData[existingComponentIndex]?.state.province ? drawerData[existingComponentIndex].state.province : "ایالت / استان"

    let switchSubCode = drawerData[existingComponentIndex]?.state.postal ? drawerData[existingComponentIndex].state.postal : "پست / کد پستی"

    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly
    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"


    const [condition, setCondition] = useState(false)
    const [addressValue, setAddressValue] = useState(switchHeader);
    const [streetInput, setStreetInput] = useState(drawerData[existingComponentIndex].state.mainStreetInput);
    const [streetValue, setStreetValue] = useState(switchSubStreet);
    const [streetInputSecond, setStreetInputSecond] = useState(drawerData[existingComponentIndex].state.mainStreetInputSecond);
    const [streetValueSecond, setStreetValueSecond] = useState(switchSubSecondStreet);
    const [cityInput, setCityInput] = useState(drawerData[existingComponentIndex].state.mainCityInput);
    const [city, setCity] = useState(switchSubCity);
    const [provinceInput, setProvinceInput] = useState(drawerData[existingComponentIndex].state.mainProvinceInput);
    const [province, setProvince] = useState(switchSubProvince);
    const [postalInput, setPostalInput] = useState(drawerData[existingComponentIndex].state.mainPostalInput );
    const [postal, setPostal] = useState(switchSubCode);
    const [border, setBorder] = useState(false)
    const [error, setError] = useState('');
    const [errorStreet, setErrorStreet] = useState('');
    const [errorCity, setErrorCity] = useState('');
    const [errorProvince, setErrorProvince] = useState('');
    const [errorPostal, setErrorPostal] = useState('');

    const gridRef = useRef(null);






    const fillInputValue = () => {

        if (drawerData.length > 0) {

            if (drawerData[existingComponentIndex]) {
                drawerData[existingComponentIndex].state.addressValue = addressValue
                drawerData[existingComponentIndex].state.mainStreetInput = streetInput
                drawerData[existingComponentIndex].state.streetValue = streetValue
                drawerData[existingComponentIndex].state.mainStreetInputSecond = streetInputSecond
                drawerData[existingComponentIndex].state.streetValueSecond = streetValueSecond
                drawerData[existingComponentIndex].state.mainCityInput = cityInput
                drawerData[existingComponentIndex].state.city = city
                drawerData[existingComponentIndex].state.mainProvinceInput = provinceInput
                drawerData[existingComponentIndex].state.province = province
                drawerData[existingComponentIndex].state.mainPostalInput = postalInput
                drawerData[existingComponentIndex].state.postal = postal
                drawerData[existingComponentIndex].state.readOnly = readOnly
            }

        }

    }



    let theme = useTheme()
    let HeadingColor = theme.palette.blue[700]



    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === "Address") {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }



    const handleFindErorr = () => {
        let errorIndex = inputValues.findIndex(item => item.name === "ThisFieldCanOnlyContainLetters")
        if (errorIndex !== -1) {
            setError(inputValues[errorIndex].value)
            setMistake(true)
        } else {
            setError('')
            setMistake(false)
        }
    }

    const handleFindErorrStreet = () => {
        let errorIndex = inputValues.findIndex(item => item.name === "ThisFieldCanOnlyContainLetters")
        if (errorIndex !== -1) {
            setErrorStreet(inputValues[errorIndex].value)
            setMistake(true)
        } else {
            setErrorStreet('')
            setMistake(false)
        }
    }


    const handleFindErorrCity = () => {
        let errorIndex = inputValues.findIndex(item => item.name === "ThisFieldCanOnlyContainLetters")
        if (errorIndex !== -1) {
            setErrorCity(inputValues[errorIndex].value)
            setMistake(true)
        } else {
            setErrorCity('')
            setMistake(false)
        }
    }


    const handleFindErorrProvince = () => {
        let errorIndex = inputValues.findIndex(item => item.name === "ThisFieldCanOnlyContainLetters")
        if (errorIndex !== -1) {
            setErrorProvince(inputValues[errorIndex].value)
            setMistake(true)
        } else {
            setErrorProvince('')
            setMistake(false)
        }
    }


    const handleFindErorrPostal = () => {
        let errorIndex = inputValues.findIndex(item => item.name === "ThisFieldCanOnlyContainNumericValues")
        if (errorIndex !== -1) {
            setErrorPostal(inputValues[errorIndex].value)
            setMistake(true)
        } else {
            setErrorPostal('')
            setMistake(false)
        }
    }



    const handleAddressChange = (event) => {
        setAddressValue(event.target.value);
    };

    const handleStreetInputChange = (event) => {
        const value = event.target.value;
        setStreetInput(value);
        if (!/^[a-zA-Zآ-ی]+$/.test(value)) {
            handleFindErorr()
        } else {
            setError('');
        }
    };

    const handleStreetValueChange = (event) => {
        setStreetValue(event.target.value);
    };


    const handleStreetInputSecondChange = (event) => {
        const value = event.target.value;
        setStreetInputSecond(value);
        if (!/^[a-zA-Zآ-ی]+$/.test(value)) {
            handleFindErorrStreet()
        } else {
            setErrorStreet('');
        }
    };

    const handleStreetValueSecondChange = (event) => {
        setStreetValueSecond(event.target.value);
    };

    const handleCityInputChange = (event) => {
        const value = event.target.value;
        setCityInput(value);
        if (!/^[a-zA-Zآ-ی]+$/.test(value)) {
            handleFindErorrCity()
        } else {
            setErrorCity('');
        }
    };


    const handleCityChange = (event) => {
        setCity(event.target.value);
    };

    const handleProvinceInputChange = (event) => {
        const value = event.target.value;
        setProvinceInput(value)
        if (!/^[a-zA-Zآ-ی]+$/.test(value)) {
            handleFindErorrProvince()
        } else {
            setErrorProvince('');
        }
    }

    const handleProvinceChange = (event) => {
        setProvince(event.target.value)
    }


    const handlePostalInputChange = (event) => {
        const value = event.target.value;
        const containsNonNumber = isNaN(value);
        setPostalInput(value)
        if (containsNonNumber) {
            handleFindErorrPostal()
        } else {
            setErrorPostal('');
        }
    }

    const handlePostalChange = (event) => {
        setPostal(event.target.value)
    }


    const handleHeadingFocus = (event) => {
        event.target.select();
    };

    const handleOutsideClick = (event) => {
        if (gridRef.current && !gridRef.current.contains(event.target)) {
            setBorder(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id, addressValue, streetInput, streetValue, streetInputSecond, streetValueSecond, city, cityInput, province, provinceInput, postal, postalInput]);



    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])



    return (
        <Grid className={'Address'}
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px') : '0px'}
            borderRadius={'3px'}
            width={'100%'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'} ref={gridRef} onClick={() => {
                setBorder(true)
            }} border={border && drawerData.length > 0 && !isReadOnly ? '2px solid #0293e2' : '2px solid transparent'}>
            <Grid pb={'24px'}>
                <input
                    className=""
                    placeholder={'یک عنوان وارد کنید'}
                    style={{
                        border: 'none',
                        outline: 'none',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        fontSize: '16px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor,
                        '::placeholder': {
                            color: '#b8bdc9', // Placeholder text color
                        },
                    }}
                    readOnly={isReadOnly || condition}
                    value={addressValue}
                    onChange={handleAddressChange}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                />
            </Grid>
            <Grid pb={'24px'}>
                <input style={{
                    border: '1px solid #b8bdc9',
                    borderRadius: '3px',
                    padding: '10px',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    marginBottom: '8px'
                }}
                    readOnly={condition}
                    value={streetInput}
                    onChange={handleStreetInputChange}
                />
                {<Typography display={error !== '' && streetInput !== '' ? 'block' : 'none'} variant='subtitle1' color={'#FF0000'} fontWeight={700}>{error}</Typography>}

                <input className="" placeholder={'یک SubLabel وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: '#b8bdc9',
                }}
                    readOnly={isReadOnly || condition}
                    value={streetValue}
                    onChange={handleStreetValueChange}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                />
            </Grid>
            <Grid pb={'24px'}>
                <input style={{
                    border: '1px solid #b8bdc9',
                    borderRadius: '3px',
                    padding: '10px',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    marginBottom: '8px'
                }}
                    readOnly={condition}
                    value={streetInputSecond}
                    onChange={handleStreetInputSecondChange}
                />
                {<Typography variant='subtitle1' display={errorStreet !== '' && streetInputSecond !== '' ? 'block' : 'none'} color={'#FF0000'} fontWeight={700}>{errorStreet}</Typography>}
                <input className="" placeholder={'یک SubLabel وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: '#b8bdc9',
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={isReadOnly || condition}
                    value={streetValueSecond}
                    onChange={handleStreetValueSecondChange}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                />
            </Grid>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={{ xs: '8px', xxs: '24px' }} pb={'24px'}>
                <Grid width={'50%'}>
                    <input style={{
                        border: '1px solid #b8bdc9',
                        borderRadius: '3px',
                        padding: '10px',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        fontSize: '12px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor,
                        marginBottom: '8px'
                    }}
                        readOnly={condition}
                        value={cityInput}
                        onChange={handleCityInputChange}
                    />
                    {<Typography variant='subtitle1' color={errorCity !== '' && cityInput !== '' ? '#FF0000' : 'transparent'} fontWeight={700}>{errorCity}</Typography>}

                    <input className="" placeholder={'یک SubLabel وارد کنید'} style={{
                        border: 'none',
                        outline: 'none',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        fontSize: '12px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: '#b8bdc9',
                        '::placeholder': {
                            color: '#b8bdc9', // Placeholder text color
                        },
                    }}
                        readOnly={isReadOnly || condition}
                        value={city}
                        onChange={handleCityChange}
                        onFocus={isReadOnly ? null : handleHeadingFocus}
                    />
                </Grid>
                <Grid width={'50%'}>
                    <input style={{
                        border: '1px solid #b8bdc9',
                        borderRadius: '3px',
                        padding: '10px',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        fontSize: '12px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor,
                        marginBottom: '8px'
                    }}
                        readOnly={condition}
                        value={provinceInput}
                        onChange={handleProvinceInputChange}
                    />
                    {<Typography variant='subtitle1' color={errorProvince !== '' && provinceInput !== '' ? '#FF0000' : 'transparent'} fontWeight={700}>{errorProvince}</Typography>}

                    <input className="" placeholder={'یک SubLabel وارد کنید'} style={{
                        border: 'none',
                        outline: 'none',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        fontSize: '12px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: '#b8bdc9',
                        '::placeholder': {
                            color: '#b8bdc9', // Placeholder text color
                        },
                    }}
                        readOnly={isReadOnly || condition}
                        value={province}
                        onChange={handleProvinceChange}
                        onFocus={isReadOnly ? null : handleHeadingFocus}
                    />
                </Grid>
            </Grid>
            <Grid>
                <input style={{
                    border: '1px solid #b8bdc9',
                    borderRadius: '3px',
                    padding: '10px',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    marginBottom: '8px'
                }}
                    readOnly={condition}
                    value={postalInput}
                    onChange={handlePostalInputChange}
                />
                {<Typography display={errorPostal !== '' && postalInput !== '' ? 'block' : 'none'} variant='subtitle1' color={'#FF0000'} fontWeight={700}>{errorPostal}</Typography>}

                <input className="" placeholder={'یک SubLabel وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: '#b8bdc9',
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={isReadOnly || condition}
                    value={postal}
                    onChange={handlePostalChange}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                />
            </Grid>
        </Grid>
    )
}
export default Address
