import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import Email from './Email'
import Phone from './Phone'
import SingleChoice from './SingleChoice'
import DropDown from './DropDown'
import Appointment from './Appointment'
import LongText from './LongText'

const HairdressersForm = () => {


    const contactData = [
        { id: 1, label: 'ایمیل', checked: false },
        { id: 2, label: 'تلفن', checked: false },
        { id: 2, label: 'هر دو', checked: false },
    ]


    const serviceData = [
        'کوتاه کردن و سشوار کردن مو',
        'شامپو و سشوار',
        'رنگ - نیمه دائمی',
        'رنگ - دائمی',
        'رنگ - مد',
        'رنگ - سفید کننده و تونر',
        'های لایت - نیمه',
        'های لایت - کامل',
        'های لایت - فویل و رنگ',
        'پرم',
        'صاف کردن مو',
        'ریلکس کننده مو',
        'دیگر',
    ]


    const emailListData = [
        { id: 1, label: 'بله لطفا', checked: false },
        { id: 2, label: 'نه ممنون', checked: false },
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم آرایشگاه ها'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'} />
                <Email header={'ایمیل'} />
                <Phone header={'شماره تلفن'} />
                <SingleChoice header={'کدام روش ارتباطی را ترجیح میدهید؟'} options={contactData} />
                <DropDown header={'خدمات مورد نیاز'} options={serviceData} />
                <Appointment header={'قرار ملاقات'} />
                <LongText header={'جزئیات دیگری که ممکن است بخواهید برجسته کنید'} />
                <SingleChoice header={'من را به لیست ایمیل خود اضافه کنید'} options={emailListData} />
            </Grid>
        </Grid>
    )
}

export default HairdressersForm