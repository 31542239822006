import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid, MenuItem, useMediaQuery, useTheme, Select } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const Time = ({ id, header }) => {




    const { drawerData } = useFormContext();


    const location = useLocation();
    const currentPage = location.pathname;


    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);

    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"



    const existingComponentIndex = drawerData.findIndex(component => component.id === id);


    let switchHeader = header ? header : drawerData[existingComponentIndex]?.state.timeValue ? drawerData[existingComponentIndex].state.timeValue : 'زمان'
    let switchTime = drawerData[existingComponentIndex]?.state.mainInputValue ? drawerData[existingComponentIndex].state.mainInputValue : ''
    let switchSelectTime = drawerData[existingComponentIndex]?.state.selectedOption ? drawerData[existingComponentIndex].state.selectedOption : 'ق.ظ'
    let switchSubHeader = drawerData[existingComponentIndex]?.state.subTimeValue ? drawerData[existingComponentIndex].state.subTimeValue : 'ساعت دقیقه'



    const [timeValue, setTimeValue] = useState(switchHeader);
    const [timeInput, setTimeInput] = useState('');
    const [subTimeValue, setSubTimeValue] = useState(switchSubHeader);
    const [border, setBorder] = useState(false)
    const [selectedOption, setSelectedOption] = useState(switchSelectTime); // State to store the selected option
    const [inputValue, setInputValue] = useState(switchTime);
    const [condition, setCondition] = useState(false)



    const gridRef = useRef(null);



    let theme = useTheme()
    let HeadingColor = theme.palette.blue[700]


    const fillInputValue = () => {

        if (drawerData.length > 0) {

            if (drawerData[existingComponentIndex]) {
                drawerData[existingComponentIndex].state.timeValue = timeValue
                drawerData[existingComponentIndex].state.subTimeValue = subTimeValue
                drawerData[existingComponentIndex].state.selectedOption = selectedOption
                drawerData[existingComponentIndex].state.mainInputValue = inputValue
                drawerData[existingComponentIndex].state.readOnly = readOnly
            }

        }

    }



    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly


    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'Time') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }

    const handleClockChange = (e) => {
        let newValue = e.target.value;
        // Remove non-numeric characters
        newValue = newValue.replace(/\D/g, '');

        // Ensure that the minute part is present
        if (newValue.length >= 2) {
            // Extract hours and minutes
            let hours = newValue.slice(0, 2);
            let minutes = newValue.slice(2, 4);


            // Convert 24 to 00 for hours
            if (hours === '24') {
                hours = '00';
            }

            // Limit hours to 23
            if (parseInt(hours, 10) > 23) {
                hours = '23';
            }


            // Limit minutes to 59
            if (parseInt(minutes, 10) > 59) {
                minutes = '59';
            }
            // Combine hours and minutes
            newValue = `${hours.padStart(2, '0')}:${minutes}`;

            setSelectedOption(parseInt(hours, 10) >= 12 ? 'ب.ظ' : 'ق.ظ');
        }

        setInputValue(newValue);
    };


    const handleChange = (event) => {
        const newOption = event.target.value;
        setSelectedOption(newOption);

        // Convert time if needed
        if (newOption === 'ب.ظ' && inputValue) {
            const [hours, minutes] = inputValue.split(':').map(Number);
            if (hours < 12) {
                setInputValue(`${String(hours + 12).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`);
            }
        } else if (newOption === 'ق.ظ' && inputValue) {
            const [hours, minutes] = inputValue.split(':').map(Number);
            if (hours >= 12) {
                setInputValue(`${String(hours - 12).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`);
            }
        }
    };


    const handleTimeChange = (event) => {
        setTimeValue(event.target.value);
    };


    const handleSubTimeChange = (event) => {
        setSubTimeValue(event.target.value);
    };

    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        event.target.select();
    };


    const handleOutsideClick = (event) => {
        if (gridRef.current && !gridRef.current.contains(event.target)) {
            setBorder(false);
        }
    };



    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };
        fetchData();
    }, [id, selectedOption, timeValue, subTimeValue, inputValue]);

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);



    // useEffect(() => {
    //     const currentHour = new Date().getHours();

    //     // Set initial value based on the current hour
    //     if (currentHour >= 12) {
    //         setSelectedOption('ب.ظ');
    //     } else {
    //         setSelectedOption('ق.ظ');
    //     }
    // }, []);


    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])


    return (
        <Grid
        mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
        p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px') : '0px'}
        borderRadius={'3px'}
        mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'}
            ref={gridRef} onClick={() => {
                setBorder(true)
            }} border={border && drawerData.length > 0 && drawerData[existingComponentIndex] && !isReadOnly ? '2px solid #0293e2' : '2px solid transparent'}>
            <Grid pb={'16px'}>
                <input className="customHead-input" placeholder={'یک عنوان وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={isReadOnly || condition}
                    value={timeValue}
                    onChange={handleTimeChange}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                />
            </Grid>
            <Grid pb={'4px'} display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
                <input
                    className="" placeholder={'HH : MM'}
                    value={inputValue}
                    onChange={handleClockChange}
                    readOnly={condition}
                    style={{
                        border: '1px solid #b8bdc9',
                        borderRadius: '3px',
                        padding: '10px',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        maxWidth: '86px',
                        height: '40px',
                        fontSize: '12px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor,
                        marginBottom: '8px',
                        appearance: 'none',
                        '-moz-appearance': 'textfield',
                    }}
                />
                <Grid position={'relative'}>
                    <Select
                        disabled={condition}
                        value={selectedOption}
                        onChange={handleChange}
                        style={{
                            borderRadius: '3px',
                            // padding: '10px',
                            // paddingLeft:'5px',
                            userSelect: 'text',
                            wordWrap: 'break-word',
                            whiteSpace: 'pre-wrap',
                            width: '100%',
                            maxWidth: '80px',
                            height: '40px',
                            fontSize: '12px',
                            fontWeight: 700,
                            lineHeight: 1.45,
                            color: 'HeadingColor', // You should define HeadingColor somewhere
                            marginBottom: '8px',
                            appearance: 'none',
                            '-moz-appearance': 'textfield',
                        }}
                    >
                        <MenuItem value="ق.ظ" >ق.ظ</MenuItem>
                        <MenuItem value="ب.ظ" >ب.ظ</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Grid>
                <input className="" placeholder={'یک SubLabel وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: '#b8bdc9',
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={isReadOnly || condition}
                    value={subTimeValue}
                    onChange={handleSubTimeChange}
                />
            </Grid>
        </Grid>
    )
}
export default Time
