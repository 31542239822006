import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import SingleChoice from "./SingleChoice";
import Number from "./Number";
import MultiChoice from "./MultiChoice";
import DatePickers from "./DatePicker";
import Address from "./Address";
import Email from "./Email";
import Phone from "./Phone";
import DropDown from "./DropDown";
import ShortText from "./ShortText";
import React from "react";
import Time from "./Time";
import FileUpload from "./FileUpload";
import LongText from "./LongText";

const TravelContactForm = () => {



    const travelData =  [
        {
            id: 1,
            label: "پرواز",
            checked: false
        },
        {
            id: 2,
            label: "هتل",
            checked: false
        },
        {
            id: 3,
            label: "ماشین کرایه ای",
            checked: false
        },
        {
            id: 4,
            label: "سایر",
            checked: false
        },
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم تماس سفر'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن'}/>
                <Address header={'آدرس'}/>
                <ShortText header={"شهر مبدأ"}/>
                <ShortText header={"شهر مقصد"}/>
                <DatePickers header={'تاریخ حرکت'}/>
                <Time header={"زمان حرکت"}/>
                <DatePickers header={'تاریخ برگشت'}/>
                <Time header={'زمان بازگشت'}/>
                <SingleChoice header={'مقدمات سفر مورد نیاز است.'} options={travelData}/>
                <FileUpload header={'لطفا تمام فایل های لازم را آپلود کنید'}/>
                <LongText header={'اطلاعات تکمیلی'}/>
            </Grid>
        </Grid>
    )
}

export default TravelContactForm