import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Phone from "./Phone";
import Email from "./Email";
import Address from "./Address";
import DatePickers from "./DatePicker";
import React from "react";
import MultiChoice from "./MultiChoice";
import LongText from "./LongText";
import FileUpload from "./FileUpload";

const HiringInquiryForm = () => {

    const areaData = [
        { id: 1, label: 'علاقه‌مندی 1', checked: false },
        { id: 2, label: 'علاقه‌مندی 2', checked: false },
        { id: 3, label: 'علاقه‌مندی 3', checked: false },
        { id: 4, label: 'علاقه‌مندی 4', checked: false },
        { id: 5, label: 'علاقه‌مندی 5', checked: false },
        { id: 6, label: 'علاقه‌مندی 6', checked: false },
        { id: 7, label: 'سایر', checked: false },
    ]

    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم درخواست استخدام'} subHeader={'اگر مایل به کسب اطلاعات بیشتر در مورد موقعیت های موجود هستید، لطفا این فرم درخواست را تکمیل و ارسال کنید'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <Phone header={'شماره تلفن'} />
                <Email header={'ایمیل'}/>
                <MultiChoice header={'لطفاً منطقه یا مناطق مورد علاقه خود را انتخاب کنید'} options={areaData}/>
                <DatePickers header={'تاریخ شروع تخمینی'} />
                <Address header={'آدرس'}/>
                <LongText header={'یادداشت های اضافی'}/>
                <FileUpload header={'بارگذاری رزومه'}/>
            </Grid>
        </Grid>
    )
}


export default HiringInquiryForm