import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import DatePickers from './DatePicker'
import SingleChoice from './SingleChoice'
import LongText from './LongText'
import FullName from './FullName'
import Email from './Email'
import DropDown from './DropDown'

const RestaurantEvaluationForm = () => {


    const DineIn_TakeOut = [
        { id: 1, label: "صرف غذا در رستوران" , checked: false },
        { id: 2, label: 'بیرون‌بر', checked: false },
    ]


    const rateData = [
        { id: 1, label: 'عالی', checked: false },
        { id: 2, label: 'خوب', checked: false },
        { id: 3, label: 'متوسط', checked: false },
        { id: 4, label: 'ناراضی', checked: false },
    ]


    const rangeAgeData = [
        'زیر 13',
        '13-17',
        '18-24',
        '25-34',
        '35-44',
        '45-54',
        '55 یا بیشتر',
    ]
    
    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم ارزیابی رستوران'} subHeader={'لطفا به ما اطلاع دهید که غذا و خدمات چگونه بود.'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>

                <DatePickers header={'روز بازدید'}/>
                <SingleChoice header={"صرف غذا در رستوران / بیرون‌بر"} options={DineIn_TakeOut}/>
                <SingleChoice header={'کیفیت غذا'} options={rateData}/>
                <SingleChoice header={'کیفیت خدمات کلی'} options={rateData}/>
                <SingleChoice header={'پاکیزگی'} options={rateData}/>
                <SingleChoice header={'دقت سفارش'} options={rateData}/>
                <SingleChoice header={'سرعت خدمات'} options={rateData}/>
                <SingleChoice header={'ارزش'} options={rateData}/>
                <SingleChoice header={'تجربه کلی'} options={rateData}/>
                <LongText header={'توضیحات'} />
                <FullName header={'نام و نام خانوادگی'} />
                <DropDown header={'سن'} options={rangeAgeData}/>
                <Email header={'ایمیل'} />

            </Grid>
        </Grid>
    )
}

export default RestaurantEvaluationForm