import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import Phone from "./Phone";
import DatePickers from "./DatePicker";
import SingleChoice from "./SingleChoice";
import Time from "./Time";
import ShortText from "./ShortText";
import Number from "./Number";
import DropDown from "./DropDown";
import LongText from "./LongText";
import FileUpload from "./FileUpload";
import React from "react";
import MultiChoice from "./MultiChoice";

const GraphicDesignInquiryForm = ()=>{


    const buyServicesData = [
        { id: 1, label: 'بروشور الکترونیکی', checked: false },
        { id: 2, label: 'دعوت نامه', checked: false },
        { id: 3, label: 'لیست قیمت', checked: false },
        { id: 4, label: 'کارت ویزیت', checked: false },
        { id: 5, label: 'کارت تشکر', checked: false },
        { id: 6, label: 'الگوی برند', checked: false },
        { id: 7, label: 'الگوی داستان', checked: false },
        { id: 8, label: 'نکات برجسته داستان', checked: false },
    ]


    const logoData = [
        'بله',
        'نه',
        'من به یک دعوت نامه نیاز دارم - لوگو لازم نیست',
    ]



    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم درخواست طراحی گرافیک'} subHeader={'هر آنچه را که باید بدانم تا به بهترین شکل به شما خدمت کنم به من بگویید!'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <MultiChoice header={'چه خدمتی را قصد دارید خریداری کنید؟'} options={buyServicesData}/>
                <DatePickers header={'چقدر زود به طراحی‌های خود نیاز دارید؟'}/>
                <DropDown header={'آیا شما یک لوگو دارید؟'} options={logoData}/>
                <FileUpload header={'لوگو را بارگذاری کنید'}/>
                <LongText header={'دوست دارید بروشور/کارت/قالب چه بگوید؟ لطفا هیچ اطلاعاتی را از قلم نیندازید.'}/>
                <LongText header={'آیا تم یا رنگی در ذهن دارید؟'}/>
                <FileUpload header={'هر عکسی را که دوست دارید در طرح خود آپلود کنید'}/>
                <FullName header={"نام و نام خانوادگی"}/>
                <Email header={"ایمیل"}/>
                <Phone header={'شماره تلفن'}/>
            </Grid>
        </Grid>
    )
}


export default GraphicDesignInquiryForm;