import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Phone from "./Phone";
import Email from "./Email";
import SingleChoice from "./SingleChoice";
import Appointment from "./Appointment";
import React from "react";
import Address from "./Address";
import ShortText from "./ShortText";
import Number from "./Number";

const PetServiceAppointmentForm = () => {


    const genderData = [
        {
            id: 1,
            label: "نر",
            checked: false
        },
        {
            id: 2,
            label: "ماده",
            checked: false
        },
    ]


    const serviceData = [
        {
            id: 1,
            label: "نظافت",
            checked: false
        },
        {
            id: 2,
            label: "گردش",
            checked: false
        },
    ]

    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم قرار ملاقات خدمات حیوانات خانگی'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام مالک'}/>
                <Phone header={'شماره تلفن'}/>
                <Email header={'ایمیل'}/>
                <Address header={'آدرس'}/>
                <Heading header={'اطلاعات حیوان خانگی'}/>
                <FullName header={'نام حیوان خانگی'}/>
                <ShortText header={'نژاد'}/>
                <Number header={'سن حیوان خانگی'}/>
                <SingleChoice header={'جنسیت'} options={genderData}/>
                <Heading header={'اطلاعات خدمات'}/>
                <SingleChoice header={'دوست دارید کدام سرویس را دریافت کنید؟'} options={serviceData}/>
                <Appointment header={'یک قرار ملاقات را انتخاب کنید'}/>
            </Grid>
        </Grid>
    )
}


export default PetServiceAppointmentForm;