import { Grid, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search';
import './LoginSelect.css'
import { useCountry } from '../../Context/CountryContext';
import { RotatingLines } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import 'flag-icons/css/flag-icons.min.css';

const LoginSelect = ({ options }) => {

    const { countryId, setCountryId } = useCountry();

    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [countryName, setCountryName] = useState("Iran");
    const [flag , setFlag] = useState('ir')  


    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setSearchTerm("");
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleOptionSelect = (option, id , flag) => {
        setCountryName(option)
        setCountryId(id)
        setIsOpen(false);
        setFlag(flag)
        setSearchTerm("");
    };

    const filteredOptions = options.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);


    return (
        <Grid ref={dropdownRef} className="select-with-search-container" width={{ xs: '100%' }}>
            <Typography variant='h5' mb={'4px'}>کشور</Typography>
            <Grid className="custom-select-country" onClick={toggleDropdown} width={'100%'} p={{ xs: '7px', sm: '14px' }} height={'50px'} display={'flex'} alignItems={'center'} justifyContent={'center'} gap={'4px'}>
                <Typography variant='h5' mt={'4px'}>
                    {countryName}
                </Typography>
                <span className={`fi fi-${flag}`} />
            </Grid>
            {isOpen && (
                <Grid className="dropdown-content" p={{ xs: '6px', sm: '12px' }}>
                    <Grid className="search-container" borderBottom={'1px solid #ccc'}>
                        <SearchIcon className="search-icon" style={{width:'20px' , height:'20px'}}/>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            placeholder="جستجو..."
                            style={{
                                width: '100%'
                            }}
                        />
                    </Grid>
                    {filteredOptions.length > 0 ?
                        filteredOptions.map((option) => (
                            <Grid
                                display={'flex'}
                                alignItems={'center'}
                                gap={'4px'}
                                key={option.id}
                                onClick={() => handleOptionSelect(option.name, option.id , option.sortName.toLowerCase())}
                                className="option"
                            >
                                <span className={`fi fi-${option.sortName.toLowerCase()} mr-2`} />
                                <Typography variant="h6" color={'#0a1551'} mt={'5px'} display={{xs:'none' , xxs:'block'}}>{option.name}</Typography>
                                <Typography variant="h6" color={'#0a1551'} mt={'5px'} display={{xs:'block' , xxs:'none'}}>{option.sortName}</Typography>
                            </Grid>
                        ))
                        :
                        <Grid mt={'24px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <RotatingLines
                                visible={true}
                                height="48"
                                width="48"
                                color="#4379F2"
                                strokeWidth="5"
                                animationDuration="0.75"
                                ariaLabel="rotating-lines-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </Grid>
                    }
                </Grid>
            )}
        </Grid>
    )
}

export default LoginSelect