import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import Email from './Email'
import Phone from './Phone'
import Address from './Address'
import Number from './Number'
import DropDown from './DropDown'
import DatePickers from './DatePicker'
import SingleChoice from './SingleChoice'

const ProductRegistration = () => {


    const provinceData = [
        'آذربایجان شرقی ',
        'آذربایجان غربی ',
        'اردبیل ',
        'اصفهان',
        'البرز',
        'ایلام',
        'بوشهر',
        'تهران',
        'چهارمحال و بختیاری ',
        'خراسان جنوبی ',
        'خراسان رضوی ',
        ' خراسان شمالی ',
        'خوزستان  ',
        'زنجان',
        'سمنان',
        'سیستان و بلوچستان ',
        'فارس',
        'قزوین',
        'قم',
        'کردستان',
        'کرمان',
        'کرمانشاه',
        'کهگیلویه و بویراحمد ',
        'گلستان',
        'گیلان',
        'لرستان',
        'مازندران',
        'مرکزی',
        'هرمزگان',
        'همدان',
        'یزد'
    ]



    const yesOrNoData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'ثبت محصول'} subHeader={'محصول خود را در فرم زیر ثبت کنید.'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flexstart'} justifyContent={'flexstart'}>

                <FullName header={'نام و نام خانوادگی'} />
                <Email header={'ایمیل'} />
                <Phone header={'شماره تلفن'} />
                <Address header={'آدرس'} />
                <Heading header={'اطلاعات محصول'} />
                <FullName header={'نام مدل'} />
                <Number header={'شناسه مدل'} />
                <DropDown header={'خریداری شده از'} options={provinceData} />
                <DatePickers header={'تاریخ خرید'} />
                <SingleChoice header={'آیا می خواهید ما اطلاعیه های محصول و پیشنهادات ویژه را برای شما ارسال کنیم؟'} options={yesOrNoData} />

            </Grid>
        </Grid>
    )
}

export default ProductRegistration