import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import React from "react";
import Address from "./Address";
import ProductList from "./ProductList";
import SingleChoice from "./SingleChoice";
import {v4 as uuidv4} from "uuid";

const ProductPurchaseOrderForm = ()=>{


    const productListData = [
        {
            id: uuidv4(),
            name: "نام محصولات",
            description: "توضیحات را وارد کنید",
            image: null,
            price: "10000",
            quantity: 1
        },
        {
            id: uuidv4(),
            name: "نام محصولات",
            description: "توضیحات را وارد کنید",
            image: null,
            price: "20000",
            quantity: 2
        },
        {
            id: uuidv4(),
            name: "نام محصولات",
            description: "توضیحات را وارد کنید",
            image: null,
            price: "30000",
            quantity: 3
        },
        {
            id: uuidv4(),
            name: "نام محصولات",
            description: "توضیحات را وارد کنید",
            image: null,
            price: "40000",
            quantity: 4
        }
    ]


    const paymentData = [
        {
            id: 1,
            label: "کارت بانکی",
            checked: false
        },
        {
            id: 2,
            label: "پرداخت در مقابل منزل",
            checked: false
        },
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'سفارش خرید'} subHeader={'دوست دارید چه چیزی بخرید؟'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام شما'}/>
                <Email header={'ایمیل شما'}/>
                <Address header={'آدرس حمل و نقل'}/>
                <ProductList header={'محصولات من'} product={productListData}/>
                <SingleChoice header={'روش های پرداخت'} options={paymentData}/>
            </Grid>
        </Grid>
    )
}



export default ProductPurchaseOrderForm