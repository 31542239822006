import { Box, Button, Checkbox, Grid, Tooltip, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import 'react-modern-drawer/dist/index.css'
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { DrawerContext } from '../Context/DrawerContext'
import { useData } from '../Context/DataContext'
import { useDraft } from '../Context/DraftContext'
import { toast } from 'react-toastify'
import { animated, useTransition } from '@react-spring/web';
import EditIcon from '@mui/icons-material/Edit';
import { useFormContext } from '../Context/Context'
import { useEditMode } from '../Context/EditModeContext'
import { useTitleContext } from '../Context/TitleContext'
import axios from 'axios'
import { useRemovedItems } from '../Context/RemovedItemsContext'
import { useArchivedItems } from '../Context/ArchivedItemsContext'
import { useFavoriteItems } from '../Context/FavoriteItemsContext'
import { useFormLimit } from '../Context/FormLimitContext'
import MainMessage from './MainMessage'
import {sectionUrls} from "../api/GetMainPageFormApi";
import {sortItems} from "../Constant/MainNavbarSortItems";
import CustomToast from "./CustomToast/CustomToast";
import MobileMainDrawer from "./MobileMainDrawer";
import MainNavbarButtonAction from "./MainNavbarButtonAction/MainNavbarButtonAction";
import Loading from "./Loading/Loading";


const MainNavbar = () => {


    const { sendToServerData, setSendToServerData } = useData();
    const { selectDrawerItem, setSelectDrawerItem } = useContext(DrawerContext);
    const { draftData, saveDraft, setDraftData } = useDraft()
    const { drawerData, setDrawerData } = useFormContext();
    const { setEditMode } = useEditMode();
    const { title, setTitle } = useTitleContext();
    const { removedItems, setRemovedItems } = useRemovedItems();
    const { archiveItems, setArchiveItems } = useArchivedItems()
    const { favoriteItems, setFavoriteItems } = useFavoriteItems();



    const {
        canCreateForm,
        decrementFormCount
    } = useFormLimit();


    const navigate = useNavigate()

    const [isOpen, setIsOpen] = useState(false)
    const [listData, setListData] = useState(sendToServerData);
    const [checked, setChecked] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [list, setList] = useState(null);
    const [active, setActive] = useState(false);
    const [heading, setHeading] = useState('');
    const [loading, setLoading] = useState('')
    const [error, setError] = useState(null);
    const [formFullDetails , setFormFullDetails] = useState(null)
    const [switchSection , setSwitchSection] = useState('all')
    const [switchItemApi , setSwitchItemApi] = useState()
    const [filteredListData , setFilteredListData] = useState(null)

    const dropdownRef = useRef(null);
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }


    const transitions = useTransition(active, {
        from: { opacity: 0, transform: 'translateY(-20px)' },
        enter: { opacity: 1, transform: 'translateY(0)' },
        leave: { opacity: 0, transform: 'translateY(-20px)' },
    });



    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


    const getCurrentListSetter = () => {

        if (selectDrawerItem === 'Trash') {
            return setRemovedItems;
        } else if (selectDrawerItem === 'Archive') {
            return setArchiveItems;
        } else if (selectDrawerItem === 'Drafts') {
            return setDraftData
        } else if (selectDrawerItem === 'Favorite') {
            return setFavoriteItems
        } else {
            return setList;
        }

    };


    const getCurrentList = () => {

        console.log(selectDrawerItem);

        if (selectDrawerItem === 'Trash') {
            return removedItems;
        } else if (selectDrawerItem === 'Archive') {
            return archiveItems;
        } else if (selectDrawerItem === 'Drafts') {
            return draftData;
        } else if (selectDrawerItem === 'Favorite') {
            return favoriteItems;
        } else {
            return list;
        }

    };

    const handleGetListForm = ()=> {

        const token = localStorage.getItem('authToken');

        setLoading(true);

        axios
            .get(switchSection, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                console.log('Data successfully get from the server:', response.data);
                setLoading(false)
                setList(response.data.output);
            })
            .catch((err) => {
                setError(err); // Handle the error
                console.error("Error fetching data:", err);
            });
    }

    const handleChangeCheckBox = (id) => (event) => {

        const targetList = getCurrentList();
        const setTargetList = getCurrentListSetter();

        const updatedList = targetList.map(item =>
            item.id === id ? { ...item, checked: event.target.checked } : item
        );
        setTargetList(updatedList);

    };



    const handleChangeStarCheckBox = (id) => (event) => {

        setLoading(true)

        const apiUrl = `https://formmaker-api.altynlogistics.com/api/form/${id}/bookmark`

        const token = localStorage.getItem('authToken');

        axios.post(apiUrl, {}, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if(response.data.output.bookmark){
                setLoading(false)
                toast(<CustomToast message={'فرم مورد نظر به لیست علاقمندی ها اضافه شد'} state={'success'} />);
                handleGetListForm()
            }else{
                toast(<CustomToast message={'فرم مورد نظر از لیست علاقمندی ها حذف شد'} state={'success'} />);
                handleGetListForm()
            }
        }).catch((error) => {
            console.error('Error sending data to the server:', error);
        });

    };


    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const isAnyItemChecked = getCurrentList()?.some(item => item.checked);


    const handleChangeForm = (apiUrl , data) => {

        setLoading(true);

        axios
            .post(apiUrl, '', {
                headers: {
                    Accept: '*/*',
                    'Content-Type': 'application/json',
                },
            })
            .then((response) => {
                console.log(response)
                setLoading(false); // End the loading state
                if (response.data.status) {

                    const token = response.data.output.token;
                    localStorage.setItem('authToken', token);

                } else {
                    response.data.errors.length > 0 && toast(<CustomToast message={response.data.errors[0]} state={'error'} />);
                }
            })
            .catch((err) => {
                setLoading(false); // End the loading state on error
                console.error('Error:', err);
            });

    };


    console.log(filteredListData , 'filter')


    const handleGetFormDetails = async (id) => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            console.error("Auth token is missing");
            return;
        }

        setLoading(true); // Show loading spinner
        try {
            const response = await axios.get(
                `https://formmaker-api.altynlogistics.com/api/form/${id}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            const { content } = response.data.output;

            let parsedForms = null;
            try {
                // Parse `forms` field safely
                parsedForms = JSON.parse(content.forms);
            } catch (parseError) {
                console.error("Error parsing forms JSON:", parseError);
                setError("Invalid form data received from the server");
                return;
            }

            if (!parsedForms || !parsedForms.sendToServerData) {
                console.error("Parsed forms structure is invalid");
                setError("Form data is incomplete");
                return;
            }

            console.log(content, "get form details");
            console.log(parsedForms, "parsedForms");

            // Update states with parsed data
            setFormFullDetails(parsedForms);
            setDrawerData(parsedForms.sendToServerData);
            setTitle(content.title);
            setEditMode(true);

            // Navigate after setting states
            navigate('/build');
        } catch (err) {
            setError(err.message || "An error occurred"); // Handle and display error
            console.error("Error fetching data:", err);
        } finally {
            setLoading(false); // Hide loading spinner
        }
    };




    const initializeListData = (data) => {
        return data?.map(item => ({
            ...item,
            checked: item.checked || false // Default to false if `checked` doesn't exist
        }));
    };


    useEffect(() => {
        const initializedData = initializeListData(list);

        let filter = initializedData?.filter(item =>
            item.title.toLowerCase().includes(searchTerm.toLowerCase())
        );

        setFilteredListData(filter)
    }, [list , searchTerm ]);

    useEffect(() => {

        if (selectDrawerItem === 'AllForm') {
            setList(listData)
        } else if (selectDrawerItem === 'Favorite') {
            let favoriteList = listData?.filter(item => item.favorite === true)
            setList(favoriteList)
            setFavoriteItems(favoriteList)
        } else if (selectDrawerItem === 'Trash') {
            setList(removedItems)
        } else if (selectDrawerItem === 'Archive') {
            setList(archiveItems)
        } else if (selectDrawerItem === 'Drafts') {
            setList(draftData)
        }

    }, [listData, removedItems, archiveItems, selectDrawerItem, draftData, favoriteItems])


    useEffect(() => {

        let sortedList = [];

        const applySorting = (data) => {
            switch (heading) {
                case 'عنوان [ الف تا ی ]':
                    return data.sort((a, b) => a.title.localeCompare(b.title));
                case 'عنوان [ ی تا الف ]':
                    return data.sort((a, b) => b.title.localeCompare(a.title));
                case 'قدیمی ترین ارسال':
                    return data; // Original order
                case 'جدیدترین ارسال':
                    return data.reverse(); // Reverse order
                default:
                    return data;
            }
        };

        if (selectDrawerItem === 'AllForm') {
            sortedList = applySorting([...listData]);
        } else if (selectDrawerItem === 'Favorite') {
            sortedList = applySorting([...listData.filter(item => item.favorite === true)]);
        } else if (selectDrawerItem === 'Trash') {
            sortedList = applySorting([...removedItems]);
        } else if (selectDrawerItem === 'Archive') {
            sortedList = applySorting([...archiveItems]);
        } else if (selectDrawerItem === 'Drafts') {
            sortedList = applySorting([...draftData]);
        }

        setList(sortedList);

    }, [listData, removedItems, archiveItems, draftData, selectDrawerItem, heading]);



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setActive(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        setRemovedItems(removedItems);
        setSwitchItemApi(`apiEndPoint`)
        handleChangeForm(switchItemApi , removedItems)
    }, [removedItems, setRemovedItems]);

    useEffect(() => {
        setArchiveItems(archiveItems);
        setSwitchItemApi(`apiEndPoint`)
        handleChangeForm(switchItemApi , archiveItems)
    }, [archiveItems, setArchiveItems]);


    useEffect(() => {
        setFavoriteItems(favoriteItems);
    }, [favoriteItems, setFavoriteItems]);


    useEffect(() => {
        setSwitchSection(sectionUrls[selectDrawerItem] || '');
    }, [selectDrawerItem]);


    useEffect(() => {

        handleGetListForm()

    }, [switchSection]);


    useEffect(() => {
        if (!canCreateForm) {
            toast(<CustomToast message={'شما نمی‌توانید فرم جدیدی ایجاد کنید. حداکثر تعداد ۱۰ فرم مجاز است.'} state={'error'} />);
            return;
        }
    }, [])


    return (
        <Grid width={{ xs: '100%' }} display={'block'}>
            {
                loading && <Loading />
            }
            <Grid width={{ xs: '100%' }} p={{ xs: '14px 10px', md: '9px 13px' }} display={'flex'} alignItems={'center'} justifyContent={{ xs: 'space-between', md: 'flex-end' }} bgcolor={'white'}>
                <Box display={{ xs: 'block', md: 'none' }}>
                    <Grid onClick={toggleDrawer} display={'flex'} alignItems={'center'} p={'0px 10px'} border={'1px solid rgba(200,206,237,1)'} width={'40px'} height={'40px'} borderRadius={'4px'}>
                        <img src='/assets/images/threeDots.svg' alt='' style={{ width: '100%', height: '100%' }} />
                    </Grid>
                </Box>
                <MobileMainDrawer isOpen={isOpen} toggleDrawer={toggleDrawer}/>
                {
                    isAnyItemChecked ?
                        (
                            <MainNavbarButtonAction selectDrawerItem={selectDrawerItem}/>
                        )
                        :
                        (

                            <Box display={'flex'} alignItems={'center'} gap={'8px'}>
                                <Grid sx={{ position: 'relative' }}>
                                    <input
                                        style={{
                                            height: '40px',
                                            borderRadius: '4px',
                                            color: 'rgba(10,21,81,1)',
                                            border: '1px solid rgba(200,206,237,1)',
                                            backgroundColor: 'rgba(255,255,255,1)',
                                            outline: 'rgba(0,0,0,1)',
                                            padding: '0px 32px'
                                        }}
                                        placeholder='جستجو'
                                        value={searchTerm}
                                        onChange={handleSearch}
                                    />
                                    <Grid width={'20px'} height={'20px'} sx={{ position: 'absolute', top: '10px', right: '10px' }}>
                                        <img src='/assets/images/magnifyingGlass.svg' alt='' style={{ width: '100%', height: '100%' }} />
                                    </Grid>
                                </Grid>
                                <Grid style={{ position: 'relative', cursor: 'pointer' }} ref={dropdownRef}>
                                    <Grid p={'0px 10px'} border={active ? '1px solid rgb(44, 51, 69)' : '1px solid rgba(200,206,237,1)'} width={'40px'} height={'40px'} borderRadius={'4px 4px 4px 0px'} onClick={() => { setActive(!active) }} bgcolor={active ? 'rgb(44, 51, 69)' : 'white'}>
                                        <img src='/assets/images/sort.svg' alt='' style={{ width: '100%', height: '100%' }} />
                                    </Grid>
                                    {transitions(
                                        (styles, item) =>
                                            item && (
                                                <animated.div
                                                    style={{
                                                        ...styles,
                                                        position: 'absolute',
                                                        top: '38px',
                                                        left: '0px',
                                                        zIndex: 100,
                                                        backgroundColor: 'rgb(44, 51, 69)',
                                                        borderRadius: '0px 4px 4px 4px',
                                                        padding: '8px',
                                                        width: '244px',
                                                    }}
                                                >
                                                    {sortItems.map((item) => (
                                                        <Button
                                                            onClick={() => setHeading(item.title)}
                                                            key={item.id}
                                                            sx={{
                                                                backgroundColor: 'transparent',
                                                                fontSize: '14px',
                                                                fontWeight: 500,
                                                                color: 'white',
                                                                width: '100%',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'flex-start',
                                                                '&:hover': { backgroundColor: 'white', color: 'rgb(44, 51, 69)' },
                                                            }}
                                                        >
                                                            {item.title}
                                                        </Button>
                                                    ))}
                                                </animated.div>
                                            )
                                    )}
                                </Grid>
                            </Box>
                        )
                }

            </Grid>
            <Grid style={{ height: '600px', maxHeight: '600px', overflowY: 'auto' }}>
                {
                        filteredListData?.length > 0
                        ?
                        filteredListData.map(
                            (item, index) =>
                                <Grid key={index} width={'100%'} px={'14px'}>
                                    <Button sx={{ p: '14px 23px', m: '18px 0px', bgcolor: 'transparent', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', '&:hover': { bgcolor: 'white' } }}>
                                        <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '24px' }}>
                                            <input
                                                className='check'
                                                type='checkbox'
                                                style={{ width: checked ? '18px' : '18px', height: checked ? '18px' : '18px', margin: '0px', display: selectDrawerItem === 'Favorite' ? 'none' : 'block' }}
                                                checked={item.checked || false}
                                                onChange={handleChangeCheckBox(item.id)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                            <Grid width={'36px'} height={'36px'} display={selectDrawerItem === 'AllForm' ? 'block' : 'none'}>
                                                <Checkbox
                                                    style={{ width: '36px', height: '36px' }}
                                                    {...label}
                                                    icon={<StarOutlineIcon fontSize='large' />} checkedIcon={<StarIcon style={{ color: '#ffb629' }} fontSize='large' />}
                                                    checked={item.bookmark}
                                                    onChange={handleChangeStarCheckBox(item.id)}
                                                />
                                            </Grid>
                                            <Grid width={'32px'} height={'32px'}>
                                                <img src='/assets/images/checklist.png' alt='' style={{ width: '100%', height: '100%' }} />
                                            </Grid>
                                            <Grid pt={'4px'}>
                                                <Typography variant='h4' color={'rgb(10, 21, 81)'} fontWeight={700}>{item.title}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Button sx={{ display: selectDrawerItem === 'AllForm' || selectDrawerItem === 'Drafts' ? 'flex' : 'none', alignItems: "center", justifyContent: 'center', '&:hover': { variant: 'contained', bgcolor: '#E2DAD6' } }} onClick={() => { handleGetFormDetails(item.id) }}>
                                            <Grid width={'24px'} height={'24px'}>
                                                <Tooltip title={<Typography variant='subtitle1'>ویرایش</Typography>} arrow>
                                                    <EditIcon fontSize='large' />
                                                </Tooltip>
                                            </Grid>
                                        </Button>
                                    </Button>
                                </Grid>
                        )
                        :
                        <Grid width={'300px'} display={'flex'} alignItems={'center'} justifyContent={'center'} mx={'auto'}>
                            <MainMessage />
                        </Grid>

                }
            </Grid>

        </Grid>
    )
}

export default MainNavbar