import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import Number from './Number'
import DropDown from './DropDown'
import Address from './Address'
import Phone from './Phone'
import Email from './Email'
import LongText from './LongText'
import DatePickers from './DatePicker'

const OnlineInterviewQuestionnaireForm = () => {


    const genderData = ['آقا', 'خانم']

    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'پرسشنامه مصاحبه'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'} />
                <Number header={'سن'}/>
                <DropDown header={'جنسیت'} options={genderData}/>
                <Address />
                <Phone header={'شماره تلفن'} />
                <Email header={'ایمیل'} />
                <LongText header={'خودتان را در چند کلمه توصیف کنید.'} />
                <LongText header={'مهارت های اصلی شما در مورد شغلی که برای آن درخواست داده اید چیست؟'} />
                <LongText header={'مدرک تحصیلی شما چیست؟'} />
                <LongText header={'چرا شغل قبلی خود را رها کردید؟'} />
                <LongText header={'جذاب ترین ویژگی این پروفایل شغلی چیست؟'} />
                <LongText header={'چرا می خواهید به سازمان ما بپیوندید؟'} />
                <LongText header={'بعد از چند سال حضور در این صنعت خود را کجا می بینید؟'} />
                <LongText header={'چرا برای این موقعیت اقدام می کنید؟'} />
                <LongText header={'لطفا در صورت داشتن هر گونه یادداشت اضافی احساس راحتی کنید'} />
                <DatePickers header={'تاریخ'}/>
            </Grid>
        </Grid>
    )
}

export default OnlineInterviewQuestionnaireForm