import {Button, Grid, Typography} from "@mui/material";
import {Circles} from "react-loader-spinner";
import LoginSelect from "../LoginSelect/LoginSelect";
import {Link, useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useCountry} from "../../Context/CountryContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import {toast} from "react-toastify";
import axios from "axios";

const LoginAuthentication = () => {

    const { countryId } = useCountry();

    const location = useLocation();
    const currentPage = location.pathname;
    let navigate = useNavigate();

    let changeTitle = currentPage === '/SignUp' ? 'ثبت نام' : 'ورود';
    const isSignUp = currentPage === '/SignUp';

    const [phoneValue, setPhoneValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [country, setCountry] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state for API request
    const [error, setError] = useState(null);
    const [phoneError, setPhoneError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [countryError, setCountryError] = useState('');


    const CustomToast = ({ message, state }) => {
        return (
            <Grid display="flex" alignItems="start" justifyContent="end" gap="5px" style={{
                backgroundColor: 'white',
                color: state === 'success' ? 'green' : 'red',
                fontSize: '16px',
                padding: '15px 10px',
                fontWeight: 900,
            }}>
                {state === 'success' ? <CheckCircleIcon fontSize="large" /> : <ErrorIcon fontSize="large" />}
                {message}
            </Grid>
        );
    };

    const handleChangePhoneValue = (e) => {
        setPhoneValue(e.target.value);
    };

    const handleChangePasswordValue = (e) => {
        setPasswordValue(e.target.value);
    };

    const handleSubmit = () => {



        const data = {
            phone: String(phoneValue),
            countryId: countryId,
            password: passwordValue,
        };


        if (countryId !== 103) {
            toast(<CustomToast message={'فقط ایران مجاز است'} state={'error'} />)
            return
        } else {

            const apiUrl = isSignUp
                ? 'https://formmaker-api.altynlogistics.com/api/register'
                : 'https://formmaker-api.altynlogistics.com/api/login';

            setLoading(true); // Start the loading state

            axios
                .post(apiUrl, data, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then((response) => {
                    console.log(response)
                    setLoading(false); // End the loading state
                    if (response.data.status) {
                        const token = response.data.output.token;

                        // Store token in localStorage
                        localStorage.setItem('authToken', token);

                        // Navigate to main page
                        navigate('/Main');
                        toast(<CustomToast message={'با موفقیت وارد شدید'} state={'success'} />);
                    } else {
                        response.data.errors.length > 0 && toast(<CustomToast message={response.data.errors[0]} state={'error'} />);
                        setPasswordError(response.data.validations.password && response.data.validations.password[0]);
                        setPhoneError(response.data.validations.phone && response.data.validations.phone[0]);
                        setCountryError(response.data.validations.countryId && response.data.validations.countryId[0]);
                        isSignUp
                            ? toast(<CustomToast message={'ثبت نام انجام نشد لطفا دوباره امتحان کنید.'} state={'error'} />)
                            : toast(<CustomToast message={'ورود ناموفق بود. لطفا اعتبار خود را بررسی کنید.'} state={'error'} />);
                    }
                })
                .catch((err) => {
                    setLoading(false); // End the loading state on error
                    console.error('Error:', err);
                });

        }

    };

    useEffect(() => {
        setPasswordValue('');
        setPhoneValue('');
    }, [currentPage]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://formmaker-api.altynlogistics.com/api/countries');
                setCountry(response.data.output);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return(
        <>
            <Grid display={loading ? 'flex' : 'none'} alignItems="center" justifyContent="center" height="100vh" width={'100vw'} bgcolor={'darkgray'} position={'fixed'} top={0} right={0} style={{ opacity: '0.8', zIndex: 1000 }} >
                <Circles color="#FF6600" height={80} width={80} /> {/* Show loading spinner */}
            </Grid>
            <Grid display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} mt={'36px'}>
                <Grid width={'100px'} height={'100px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                    <img src='/assets/images/AltynLogo.svg' alt='' style={{ width: '100%', height: '100%' }} />
                </Grid>
            </Grid>
            <Grid width={'90%'} maxWidth={'500px'} display={'flex'} alignItems={'center'} justifyContent={'center'} mx={'auto'}>
                <Grid width={'100%'} mx={'auto'} mt={'30px'} bgcolor={'white'} p={{ xs: '30px 15px', sm: '30px' }} borderRadius={'8px'} mb={'48px'}>
                    <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} mb={'50px'}>
                        <Typography variant='h2' color={'#FDA058'} fontWeight={700}>{changeTitle}</Typography>
                    </Grid>
                    <Grid width={'100%'}>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }} style={{width:'100%'}}>
                            <Grid width={'100%'}>
                                <Grid mb={'24px'}>
                                    <Grid display={'flex'} alignItems={'center'} flexDirection={'row-reverse'}
                                          gap={'8px'} width={'100%'}>
                                        <Grid width={{xs: '50%', xxs: '60%'}} mb={'0px'} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                                            <label htmlFor="Phone"
                                                   style={{marginBottom: '4px', fontSize: '16px'}}>تلفن</label>
                                            <input
                                                type="number"
                                                id="Phone"
                                                name="Phone"
                                                value={phoneValue}
                                                onChange={handleChangePhoneValue}
                                                style={{
                                                    padding: '12px',
                                                    color: '#3C3D37',
                                                    borderRadius: '4px',
                                                    outline: 'none',
                                                    fontSize: '16px',
                                                    height: '50px',
                                                    border: '2px solid rgb(60, 61, 55)',
                                                    width: '100%',

                                                }}
                                            />
                                        </Grid>
                                        <Grid width={{xs: '50%', xxs: '40%'}} height={'72px'}>
                                            <LoginSelect options={country}/>
                                        </Grid>
                                    </Grid>
                                    {phoneError && <Typography variant='subtitle1' color={'#B8001F'}
                                                               fontWeight={700}>{phoneError}</Typography>}
                                    {countryError && <Typography mt={'4px'} variant='subtitle1' color={'#B8001F'}
                                                                 fontWeight={700}>{countryError}</Typography>}
                                </Grid>
                                <Grid mb={'48px'} width={'100%'} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                                    <label htmlFor="PassWord" style={{marginBottom: '4px'}}>رمز عبور</label>
                                    <input
                                        type="password"
                                        id="PassWord"
                                        name="PassWord"
                                        value={passwordValue}
                                        onChange={handleChangePasswordValue}
                                        style={{
                                            padding: '12px',
                                            color: '#3C3D37',
                                            borderRadius: '4px',
                                            outline: 'none',
                                            fontSize: '16px',
                                            border: '2px solid rgb(60, 61, 55)',
                                            width: '100%',
                                        }}
                                    />
                                    {passwordError && <Typography variant='subtitle1' color={'#B8001F'}
                                                                  fontWeight={700}>{passwordError}</Typography>}
                                </Grid>
                                <Button
                                    type="submit"
                                    sx={{
                                        bgcolor: '#FDA058',
                                        borderRadius: '12px',
                                        width: '100%',
                                        p: '12px 0px',
                                        color: 'white',
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        boxShadow: 5,
                                        mb: '36px',
                                        '&:hover': {bgcolor: '#FF8343'},
                                    }}
                                >
                                    {changeTitle}
                                </Button>
                            </Grid>
                        </form>

                    </Grid>
                    <Grid display={currentPage === '/SignUp' ? 'none' : 'block'}>
                        <Typography variant='h6' color={''} fontWeight={700} mb={'12px'}>اکانت ندارید؟</Typography>
                        <Link to={'/SignUp'}>
                            <Typography variant='h4' color={'#FDA058'} fontWeight={700}
                                        style={{textDecoration: 'underline'}}>ثبت نام کنید</Typography>
                        </Link>
                    </Grid>
                    <Grid display={currentPage === '/SignUp' ? 'block' : 'none'}>
                        <Typography variant='h6' color={''} fontWeight={700} mb={'12px'}>اکانت دارید؟</Typography>
                        <Link to={currentPage === '/SignUp' ? '/' : '/SignUp'}>
                            <Typography variant='h4' color={'#FDA058'} fontWeight={700}
                                        style={{textDecoration: 'underline'}}>ورود</Typography>
                        </Link>
                    </Grid>
                    <Link to={'/ForgetPassword'}
                          style={{display: currentPage === '/SignUp' ? 'none' : 'block', marginTop: '16px'}}>
                        <Typography variant='h6' color={'#1230AE'} fontWeight={700}
                                    style={{textDecoration: 'underline'}}>رمز عبور خود را فراموش کرده اید؟</Typography>
                    </Link>
                </Grid>
            </Grid>
        </>
    )
}


export default LoginAuthentication;