import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Phone from "./Phone";
import Email from "./Email";
import LongText from "./LongText";
import React from "react";
import ShortText from "./ShortText";
import Captcha from "./Captcha";

const TradeRegistrationForm = ()=>{
    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم ثبت تجارت'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'صاحب کسب و کار'}/>
                <ShortText header={'نام کسب و کار'}/>
                <Phone header={'شماره تماس'}/>
                <Email header={'ایمیل'}/>
                <ShortText header={'نوع کسب و کار'}/>
                <LongText header={'مکان مورد نظر'} />
                <ShortText header={'نرخ ساعتی'}/>
                <LongText header={'پیام'}/>
                <Captcha header={'لطفاً تأیید کنید که انسان هستید'}/>
            </Grid>
        </Grid>
    )
}


export default TradeRegistrationForm