import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import MainHeader from "../Component/MainHeader";
import CloseIcon from "@mui/icons-material/Close";

import { useNavigate } from "react-router-dom";
import FromCreatorTemplate from "../Component/FormCreatorTemplate/FromCreatorTemplate";

const CreateForm = () => {

  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/Main");
  };

  return (
    <Grid width={"100%"} height={"100vh"} bgcolor={"white"}>
      <MainHeader />
      <Grid
        onClick={handleClose}
        width={"40px"}
        height={"40px"}
        borderRadius={"50%"}
        bgcolor={"#e3e5f5"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        m={{ xs: "20px", sm: "20px 40px" }}
        style={{ cursor: "pointer" }}
      >
        <CloseIcon fontSize="large" style={{ color: "#6f76a7" }} />
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            mb: "50px",
          }}
        >
          <Typography
            variant="h2"
            fontWeight={700}
            color={"#0a1551"}
            mb={"10px"}
          >
            یک فرم ایجاد کنید
          </Typography>
          <Typography variant="h5" fontWeight={500} color={"#6f76a7"}>
            برای شروع جمع آوری داده ها، فرمی ایجاد یا وارد کنید
          </Typography>
        </Grid>
        <FromCreatorTemplate />
      </Box>
    </Grid>
  );
};

export default CreateForm;