import React, { useEffect, useRef, useState } from 'react'
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import ReCaptcha from "./ReCaptcha";
import HiCaptcha from "./HiCaptcha";
import SimpleCaptcha from "./SimpleCaptcha";
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';

const Captcha = ({ id, header }) => {


    const { drawerData } = useFormContext();

    const location = useLocation();
    const currentPage = location.pathname;




    const existingComponentIndex = drawerData.findIndex(component => component.id === id);


    let switchHeader = header ? header : "خواهشمندیم اعلام کنید که ربات نیستید."





    const [captchaValue, setCaptchaValue] = useState(switchHeader);
    const [border, setBorder] = useState(false);

    const gridRef = useRef(null);
    const theme = useTheme();
    const isSm = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const isXxs = useMediaQuery((theme) => theme.breakpoints.up('xxs'));
    let HeadingColor = theme.palette.blue[700];



    // const fillInputValue = () => {
    //     drawerData[existingComponentIndex].state.select = 'select'
    // }


    const handlePhoneChange = (event) => {
        setCaptchaValue(event.target.value);
    };

    const handleHeadingFocus = (event) => {
        event.target.select();
    };

    const handleOutsideClick = (event) => {
        if (gridRef.current && !gridRef.current.contains(event.target)) {
            setBorder(false);
        }
    };


    // useEffect(() => {
    //     const fetchData = async () => {
    //         await fillInputValue();
    //     };
    //     fetchData();
    // }, [id]);


    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const width = '100%'
    const font = isXxs ? '16px' : '12px'

    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"


    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px'}
            borderRadius={'3px'}
            width={'100%'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'} bgcolor={'white'}
            ref={gridRef} onClick={() => setBorder(true)}
            border={border && drawerData.length > 0 && drawerData[existingComponentIndex] && !isReadOnly ? '2px solid #0293e2' : '2px solid transparent'}>
            <Grid pb={'16px'} display={'flex'} alignItems={'center'} gap={'8px'} width={'100%'}>
                <input
                    className="customHead-input"
                    placeholder={'Type a question'}
                    style={{
                        border: 'none',
                        outline: 'none',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: width,
                        fontSize: font,
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor,
                    }}
                    value={captchaValue}
                    onChange={handlePhoneChange}
                    onFocus={handleHeadingFocus}
                />
            </Grid>
            <Grid width={'100%'}>
                <SimpleCaptcha />
            </Grid>
        </Grid>
    )
}
export default Captcha
