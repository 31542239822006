import {Grid} from "@mui/material";
import Heading from "./Heading";
import SingleChoice from "./SingleChoice";
import FullName from "./FullName";
import Phone from "./Phone";
import DropDown from "./DropDown";
import DatePickers from "./DatePicker";
import React from "react";
import ShortText from "./ShortText";
import Email from "./Email";
import Address from "./Address";
import Number from "./Number";
import Captcha from "./Captcha";

const CopyrightRequestForm = ()=>{


    const provincesOfPublishData = [
        "تهران",
        "البرز",
        "مازندران",
        "گلستان",
        "خراسان رضوی",
        "خراسان شمالی",
        "خراسان جنوبی",
        "سیستان و بلوچستان",
        "کرمان",
        "هرمزگان",
        "فارس",
        "بوشهر",
        "کهگیلویه و بویراحمد",
        "چهارمحال و بختیاری",
        "اصفهان",
        "یزد",
        "مرکزی",
        "قم",
        "قزوین",
        "زنجان",
        "گیلان",
        "اردبیل",
        "آذربایجان شرقی",
        "آذربایجان غربی",
        "کردستان",
        "همدان",
        "کرمانشاه",
        "لرستان",
        "ایلام",
        "خوزستان"
    ];


    const freeOrPaidData = [
        {
            id: 1,
            label: "رایگان",
            checked: false
        },
        {
            id: 2,
            label: "پرداخت",
            checked: false
        },
    ]


    const formatOfWorkData = [
        {
            id: 1,
            label: "چاپ",
            checked: false
        },
        {
            id: 2,
            label: "آنلاین",
            checked: false
        },
    ]




    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'اطلاعات درخواست کننده'} subHeader={'لطفاً اطلاعاتی در مورد درخواست کننده مجوز حق نشر ارائه دهید.'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام شما'}/>
                <ShortText header={'شرکت/سازمان'}/>
                <ShortText header={'عنوان شغلی'}/>
                <Email header={'آدرس ایمیل'}/>
                <Phone header={'شماره تماس'}/>
                <Address header={'آدرس'}/>

                <Heading header={'اطلاعات محتوا'} subHeader={'لطفا اطلاعاتی در مورد محتوای ارجاع دهید.'}/>
                <ShortText header={'مقاله کجا منتشر شده است؟'}/>
                <ShortText header={'عنوان مقاله ای که باید ارجاع داده شود'}/>
                <Number header={'صفحات برای ارجاع'}/>
                <FullName header={'نام نویسنده'}/>
                <DropDown header={'محل انتشار'} options={provincesOfPublishData}/>
                <DatePickers header={'تاریخ انتشار'}/>

                <Heading header={'درباره کار شما'} subHeader={'لطفاً اطلاعاتی در مورد کار خود ارائه دهید که در آن مطالب ذکر شده در بالا را ارجاع دهید.'}/>
                <ShortText header={'مقاله فوق در کجا مورد استفاده قرار خواهد گرفت؟'}/>
                <FullName header={'نویسنده اثر شما'}/>
                <ShortText header={'ناشر اثر شما'}/>
                <SingleChoice header={'رایگان یا پولی؟'} options={freeOrPaidData}/>
                <SingleChoice header={'قالب کار شما؟'} options={formatOfWorkData}/>
                <Number header={'قیمت فروش مورد انتظار'}/>
                <Captcha header={'لطفاً تأیید کنید که انسان هستید*'}/>
            </Grid>
        </Grid>
    )
}

export default CopyrightRequestForm