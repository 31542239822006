import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import Email from './Email'
import LongText from './LongText'
import FileUpload from './FileUpload'
import Captcha from './Captcha'

const SendFileWithCaptchaForm = () => {
    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'ارسال یک فایل'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام مشتری'} />
                <Email header={'ایمیل'} />
                <LongText header={'توضیحات'} />
                <FileUpload header={'لطفا فایل(های) خود را انتخاب کنید:'} />
                <Captcha header={'لطفا تایید کنید که انسان هستید*'} />
            </Grid>
        </Grid>
    )
}

export default SendFileWithCaptchaForm