import {Box, Grid, Typography} from "@mui/material";
import React from "react";
import {templateData} from "../../Constant/templateData";
import {useNavigate} from "react-router-dom";


const FromCreatorTemplate = () => {


    const navigate = useNavigate();

    const handleNavigate = (link) => {
        navigate(link);
    };


    return(
        <Box
            sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "flex-start",
                gap: { xs: "40px", sm: "60px" },
                mb: "45px",
            }}
        >
            {templateData.map((item) => (
                <Box
                    key={item.id}
                    onClick={() => {
                        handleNavigate(item.link);
                    }}
                    sx={{
                        display: "flex",
                        flexDirection: { xs: "row", sm: "column" },
                        alignItems: { xs: "flex-start", sm: "center" },
                        justifyContent: "center",
                        cursor: "pointer",
                        width: "100%",
                        gap: { xs: "0px", sm: "0px" },
                        transition: 'transform 0.3s ease-in-out',
                        '&:hover': {
                            transform: 'translateY(-3%)',
                            '& .title': {
                                color: '#09f',
                            },
                            '& .image': {
                                border: '2px solid #09f',
                            }
                        },
                    }}
                >
                    <Grid
                        className="image"
                        mb={{ xs: "14px", sm: "28px" }}
                        p={"20px"}
                        bgcolor={"#c8ceed"}
                        borderRadius={{ xs: "0px 10px 10px 0px", sm: "10px" }}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                            border: "2px solid transparent",
                            width: { xs: "105px", sm: "163px" },
                            height: { xs: "130px", sm: "153px" },
                            cursor: "pointer",
                            "&:hover": { border: "2px solid #09f" },
                        }}
                    >
                        {item.image}
                    </Grid>
                    <Grid
                        bgcolor="#f3f4f7"
                        display={{ xs: "flex", sm: "none" }}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                        justifyContent={"center"}
                        sx={{ height: { xs: "130px", sm: "153px" } }}
                        borderRadius={"10px 0px 0px 10px"}
                        p={"16px"}
                    >
                        <Typography
                            className="title"
                            mb={{ xs: "9px", sm: "18px" }}
                            style={{ width: "153px" }}
                            variant="h5"
                            fontWeight={700}
                            sx={{ ":&hover": { color: "#09f" } }}
                        >
                            {item.title}
                        </Typography>
                        <Typography
                            style={{ width: "153px" }}
                            variant="h6"
                            color={"#6f76a7"}
                            fontWeight={500}
                        >
                            {item.subTitle}
                        </Typography>
                    </Grid>
                    <Grid display={{ xs: "none", sm: "block" }}>
                        <Typography
                            className="title"
                            mb={{ xs: "9px", sm: "18px" }}
                            textAlign={"center"}
                            style={{ width: "153px" }}
                            variant="h4"
                            fontWeight={700}
                            sx={{ ":&hover": { color: "#09f" } }}
                        >
                            {item.title}
                        </Typography>
                        <Typography
                            textAlign={"center"}
                            style={{ width: "153px" }}
                            variant="h5"
                            color={"#6f76a7"}
                            fontWeight={500}
                        >
                            {item.subTitle}
                        </Typography>
                    </Grid>
                </Box>
            ))}
        </Box>
    )
}


export default FromCreatorTemplate