import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import ShortText from "./ShortText";
import LongText from "./LongText";
import React from "react";
import Phone from "./Phone";
import SingleChoice from "./SingleChoice";
import DatePickers from "./DatePicker";
import Time from "./Time";
import Number from "./Number";
import DropDown from "./DropDown";

const AirportPickupReservationForm = () => {


    const typeOfTripData  = [
        {
            id: 1,
            label: "یک طرفه",
            checked: false
        },
        {
            id: 2,
            label: "رفت و برگشت",
            checked: false
        },
        {
            id: 3,
            label: "ساعتی",
            checked: false
        },
    ]



    const airportData = ['فرودگاه اول','فرودگاه دوم', 'فرودگاه سوم' , 'سایر']


    const pieceData = [
        '1 عدد',
        '2 عدد',
        '3 عدد',
        '4 عدد',
        '5 عدد',
        '6 عدد',
        '7 عدد',
        '8 عدد',
        '9 عدد',
        '10 عدد',
        '10 عدد',
        'بیش از 10 قطعه'
    ]


    const passengersData = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        'بیش از 14 مسافر'
    ]



    const childCarData = [
        '1',
        '2',
        '3',
        '4',
    ]


    const yesOrNoData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]



    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'رزرو سفر'} subHeader={'یک پیکاپ رزرو کنید'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <Heading header={'اطلاعات شخصی'}/>
                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن'}/>
                <Heading header={'اطلاعات سفر'}/>
                <SingleChoice header={'نوع سفر'} options={typeOfTripData}/>
                <DatePickers header={'تاریخ رفت'}/>
                <Time header={'زمان رفت'}/>
                <DatePickers header={'تاریخ بازگشت'}/>
                <Time header={'زمان بازگشت'}/>

                <Number header={'تعداد ساعت'}/>
                <SingleChoice header={'آیا این یک تحویل فرودگاهی است؟'} options={yesOrNoData}/>
                <DropDown header={'فرودگاه'} options={airportData}/>

                <ShortText header={'شرکت هواپیمایی'}/>
                <ShortText header={'شماره پرواز'}/>
                <DropDown header={'تعداد چمدان'} options={pieceData}/>

                <ShortText header={'مکان/آدرس دریافت'}/>
                <ShortText header={'محل/آدرس تحویل'}/>
                <DropDown header={'تعداد مسافران'} options={passengersData}/>

                <SingleChoice header={'توقف های اضافی؟'} options={yesOrNoData}/>
                <LongText header={'لطفا توقف(های) را مشخص کنید'}/>
                <SingleChoice header={'آیا به صندلی ماشین کودک نیاز دارید؟'} options={yesOrNoData}/>

                <DropDown header={'چه تعداد؟'} options={childCarData}/>
                <LongText header={'نظر/درخواست های ویژه'}/>
            </Grid>
        </Grid>
    )

}


export default AirportPickupReservationForm