import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import DatePickers from "./DatePicker";
import Phone from "./Phone";
import Email from "./Email";
import Address from "./Address";
import MultiChoice from "./MultiChoice";
import ShortText from "./ShortText";
import React from "react";
import SingleChoice from "./SingleChoice";
import DropDown from "./DropDown";

const MedicalVolunteerApplication = ()=>{


    const yesOrNoData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]


    const typeOfProvider = [

        'پزشک',
        'دستیار پزشک',
        'پرستار متخصص',
        'پرستار ثبت‌شده',
        'پرستار عملی دارای مجوز',
        'متخصص بیهوشی پرستاری دارای گواهی‌نامه',
        'تکنسین فوریت‌های پزشکی',
        'متخصص تنفس',
        'دستیار پرستار دارای گواهی‌نامه',
        'مددکار اجتماعی',
        'داروساز',
        'متخصص فیزیوتراپی',
        'ارائه‌دهنده خدمات سلامت روانی و رفتاری',
        'دندانپزشک',
        'متخصص کاردرمانی',

    ]



    const careAreaData = [

        'واحد مراقبت‌های ویژه (ICU)',
        'بخش اورژانس (ED)',
        'بیماری‌های عفونی',
        'بخش جراحی پزشکی (Med-Surg)',
        'اتاق عمل یا واحد قبل و بعد از عمل (OR or Perioperative unit)',
        'بخش سرپایی (Ambulatory)',
        'محیط‌های غیرحاد (خارج از بیمارستان)',
        'اطفال (Pediatrics)',

    ]


    const typePatientData = [
        { id: 1, label: 'دستگاه‌های تنفس مصنوعی', checked: false },
        { id: 2, label: 'مراقبت تسکینی', checked: false },
        { id: 3, label: 'قطره‌های وازوپرسور', checked: false },
        { id: 4, label: 'لوله‌گذاری تراشه (اینتوباسیون)', checked: false },
        { id: 5, label: 'دستگاه‌های دیالیز (CRRT یا CVVH)', checked: false },
        { id: 6, label: 'خطوط مرکزی (سنترال لاین)', checked: false },
        { id: 7, label: 'جاگذاری سرم وریدی (IVs)', checked: false },
        { id: 8, label: 'مراقبت‌های پزشکی بستری عمومی', checked: false },
        { id: 9, label: 'مراقبت‌های پزشکی سرپایی عمومی', checked: false },
        { id: 10, label: 'زایمان و بخش زایمان', checked: false },
        { id: 11, label: 'آماده‌سازی دارو / آویزان کردن سرم‌ها', checked: false },
    ]


    const highestLevelData = [
        'من می توانم تخصیص بیمار و مراقبت از مدارک را در EHR انجام دهم',
        'من می توانم به ارائه دهندگان دیگر کمک کنم',
    ]


    const workStatusData = [
        '**در حال فعالیت حرفه‌ای',
        '**فعالیت حرفه‌ای ندارم، اما بازنشسته نیستم',
        'بازنشسته',
    ]


    const highestNumberOfHours = [
        '4',
        '6',
        '8',
        '12',
    ]


    const volunteerFullTime = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "نه فقط پاره وقت",
            checked: false
        },
    ]



    const covidData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "نه ",
            checked: false
        },
        {
            id: 3,
            label: "سایر",
            checked: false
        },
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'درخواست داوطلبان پزشکی'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>

                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن'}/>
                <Address header={'آدرس'}/>
                <DatePickers header={'تاریخ تولد'}/>
                <ShortText header={'محل تولد'}/>
                <ShortText header={'زبان هایی که علاوه بر زبان انگلیسی روان صحبت می شوند'}/>
                <SingleChoice header={'آیا شما یک متخصص پزشکی مجاز هستید؟'} options={yesOrNoData}/>
                <FullName header={'مسئول تماس اضطراری'}/>
                <Phone header={'شماره تماس اضطراری'}/>
                <DropDown header={'نوع ارائه دهنده'} options={typeOfProvider}/>
                <DropDown header={'ناحیه تخصصی یا مراقبتی'} options={careAreaData}/>
                <MultiChoice header={'چه نوع تجربه مراقبت از بیمار دارید؟'} options={typePatientData}/>
                <DropDown header={'لطفا بالاترین سطح مراقبت از بیمار را نشان دهید'} options={highestLevelData}/>
                <DropDown header={'وضعیت کار'} options={workStatusData}/>
                <DropDown header={'لطفاً بیشترین تعداد ساعاتی را که می توانید در یک روز کار کنید را مشخص کنید'} options={highestNumberOfHours}/>
                <SingleChoice header={'آیا می توانید بصورت تمام وقت داوطلب شوید؟'} options={volunteerFullTime}/>
                <SingleChoice header={'لطفاً اگر شرایطی از قبل وجود داشته است، به ویژه مواردی که خطر ابتلا به کووید-19 را افزایش داده است، مشخص کنید'} options={covidData}/>
            </Grid>
        </Grid>
    )
}


export default MedicalVolunteerApplication;