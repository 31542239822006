import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import Phone from "./Phone";
import DropDown from "./DropDown";
import DatePickers from "./DatePicker";
import React from "react";
import LongText from "./LongText";

const TimeOffRequestForm = ()=>{

    const reasonData = [
        'تعطیلات',
        'مرخصی شخصی',
        'بیماری',
        'سایر',
    ]

    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'درخواست زمان استراحت'} subHeader={'درخواست مرخصی از محل کار'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام کارمند'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تماس'}/>
                <DatePickers header={'تاریخ شروع'}/>
                <DatePickers header={'تاریخ پایان'}/>
                <DatePickers header={'شروع به کار کنید'}/>
                <DropDown header={'دلیل'} options={reasonData}/>
                <LongText header={'نظرات اضافی'}/>
            </Grid>
        </Grid>
    )
}


export default TimeOffRequestForm