import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import Phone from "./Phone";
import LongText from "./LongText";
import React from "react";
import Address from "./Address";
import Appointment from "./Appointment";
import DatePickers from "./DatePicker";
import SingleChoice from "./SingleChoice";

const AppointmentRequestForm = ()=>{

    const yesOrNoData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]

    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم درخواست قرار ملاقات'} subHeader={'به ما اطلاع دهید که چگونه می توانیم به شما کمک کنیم!'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'آدرس ایمیل'}/>
                <Phone header={'شماره تماس'}/>
                <Address header={'آدرس'}/>
                <Appointment header={'چه تاریخ و ساعتی برای شما بهتر عمل می کند؟'}/>
                <DatePickers header={'هر تاریخ و زمان مشخص دیگری، در صورتی که انتخاب فوق مناسب نباشد.'}/>
                <LongText header={'به چه خدماتی علاقه دارید؟'}/>
                <SingleChoice header={'آیا مایلید از خدمات تبلیغاتی مطلع شوید؟'} options={yesOrNoData}/>
            </Grid>
        </Grid>
    )
}


export default AppointmentRequestForm;