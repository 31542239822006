import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import Phone from "./Phone";
import Address from "./Address";
import LongText from "./LongText";
import React from "react";
import ProductList from "./ProductList";
import SingleChoice from "./SingleChoice";

const GenericProductOrderForm = ()=>{


    const yesOrNoData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]


    const paymentMethodData = [
        { id: 1, label: 'پرداخت آنلاین', checked: false },
        { id: 2, label: 'پرداخت درب منزل', checked: false },
    ]


    const productList = [
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '10000',
            quantity: 1,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '10000',
            quantity: 1,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '10000',
            quantity: 1,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '10000',
            quantity: 1,
        },
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم سفارش محصول عمومی'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>

                <ProductList header={'محصولات من'} product={productList}/>
                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'آدرس ایمیل'}/>
                <Phone header={'شماره تماس'}/>
                <Address header={'آدرس صورتحساب'}/>
                <SingleChoice header={'آیا آدرس صورتحساب با آدرس حمل و نقل یکی است؟'} options={yesOrNoData}/>
                <Address header={'آدرس حمل و نقل'}/>
                <SingleChoice header={'آیا می خواهید سفارش خود را به عنوان هدیه ارسال کنید؟'} options={yesOrNoData}/>
                <FullName header={'نام گیرنده'}/>
                <LongText header={'آیا یادداشتی برای گیرنده وجود دارد؟'}/>
                <LongText header={'درخواست ها، پیام ها یا نگرانی های اضافی؟'}/>
                <SingleChoice header={'روش های پرداخت'} options={paymentMethodData}/>

            </Grid>
        </Grid>
    )
}


export default GenericProductOrderForm