import React, {useEffect, useState} from 'react'
import CreateFormNavbar from '../Component/CreateFormNavbar'
import { Box, Button, Grid, Typography } from '@mui/material'
import MainHeader from '../Component/MainHeader'
import SearchIcon from '@mui/icons-material/Search';
import { useFormContext } from '../Context/Context';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import {useTitleContext} from "../Context/TitleContext";
import {useEditMode} from "../Context/EditModeContext";
import Loading from "../Component/Loading/Loading";

const CloneExistingForm = () => {


    const navigate = useNavigate()


    const { setDrawerData } = useFormContext();
    const { setTitle } = useTitleContext();
    const { setEditMode } = useEditMode();





    const [active, setActive] = useState(null)
    const [searchQuery, setSearchQuery] = useState('');
    const [formFullDetails , setFormFullDetails] = useState(null)
    const [loading, setLoading] = useState('')
    const [error, setError] = useState(null);
    const [formList , setFormList] = useState([])





    const handleChoiceForm = (id, state) => {
        setActive(id)
        setDrawerData(state)
    }


    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = formList.filter(item =>
        item.title.toLowerCase().includes(searchQuery.toLowerCase())
    );


    const handleGetListForm = ()=> {

        const token = localStorage.getItem('authToken');

        setLoading(true);

        axios
            .get('https://formmaker-api.altynlogistics.com/api/form?offset=0&status=all', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((response) => {
                console.log('Data successfully get from the server:', response.data);
                setLoading(false)
                setFormList(response.data.output);
            })
            .catch((err) => {
                setError(err); // Handle the error
                console.error("Error fetching data:", err);
            });
    }


    const handleGetFormDetails = async (id) => {
        const token = localStorage.getItem('authToken');
        if (!token) {
            console.error("Auth token is missing");
            return;
        }

        setLoading(true); // Show loading spinner
        try {
            const response = await axios.get(
                `https://formmaker-api.altynlogistics.com/api/form/${id}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );

            console.log(response , 'mmn')

            const { content } = response.data.output;


            let parsedForms = null;
            try {
                parsedForms = JSON.parse(content.forms);
                console.log(parsedForms , 'content');

            } catch (parseError) {
                console.error("Error parsing forms JSON:", parseError);
                setError("Invalid form data received from the server");
                return;
            }

            if (!parsedForms) {
                console.error("Parsed forms structure is invalid");
                setError("Form data is incomplete");
                return;
            }

            console.log(content, "get form details");
            console.log(parsedForms, "parsedForms");

            // Update states with parsed data
            setFormFullDetails(parsedForms);
            setDrawerData(parsedForms.sendToServerData);
            setTitle(content.title);
            setEditMode(true);

            navigate('/build');
        } catch (err) {
            setError(err.message || "An error occurred"); // Handle and display error
            console.error("Error fetching data:", err);
        } finally {
            setLoading(false); // Hide loading spinner
        }
    };



    useEffect(()=>{
       handleGetListForm()
    },[])



    return (
        <Grid width={'100%'} height={'100vh'} bgcolor={'white'}>
            {
                loading && <Loading />
            }
            <MainHeader />
            <Grid p={'28px 32px'}>
                <CreateFormNavbar title={"فرم موجود خود را شبیه‌سازی کنید"} subTitle={"یک نسخه از یکی از فرم‌های موجود خود ایجاد کنید"} back={'/createForm/ImportForm'} flag={true} />
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} width={'100%'} maxWidth={'720px'} mx={'auto'}>
                    <Grid className='searchInput' width={'100%'} sx={{ position: 'relative' }} mb={'24px'}>
                        <input
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder='در فرم های خود جستجو کنید'
                            style={{
                                color: '#0a1551',
                                backgroundColor: '#f3f3fe',
                                width: '100%',
                                border: 'none',
                                borderRadius: '4px',
                                padding: '1rem 3.5rem 1rem 1rem',
                                fontSize: '16px',
                                outline: 'none'
                            }}
                        />
                        <Grid sx={{ position: 'absolute', right: '10px', top: '10px' }}>
                            <SearchIcon style={{ color: '#686D76', rotate: '90deg' }} fontSize='large' />
                        </Grid>
                    </Grid>
                    <Grid className='formList' width={'100%'} maxHeight={'325px'} overflow={'auto'}>
                        {
                            filteredData.map(
                                item =>
                                    <Box
                                        onClick={() => { handleChoiceForm(item.id, item.state) }}
                                        sx={{
                                            p: '15px',
                                            border: active === item.id ? '1px solid rgb(0, 153, 255)' : '1px solid rgb(200, 206, 237)',
                                            bgcolor: active === item.id ? 'rgb(237, 248, 255)' : 'rgb(255, 255, 255)',
                                            borderRadius: '2px',
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '16px',
                                            mb: '16px',
                                            cursor: 'pointer',
                                            transition: 'background-color 0.3s ease',
                                            '&:hover': {
                                                backgroundColor: active === null ? 'rgb(243, 243, 254)' : 'rgb(219, 241, 255)',
                                            }
                                        }}
                                    >
                                        <Grid width={'32px'} height={'32px'}>
                                            <img src='/assets/images/checklist.png' alt='' style={{ width: '100%', height: '100%' }} />
                                        </Grid>
                                        <Typography pt={'4px'} variant='h4' fontWeight={700} color={'rgb(10, 21, 81)'}>{item.title}</Typography>
                                    </Box>
                            )
                        }
                    </Grid>
                    <Grid mt={'40px'}>
                        <Button onClick={()=>{handleGetFormDetails(active)}} disabled={active === null ? true : false} variant='contained' style={{ backgroundColor: '#4675e2', padding: '12px 28px', borderRadius: '4px', cursor: 'pointer', opacity: active === null ? 0.5 : 1 }}>
                            <Typography color={'#fff'} variant='h6' pt={'2px'} fontWeight={700}>
                                فرم ایجاد کنید
                            </Typography>
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}

export default CloneExistingForm