import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useInputValues } from '../Context/InputValuesContext';
import { useErrorContext } from '../Context/ErrorContext';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';


const MultiChoice = ({ id, header, options }) => {


    const { drawerData } = useFormContext();
    const { finalSelectValues } = useContext(FinalValuesContext);





    const location = useLocation();
    const currentPage = location.pathname;
    const existingComponentIndex = drawerData.findIndex(component => component.id === id);


    let switchHeader = header ? header : drawerData[existingComponentIndex]?.state.titleValue ? drawerData[existingComponentIndex].state.titleValue : 'یک عنوان وارد کنید'
    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"



    let switchOption = options ?
        options
        :
        drawerData[existingComponentIndex]?.state.multiData.length > 0
            ?
            drawerData[existingComponentIndex].state.multiData
            :
            [
                { id: 1, label: 'گزینه اول را وارد کنید', checked: false },
                { id: 2, label: 'گزینه دوم را وارد کنید', checked: false },
                { id: 3, label: 'گزینه سوم را وارد کنید', checked: false },
                { id: 4, label: 'گزینه چهارم را وارد کنید', checked: false },
            ]

    const { readOnly } = useReadOnly();


    const [multiData, setMultiData] = useState(switchOption);

    const [selectedOptions, setSelectedOptions] = useState([]);

    const [selectedTitles, setSelectedTitles] = useState(drawerData[existingComponentIndex].state.mainSelectedTitles);

    const [titleValue, setTitleValue] = useState(switchHeader);
    const [isSubHeaderVisible, setSubHeaderVisible] = useState(false);
    const [isHeadingFocused, setHeadingFocused] = useState(false);
    const [show, setShow] = useState(true);
    const [hoverStates, setHoverStates] = useState({});
    const [draggedItemId, setDraggedItemId] = useState(null);
    const [condition, setCondition] = useState(false)



    const containerRef = useRef(null);
    const inputOptionRef = useRef(null);



    const fillInputValue = () => {


        if (drawerData.length > 0) {

            if (drawerData[existingComponentIndex]) {
                drawerData[existingComponentIndex].state.titleValue = titleValue;
                drawerData[existingComponentIndex].state.multiData = multiData;
                drawerData[existingComponentIndex].state.selectedOptions = selectedOptions;
                drawerData[existingComponentIndex].state.mainSelectedTitles = selectedTitles;
                drawerData[existingComponentIndex].state.readOnly = readOnly
            }

        }

    };


    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly


    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'MultiChoice') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }


    const handleRemoveOption = (id) => {
        if (!condition) {
            const updatedData = multiData.filter(item => item.id !== id);
            // Check if "Other" option exists after removing an option
            const isOtherOptionExists = updatedData.some(item => item.label.trim().toLowerCase() === 'سایر');
            setMultiData(updatedData);
            // Update the show state based on the presence of "Other" option
            setShow(!isOtherOptionExists);
        }
    }

    const handleDragStart = (id) => {
        setDraggedItemId(id);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (targetId) => {
        if (draggedItemId === null || draggedItemId === targetId) {
            return;
        }

        const updatedData = [...multiData];
        const draggedItemIndex = updatedData.findIndex(item => item.id === draggedItemId);
        const targetItemIndex = updatedData.findIndex(item => item.id === targetId);

        // Swap the positions of the dragged item and the target item
        [updatedData[draggedItemIndex], updatedData[targetItemIndex]] = [updatedData[targetItemIndex], updatedData[draggedItemIndex]];

        setMultiData(updatedData);
        setDraggedItemId(null);
    };

    const handleMouseEnter = (id) => {
        setHoverStates(prev => ({ ...prev, [id]: true }));
    };

    const handleMouseLeave = (id) => {
        setHoverStates(prev => ({ ...prev, [id]: false }));
    };

    const handleAddOption = () => {
        const isAnyLabelEmpty = multiData.some(item => item.label.trim() === '');
        const isOtherOptionExists = multiData.some(item => item.label.trim().toLowerCase() === 'سایر');
        const newId = Math.max(...multiData.map(item => item.id)) + 1;
        const newOption = { id: newId, label: '', checked: false };

        if (!isAnyLabelEmpty) {
            setMultiData(prevOptions => {
                const optionsWithoutOther = prevOptions.filter(item => item.label.trim().toLowerCase() !== 'سایر');
                return isOtherOptionExists
                    ? [...optionsWithoutOther, newOption, { id: newId + 1, label: 'سایر', checked: false }]
                    : [...prevOptions, newOption];
            });
        }
    };


    const handleAddOtherOption = () => {
        const newId = Math.max(...multiData.map(item => item.id)) + 1;
        const newOption = { id: newId, label: 'سایر', checked: false };
        setMultiData(prevOptions => [...prevOptions, newOption]);
        setShow(false)
    };


    const handleTitleChange = (event) => {
        setTitleValue(event.target.value);
    };

    const handleChangeOption = (e, id) => {
        const value = e.target.value;

        if (value.trim() === '' && isHeadingFocused === false) {
            // If the input is empty, remove the item from singleData
            setMultiData(prevOptions =>
                prevOptions.filter(item => item.id !== id)
            );
        } else {
            // If the input is not empty, update the label in the corresponding item
            setMultiData(prevOptions =>
                prevOptions.map(item =>
                    item.id === id ? { ...item, label: value } : item
                )
            );
        }
    };


    const handleCheckboxChange = (id, isChecked) => {
        setMultiData(prevOptions =>
            prevOptions.map(item =>
                item.id === id ? { ...item, checked: isChecked } : item
            )
        );
        const selectedOption = multiData.find(item => item.id === id);
        if (isChecked) {
            setSelectedTitles(prevTitles => [...prevTitles, selectedOption.label]);
        } else {
            setSelectedTitles(prevTitles => prevTitles.filter(title => title !== selectedOption.label));
        }
        // Update selectedOptions with id and checked status
        setSelectedOptions(prevOptions =>
            isChecked ? [...prevOptions, id] : prevOptions.filter(optionId => optionId !== id)
        );
    };


    // Add a global click event listener to handle clicks outside the first grid


    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        setHeadingFocused(true); // Set focus state to true
        event.target.select();
    };


    const handleClickOutside = (event) => {
        if (containerRef.current && !containerRef.current.contains(event.target)) {
            setSubHeaderVisible(false);
            setHeadingFocused(false);
        } else {
            setSubHeaderVisible(true);
            setHeadingFocused(true);
        }
    };


    const handleClickOutSideInputOption = (event) => {
        if (inputOptionRef.current && !inputOptionRef.current.contains(event.target)) {
            setMultiData(prevOptions => {
                const updatedOptions = prevOptions.filter(item => item.label.trim() !== '');
                return updatedOptions;
            });
        }
    }


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        document.addEventListener('click', handleClickOutSideInputOption);

        return () => {
            document.removeEventListener('click', handleClickOutSideInputOption);
        };
    }, []);



    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id, titleValue, multiData, selectedOptions]);


    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])


    let theme = useTheme()
    let HeadingColor = theme.palette.blue[700]

    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px') : '0px'}
            borderRadius={'3px'}
            width={'100%'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'} ref={containerRef}
            border={`2px solid ${isHeadingFocused && drawerData.length > 0 && drawerData[existingComponentIndex] && !isReadOnly ? '#0293e2' : 'transparent'}`}
            onClick={handleClickOutside}>
            <Grid className={'HeadingInput'} pb={'14px'}>
                <input className="customHead-input" placeholder={'یک عنوان وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={isReadOnly || condition}
                    value={titleValue}
                    onChange={handleTitleChange}
                    onClick={handleClickOutside}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                />
            </Grid>
            <Grid ref={inputOptionRef} onClick={handleClickOutSideInputOption}>
                <Grid mb={'10px'}>
                    {
                        multiData.map(
                            item =>
                                <Grid display={'flex'} alignItems={'center'}
                                    position={'relative'}
                                    zIndex={100}
                                    pb={'10px'}
                                    onMouseEnter={() => handleMouseEnter(item.id)}
                                    onMouseLeave={() => handleMouseLeave(item.id)}
                                    draggable
                                    onDragStart={() => handleDragStart(item.id)}
                                    onDragOver={handleDragOver}
                                    onDrop={() => handleDrop(item.id)}
                                >
                                    <Grid display={'flex'} alignItems={'center'} justifyContent={'flex-start'}
                                        width={'fit-content'}
                                    >
                                        <input
                                            checked={item.checked}
                                            disabled={condition}
                                            onChange={(e) => handleCheckboxChange(item.id, e.target.checked)}
                                            name={'radio'} id={'item.id'} type={'checkbox'} style={{
                                                width: '18px',
                                                height: '18px',
                                            }} />
                                        <label htmlFor={'item.id'}>
                                            {
                                                item.label === 'سایر' ?
                                                    <input
                                                        placeholder={'یک گزینه را وارد کنید'}
                                                        style={{
                                                            border: 'none',
                                                            outline: 'none',
                                                            userSelect: 'text',
                                                            wordWrap: 'break-word',
                                                            whiteSpace: 'pre-wrap',
                                                            width: 'fit-content',
                                                            fontSize: '14px',
                                                            fontWeight: 700,
                                                            lineHeight: 1.45,
                                                            color: HeadingColor,
                                                            cursor: 'not-allowed',
                                                            '::placeholder': {
                                                                color: '#b8bdc9', // Placeholder text color
                                                            },
                                                        }}
                                                        value={item.label}
                                                        onClick={handleClickOutside}
                                                        readOnly={isReadOnly || condition}
                                                    />
                                                    :
                                                    <input
                                                        placeholder={'یک گزینه را وارد کنید'}
                                                        style={{
                                                            border: 'none',
                                                            outline: 'none',
                                                            userSelect: 'text',
                                                            wordWrap: 'break-word',
                                                            whiteSpace: 'pre-wrap',
                                                            width: 'fit-content',
                                                            fontSize: '14px',
                                                            fontWeight: 700,
                                                            lineHeight: 1.45,
                                                            color: HeadingColor,
                                                            '::placeholder': {
                                                                color: '#b8bdc9', // Placeholder text color
                                                            },
                                                        }}
                                                        value={item.label}
                                                        onChange={(e) => handleChangeOption(e, item.id)}
                                                        onClick={handleClickOutside}
                                                        onFocus={handleHeadingFocus}
                                                        readOnly={isReadOnly || condition}
                                                    />
                                            }

                                        </label>
                                    </Grid>
                                    <Grid width={'24px'} height={'24px'} borderRadius={'50%'}
                                        display={hoverStates[item.id] && !isReadOnly ? 'flex' : 'none'} alignItems={'center'}
                                        justifyContent={'center'}
                                        style={{ cursor: 'pointer' }}
                                        sx={[
                                            {
                                                backgroundColor: 'white',
                                                color: '#b8bdc9'
                                            },
                                            {
                                                '&:hover': {
                                                    backgroundColor: '#b8bdc9',
                                                    color: 'white'
                                                },
                                            },
                                        ]}
                                        onClick={isReadOnly || condition ? null : () => handleRemoveOption(item.id)}
                                    >
                                        <CloseIcon fontSize={'medium'} />
                                    </Grid>
                                </Grid>
                        )
                    }
                </Grid>
                <Grid display={isReadOnly ? 'none' : 'flex'} alignItems={'center'} justifyContent={'flex-start'} gap={'4px'}>
                    <Grid onClick={isReadOnly || condition ? null : handleAddOption}
                        sx={[
                            {
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#636a96',
                                padding: '5px 10px',
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                transition: 'all 0.25s ease',
                                border: 'none',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            },
                            {
                                '&:hover': {
                                    backgroundColor: '#636a96',
                                    color: 'white',
                                },
                            },
                        ]}>
                        افزودن گزینه
                    </Grid>
                    <Grid onClick={isReadOnly|| condition ? null : handleAddOtherOption} display={show ? 'block' : 'none'}
                        sx={[
                            {
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#636a96',
                                padding: '5px 10px',
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                transition: 'all 0.25s ease',
                                border: 'none',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            },
                            {
                                '&:hover': {
                                    backgroundColor: '#636a96',
                                    color: 'white',
                                },
                            },
                        ]}
                    >
                        گزینه "سایر" را اضافه کنید
                    </Grid>
                </Grid>
            </Grid>
            <Grid>
                <Typography variant='subtitle2'>با کلیک بر روی گزینه میتوانید تربیت گزینه ها را جابه‌جا کنید</Typography>
            </Grid>
        </Grid>
    )
}
export default MultiChoice
