import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import ShortText from "./ShortText";
import Phone from "./Phone";
import Email from "./Email";
import LongText from "./LongText";
import React from "react";
import DropDown from "./DropDown";
import SingleChoice from "./SingleChoice";
import MultiChoice from "./MultiChoice";

const WebsiteDesignAndDevelopmentForm = ()=>{


    const typeOfBusiness = [
        'وزارت',
        'مشاوره',
        'غیر انتفاعی',
        'غذا',
        'تکنولوژی',
        'تجارت الکترونیک',
        'موسیقی',
    ]


    const domain = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]


    const typeOfWebsite = [
        'شخصی',
        'آموزشی',
        'ورزش',
        'غذا، رستوران',
        'کسب و کار محلی',
        'کسب و کار کوچک',
        'کسب و کار متوسط',
    ]


    const navigationBarData = [
        {
            id: 1,
            label: "درباره",
            checked: false
        },
        {
            id: 2,
            label: "سوالات متداول",
            checked: false
        },
        {
            id: 3,
            label: "وبلاگ",
            checked: false
        },
        {
            id: 4,
            label: "قیمت گذاری",
            checked: false
        },
        {
            id: 5,
            label: "تجارت الکترونیک",
            checked: false
        },
        {
            id: 6,
            label: "رزرو",
            checked: false
        },
        {
            id: 7,
            label: "رسانه ها",
            checked: false
        },
    ]


    const additionalServices = [
        {
            id: 1,
            label: "طراحی بنر",
            checked: false
        },
        {
            id: 2,
            label: "طراحی صفحه لندینگ",
            checked: false
        },
        {
            id: 3,
            label: "مدیریت تبلیغات",
            checked: false
        },
        {
            id: 4,
            label: "سئو",
            checked: false
        },
        {
            id: 5,
            label: "خرید دامنه",
            checked: false
        },
        {
            id: 6,
            label: "طراحی لوگو",
            checked: false
        },
    ]


    const actionVisitingSite = [
        {
            id: 1,
            label: "تماس با شما",
            checked: false
        },
        {
            id: 2,
            label: "فرم تماس را پر کنید",
            checked: false
        },
        {
            id: 3,
            label: "خرید محصول",
            checked: false
        },
        {
            id: 4,
            label: "رزرو",
            checked: false
        },
    ]

    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'پرسشنامه طراحی سایت'} subHeader={'لطفا وقت بگذارید و به همه سوالات پاسخ دهید. طراحی و توسعه وب یک فرآیند مشارکتی است و نیاز به مشارکت شما در تمامی مراحل دارد.'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تماس'}/>
                <ShortText header={'شرکت'}/>
                <DropDown header={'نوع کسب و کار'} options={typeOfBusiness}/>
                <ShortText header={'مهلت تکمیل سایت'}/>
                <ShortText header={'بودجه شما چقدر است؟'}/>

                <SingleChoice header={'آیا قبلاً صاحب نام دامنه هستید؟'} options={domain}/>
                <DropDown header={'نوع وب سایت مورد نیاز شما؟'} options={typeOfWebsite}/>
                <ShortText header={'چه دو رنگی برای تم سایت خود می خواهید؟'}/>
                <MultiChoice header={'موارد را برای نوار پیمایش انتخاب کنید'} options={navigationBarData}/>

                <LongText header={'موارد بیشتر'}/>
                <LongText header={'چه چیزی ارائه می دهید؟ فهرستی از تمام بخش‌ها/صفحه‌هایی که فکر می‌کنید به آن‌ها نیاز دارید تهیه کنید.'}/>
                <MultiChoice header={'خدمات اضافی'} options={additionalServices}/>
                <MultiChoice header={'کاربر هنگام بازدید از سایت شما چه اقداماتی را باید انجام دهد؟'} options={actionVisitingSite}/>
                <LongText header={'آیا ویژگی های دیگری وجود دارد که می خواهید برای سایت خود یا چیزهایی که می خواهید در آینده اضافه کنید؟ لطفا تا حد امکان دقیق و دقیق بیان کنید.'}/>
                <LongText header={'نظرات اضافی'}/>
            </Grid>
        </Grid>
    )
}

export default WebsiteDesignAndDevelopmentForm;