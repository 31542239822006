import React from 'react'
import { Grid, Typography } from '@mui/material';
import '../App.css'
import { Link} from 'react-router-dom';
import {useTitleContext } from '../Context/TitleContext';
import '../App.css'
import { useSwitchForm } from '../Context/SwitchFormContext';


const Navbar = ({ active, state }) => {



    const { title , setTitle } = useTitleContext();
    const { switchForm } = useSwitchForm();


    const navbarData = [
        { id: 1, title: 'ساختن', link: switchForm === 'classic' ? '/build' : "/build/CardForm" },
        { id: 2, title: 'تنظیمات', link: '/Setting' },
        { id: 3, title: 'انتشار', link: switchForm === 'classic' ? '/Publish' : '/Publish/CardForm' },
    ]


    const handleChangeHeader = (e) => {
        setTitle(e.target.value)
    }



    return (
        <Grid position={'fixed'} top={0} right={0} zIndex={1000} mb={state ? '0px' : { xs: '0px', xxs: '60px' }} width={'100%'}>
            <Grid p={'10px 20px'} position={'relative'} height={'70px'} bgcolor={'white'} display={'flex'} flexDirection={{xs:'column'}} alignItems={'center'} justifyContent={'center'}>
                <Grid>
                    <Link to={'/Main'}>
                        <Grid className='logo positionCenter' position={'absolute'} width={'50px'} height={'50px'} bgcolor={'rgb(68, 74, 93)'} display={'flex'} alignItems={'center'} justifyContent={'center'} borderRadius={'4px'}>
                            <img src='/assets/images/AltynLogo.svg' alt='' style={{ width: '70%', height: '70%' }} />
                        </Grid>
                    </Link>
                    <Typography className='typoCenter' display={{xs:'none' , sm:'block'}} variant='h4' color={'#0a1551'} fontWeight={700} mr={{ xxs: "25px", sm: '5px', md: '-10px', lg: '-30px', g: '-55px' }}>گروه آلتن</Typography>
                </Grid>
                <Grid display={{xs:'block' , sm:'none'}} width={'100%'}>
                    <input
                        value={title}
                        onChange={handleChangeHeader}
                        style={{
                            border:'none',
                            outline:'none',
                            fontSize:'20px',
                            fontWeight:700,
                            width:'100%',
                        }}
                    />
                </Grid>
                <Grid display={{xs:'none' , sm:'block'}}>
                    <input
                        value={title}
                        onChange={handleChangeHeader}
                        style={{
                            border:'none',
                            outline:'none',
                            fontSize:'24px',
                            fontWeight:700,
                            width:'100%',

                        }}
                    />
                </Grid>
            </Grid>
            <Grid width={'100%'} display={'flex'} alignItems={'center'} justifyContent={'center'} style={{ background: 'linear-gradient(270deg, #ff6100 0%, #ffb841 99.97%)', }} height={'49px'}>
                {
                    navbarData.map(
                        item =>
                            <Link to={item.link} key={item.id}>
                                <Grid py={'13px'} px={'27px'} bgcolor={active === item.id ? 'rgba(255, 255, 255, .25)' : 'transparent'} style={{ cursor: 'pointer' }}
                                    className="navbar-item">
                                    <Typography variant='h4' color={'rgba(255, 255, 255, .75)'} fontWeight={700}>{item.title}</Typography>
                                </Grid>
                            </Link>
                    )
                }
            </Grid>
        </Grid>
    )
}

export default Navbar