import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Phone from "./Phone";
import Email from "./Email";
import DatePickers from "./DatePicker";
import LongText from "./LongText";
import React from "react";
import ShortText from "./ShortText";
import Appointment from "./Appointment";
import FileUpload from "./FileUpload";

const OnlineJobApplicationForm = () => {
    return(

        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم درخواست کار'} subHeader={'لطفا فرم زیر را برای ارسال درخواست شغلی خود پر کنید!'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>

                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن'}/>
                <ShortText header={'موقعیت درخواستی'}/>
                <DatePickers header={'زودترین تاریخ ممکن برای شروع'}/>
                <Appointment header={'تاریخ ترجیحی برای مصاحبه'}/>
                <LongText header={'نامه همراه'}/>
                <FileUpload header={'آپلود رزومه'}/>
                <FileUpload header={'هر سند دیگری برای آپلود'}/>

            </Grid>
        </Grid>

    )
}

export default OnlineJobApplicationForm