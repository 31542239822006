import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import DropDown from "./DropDown";
import LongText from "./LongText";
import React from "react";
import ShortText from "./ShortText";

const StudentProgressReportTemplate = () => {

    const gradeData = [
        'درجه 1',
        'درجه21',
        'درجه 3',
        'درجه 4',
        'درجه 5',
        'درجه 6',
        'درجه 7',
        'درجه 8',
    ]


    const areaData = [
        'تحصیلی (توانایی)',
        'تمرکز (رفتار/نگرش/علاقه)',
        'دانشگاهی و تمرکز',
    ]

    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'گزارش پیشرفت دانش آموزان'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام معلم'}/>
                <ShortText header={'موضوع و سطح'}/>
                <DropDown header={'سطح کلاس را مشخص کنید.'} options={gradeData}/>
                <LongText header={'فهرست دانش‌آموزان'}/>
                <DropDown header={'منطقه / مناطق مورد نظر را انتخاب کنید'} options={areaData}/>
                <LongText header={'راه حل پیشنهادی'}/>
            </Grid>
        </Grid>
    )
}

export default StudentProgressReportTemplate;