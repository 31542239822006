import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import Phone from "./Phone";
import LongText from "./LongText";
import React from "react";
import SingleChoice from "./SingleChoice";
import Appointment from "./Appointment";

const RequestAnAppointmentForm = ()=>{


    const yesOrNoData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'درخواست قرار ملاقات'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={"نام و نام خانوادگی"}/>
                <Phone header={'شماره تلفن'}/>
                <Email header={'ایمیل'}/>
                <SingleChoice header={'اولین بازدید شماست؟'} options={yesOrNoData}/>
                <Appointment header={'یک قرار ملاقات را انتخاب کنید'}/>
                <LongText header={'نظرات'}/>
            </Grid>
        </Grid>
    )
}


export default RequestAnAppointmentForm;