import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Phone from "./Phone";
import Email from "./Email";
import React from "react";
import Number from "./Number";
import Appointment from "./Appointment";
import DropDown from "./DropDown";
import ShortText from "./ShortText";

const TransportationOrderInquiryForm = () => {


    const tableReserveData = [
        "بله",
        "خیر",
    ]


    const reserveTypeData = [
        'شام',
        'VIP / میزانسن',
        'تولد / سالگرد',
        'زندگی شبانه',
        'خصوصی',
        'عروسی',
        'شرکتی',
        'تعطیلات',
        'سایر',
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم استعلام سفارش حمل و نقل'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'ایمیل'}/>
                <Phone header={' تلفن'} />
                <Number header={'تعداد مهمانان:'}/>
                <Appointment header={'رزرو'}/>
                <DropDown header={'رزرو میز:'} options={tableReserveData}/>
                <DropDown header={'نوع رزرو:'} options={reserveTypeData}/>
                <ShortText header={'اگر گزینه "سایر" را انتخاب کرده‌اید، لطفاً مشخص کنید؟'}/>
                <ShortText header={'درخواست خاصی دارید؟'}/>
            </Grid>
        </Grid>
    )
}

export default TransportationOrderInquiryForm