import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import DatePickers from './DatePicker'
import Email from './Email'
import Phone from './Phone'
import Address from './Address'
import MultiChoice from './MultiChoice'
import Number from './Number'
import DropDown from './DropDown'
import SingleChoice from './SingleChoice'
import ShortText from './ShortText'
import LongText from './LongText'
import ScaleRating from './ScaleRating';

const HealthSurvey = () => {



    const contactData = [
        {
            id: 1, label: 'تماس تلفنی', checked: false
        },
        {
            id: 2, label: 'پیام تلفنی', checked: false
        },
        {
            id: 3, label: 'ایمیل', checked: false
        },
        {
            id: 4, label: 'سایر', checked: false
        },
    ]


    const genderData = ['آقا', 'خانم']


    const MedicinalData = [
        {
            id: 1, label: 'دیابت', checked: false
        },
        {
            id: 2, label: 'فشار خون بالا', checked: false
        },
        {
            id: 3, label: 'کلسترول بالا', checked: false
        },
        {
            id: 4, label: 'تیروئید', checked: false
        },
        {
            id: 5, label: 'لیتیوم', checked: false
        },
        {
            id: 6, label: 'کومادین (وارفارین)', checked: false
        },
    ]



    const diseaseData = [
        {
            id: 1, label: 'فشار خون بالا', checked: false
        },
        {
            id: 2, label: 'دیابت - نوع 1', checked: false
        },
        {
            id: 3, label: 'دیابت - نوع 2', checked: false
        },
        {
            id: 4, label: 'نقرس', checked: false
        },
    ]


    const yesOrNoData =[
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]




    const foodHabitData =[
        {
            id: 1,
            label: "همیشه",
            checked: false
        },
        {
            id: 2,
            label: "معمولا",
            checked: false
        },
        {
            id: 3,
            label: "گاهی اوقات",
            checked: false
        },
        {
            id: 4,
            label: "به ندرت",
            checked: false
        },
        {
            id: 5,
            label: "هرگز",
            checked: false
        },
        {
            id: 6,
            label: "سایر",
            checked: false
        },
    ]


    const smokeData =[
        {
            id: 1,
            label: "1 بسته در روز",
            checked: false
        },
        {
            id: 2,
            label: "2 بسته در روز",
            checked: false
        },
        {
            id: 3,
            label: "3 بسته در روز",
            checked: false
        },
        {
            id: 4,
            label: "خیر",
            checked: false
        },
    ]



    const weekData =[
        {
            id: 1,
            label: "1-3 در هفته",
            checked: false
        },
        {
            id: 2,
            label: "3-5 در هفته",
            checked: false
        },
        {
            id: 3,
            label: "هر روز",
            checked: false
        },
        {
            id: 4,
            label: "هرگز",
            checked: false
        },
    ]



    const sleepData = [
        {
            id: 1,
            label: "کمتر از 5 ساعت",
            checked: false
        },
        {
            id: 2,
            label: "5-6 ساعت",
            checked: false
        },
        {
            id: 3,
            label: "7-8 ساعت",
            checked: false
        },
        {
            id: 4,
            label: "9-10 ساعت",
            checked: false
        },
        {
            id: 5,
            label: "بیش از 10 ساعت",
            checked: false
        },
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'بررسی سلامت'} subHeader={'لطفا این نظرسنجی سلامت را پر کنید'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام'}/>
                <DatePickers header={'تاریخ'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن'}/>
                <Address header={'آدرس'}/>
                <MultiChoice header={'تماس‌های ترجیحی'} options={contactData}/>
                <DatePickers header={'تاریخ تولد'}/>
                <Number header={'سن'}/>
                <DropDown header={'جنسیت'} options={genderData}/>
                <Heading header={'پزشکی'}/>
                <MultiChoice header={'آیا شما یکی از موارد زیر را دارید؟'} options={diseaseData}/>
                <MultiChoice header={'آیا دارویی مصرف می کنید'} options={MedicinalData}/>
                <SingleChoice header={'آیا شما باردار هستید؟'} options={yesOrNoData}/>
                <SingleChoice header={'آیا شما پرستاری می کنید؟'} options={yesOrNoData}/>
                <SingleChoice header={'آیا آلرژی غذایی دارید؟'} options={yesOrNoData}/>
                <ShortText header={'لطفا توضیح دهید'}/>
                <Heading header={'BMI'}/>
                <Number header={'قد'} placeholder={'175cm'}/>
                <Number header={'وزن'} placeholder={'75kg'}/>
                <Number header={'عدد BMI فعلی شما چند است؟'}/>
                <Number header={'مقدار BMI برای وزنی که می خواهید در آن حفظ کنید چقدر است؟'}/>
                <Heading header={'خواب'}/>
                <ShortText header={'به طور کلی در چه ساعتی به رختخواب می روید؟'}/>
                <ShortText header={'کلا کی بیدار میشیوید؟'}/>
                <ShortText header={'به طور متوسط ​​چند ساعت می خوابید؟'}/>
                <SingleChoice header={'آیا وقتی بیدار می‌شوید احساس شادابی می‌کنید؟'} options={yesOrNoData}/>
                <LongText header={'کیفیت خواب شما چگونه است؟'}/>
                <Heading header={'هیدراتاسیون'}/>
                <ShortText header={'در روز معمولا چقدر آب می نوشید؟'}/>
                <ShortText header={'معمولاً در روز چقدر قهوه می نوشید؟'}/>
                <ShortText header={'در یک هفته معمولا چقدر الکل مصرف می کنید؟'}/>
                <Heading header={'حرکت'}/>
                <ShortText header={'چند بار در هفته ورزش می کنید؟'}/>
                <LongText header={'در چه نوع تمرینی شرکت می کنید؟'}/>
                <LongText header={'آیا کارهایی وجود دارد که دوست دارید انجام دهید و در حال حاضر قادر به انجام آن نیستید؟'}/>
                <ScaleRating header={'سطح انرژی روزانه خود را چگونه ارزیابی می کنید؟'} bestTitle={'بیشترین'} worthTitle={'کمترین'}/>
                <Heading header={'استرس'}/>
                <ShortText header={'برای کار چه می کنید؟'}/>
                <ShortText header={'آیا از کاری که انجام می دهید لذت می برید؟'}/>
                <LongText header={'آیا استرس دیگری در زندگی شما وجود دارد؟'}/>
                <ScaleRating header={'سطح کلی استرس خود را ارزیابی کنید'} bestTitle={'بیشترین'} worthTitle={'کمترین'}/>
                <Heading header={'عادات غذایی'}/>
                <ShortText header={'اولین وعده غذایی خود را چه زمانی می خورید؟'}/>
                <ShortText header={'آخرین وعده غذایی خود را چه زمانی می خورید؟'}/>
                <ShortText header={'چند وعده غذایی در روز می خورید؟'}/>
                <LongText header={'میان وعده می خورید؟ اگر بله، چه میان وعده ای میل می کنید؟'}/>
                <SingleChoice header={'چند بار در یک هفته بیرون غذا می خورید؟'} options={foodHabitData}/>
                <LongText header={'معمولاً کجا بیرون غذا می خورید؟'}/>
                <Heading header={'وزن'}/>
                <ShortText header={'وزن فعلی'}/>
                <ShortText header={'وزن هدف'}/>
                <ShortText header={'قد'}/>
                <SingleChoice header={'آیا قبلاً برای کاهش وزن تلاش کرده اید؟'} options={yesOrNoData}/>
                <LongText header={'در گذشته سخت ترین قسمت کاهش وزن را چه چیزی می دانستید؟'}/>
                <SingleChoice header={'سیگار میکشید؟'} options={smokeData}/>
                <SingleChoice header={'آیا ورزش انجام می دهید؟'} options={weekData}/>
                <SingleChoice header={'چند ساعت میخوابید؟'} options={sleepData}/>
            </Grid>
        </Grid>
    )
}

export default HealthSurvey