import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import Phone from "./Phone";
import DatePickers from "./DatePicker";
import LongText from "./LongText";
import React from "react";
import Address from "./Address";
import Number from "./Number";

const BookingEnquiryForm = () => {
    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم درخواست رزرو'} subHeader={'پس از دریافت فرم ثبت شده، به زودی برای تایید در دسترس بودن اتاق با شما تماس خواهیم گرفت.'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>

                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن'}/>
                <Address header={'آدرس'}/>
                <Number header={'تعداد بزرگسالان'}/>
                <Number header={'تعداد کودکان 6-15 سال'}/>
                <Number header={'تعداد کودکان 0-5 ساله'}/>
                <DatePickers header={'تاریخ ورود'}/>
                <DatePickers header={'تاریخ حرکت'}/>
                <LongText header={'نظرات؟'}/>

            </Grid>
        </Grid>
    )
}


export default BookingEnquiryForm