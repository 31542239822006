import {Box, Button, Grid, Tooltip, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArchiveIcon from "@mui/icons-material/Archive";
import RestoreIcon from "@mui/icons-material/Restore";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import React from "react";

const MainNavbarButtonAction = ({selectDrawerItem}) => {
    return(
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <Grid display={selectDrawerItem === 'Trash' || selectDrawerItem === 'Archive' ? 'none' : 'block'}>
                <Tooltip title={<Typography variant='subtitle1'>حذف</Typography>} arrow>
                    <Button variant='outlined' color='error' style={{ width: '40px', height: '40px' }}>
                        <DeleteIcon style={{ color: '#B80000', width: '20px', height: '20px' }} />
                    </Button>
                </Tooltip>
            </Grid>
            <Grid display={selectDrawerItem === 'Trash' || selectDrawerItem === 'Archive' ? 'none' : 'block'}>
                <Tooltip title={<Typography variant='subtitle1'>بایگانی</Typography>} arrow>
                    <Button variant='outlined' color='primary' style={{ width: '40px', height: '40px' }}>
                        <ArchiveIcon style={{ color: '#478CCF', width: '20px', height: '20px' }} />
                    </Button>
                </Tooltip>
            </Grid>
            <Grid display={selectDrawerItem === 'Trash' ? 'block' : 'none'}>
                <Tooltip title={<Typography variant='subtitle1'>بازیابی</Typography>} arrow>
                    <Button variant='outlined' color='warning' style={{ width: '40px', height: '40px' }}>
                        <RestoreIcon style={{ color: '#FF9100', width: '20px', height: '20px' }} />
                    </Button>
                </Tooltip>
            </Grid>
            <Grid display={selectDrawerItem === 'Archive' ? 'block' : 'none'}>
                <Tooltip title={<Typography variant='subtitle1'>خروج از آرشیو</Typography>} arrow>
                    <Button variant='outlined' color='secondary' style={{ width: '40px', height: '40px' }}>
                        <UnarchiveIcon style={{ color: '#7469B6', width: '20px', height: '20px' }} />
                    </Button>
                </Tooltip>
            </Grid>
            <Grid className='DeleteRemove' display={selectDrawerItem === 'Trash' || selectDrawerItem === 'Archive' ? 'block' : 'none'}>
                <Tooltip title={<Typography variant='subtitle1'>حذف</Typography>} arrow>
                    <Button variant='contained' color='error' style={{ width: '40px', height: '40px' }}>
                        <DeleteIcon style={{ color: 'white', width: '20px', height: '20px' }} />
                    </Button>
                </Tooltip>
            </Grid>
        </Box>
    )
}


export default MainNavbarButtonAction;