import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import ShortText from "./ShortText";
import Email from "./Email";
import Phone from "./Phone";
import MultiChoice from "./MultiChoice";
import FileUpload from "./FileUpload";
import LongText from "./LongText";
import React from "react";
import Number from "./Number";
import ScaleRating from "./ScaleRating";

const RealEstateMarketingQuestionnaire = () => {


    const marketingData = [
        {
            id: 1,
            label: 'روزنامه/چاپ',
            checked: false
        },
        {
            id: 2,
            label: 'تبلیغات فیس بوک',
            checked: false
        },
        {
            id: 3,
            label: 'تراکت',
            checked: false
        },
        {
            id: 4,
            label: 'تبلیغات اینستاگرام',
            checked: false
        },
        {
            id: 5,
            label: 'تلویزیون',
            checked: false
        },
        {
            id: 6,
            label: 'گوگل',
            checked: false
        },
        {
            id: 7,
            label: 'سئو',
            checked: false
        },
        {
            id: 8,
            label: 'رادیو',
            checked: false
        },
        {
            id: 9,
            label: 'سایر',
            checked: false
        },
        {
            id: 10,
            label: 'هیچ کدام',
            checked: false
        },

    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'پرسشنامه بازاریابی املاک و مستغلات'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={"نام و نام خانوادگی"}/>
                <Phone header={'شماره تلفن'}/>
                <MultiChoice header={'در حال حاضر از کدام نوع بازاریابی استفاده می کنید؟'} options={marketingData}/>
                <LongText header={'بزرگترین مانع شماره 1 که شما را در حال حاضر از رسیدن به اهداف رشد خود باز می دارد چیست؟'}/>
                <ShortText header={'چقدر انگیزه دارید که امسال معاملات ملکی بیشتری داشته باشید؟'}/>
                <ShortText header={'آیا در حال حاضر به صورت آنلاین سرنخ ایجاد می کنید یا در گذشته داشته اید؟'}/>
                <ShortText header={'اگر بله، لطفاً می توانید آن تجربه را به طور خلاصه توضیح دهید؟'}/>
                <ShortText header={'شما/تیم شما در سال گذشته چند واحد فروختید؟'}/>
                <ShortText header={'چند نفر در تیم شما هستند؟ (در صورت وجود)'}/>
                <Number header={'اگر راه حل مناسبی برای اضافه کردن 2، 3 و حتی 4 یا 5 خانه در ماه به خط لوله خود ارائه شود، در مقیاس 1 تا 10 چقدر علاقه مند خواهید بود که مشتری شوید؟'}/>
                <ScaleRating header={'اگر راه حل مناسبی برای اضافه کردن 2، 3 و حتی 4 یا 5 خانه در ماه به خط لوله خود ارائه شود، در مقیاس 1 تا 10 چقدر علاقه مند خواهید بود که مشتری شوید؟'} bestTitle={'خیلی علاقه‌مند'} worthTitle={'علاقه‌مند نیستم'}/>
            </Grid>
        </Grid>
    )
}


export default RealEstateMarketingQuestionnaire;