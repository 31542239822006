
import React from 'react'
import LoginAuthentication from "../Component/Auth/LoginAuthentication";

const Login = () => {





    return (
        <LoginAuthentication />
    )
}

export default Login