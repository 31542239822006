import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import FullName from './FullName'
import ShortText from './ShortText'
import Address from './Address'
import LongText from './LongText'
import Email from './Email'
import Phone from './Phone'
import DatePickers from './DatePicker'
import Captcha from './Captcha'

const OnlineOrderFormWithoutPayment = () => {
    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم سفارش آنلاین بدون پرداخت'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام'} />
                <ShortText header={'حساب / نام فیس بوک'}/>
                <Address header={'آدرس حمل و نقل'}/>
                <LongText header={'سفارش/ها'}/>
                <Email header={'ایمیل'} />
                <Phone header={'شماره تلفن'} />
                <ShortText header={'نحوه پرداخت'} />
                <DatePickers header={'تاریخ دقیق پرداخت'}/>
                <ShortText header={'پیک'}/>
                <LongText header={'یادداشت های اضافی'}/>
                <Captcha header={'پیام را همانطور که نشان داده شده وارد کنید'}/>
            </Grid>
        </Grid>
    )
}

export default OnlineOrderFormWithoutPayment