import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import ProductList from './ProductList'
import DatePickers from './DatePicker'
import FullName from './FullName'
import Phone from './Phone'
import Email from './Email'
import LongText from './LongText'

const PizzaOrderForm = () => {


    const productData = [

        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'پیتزا ناپلی',
            description: 'مواد تشکیل دهنده',
            image: null,
            price: '150000',
            quantity: 3,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'پیتزا پپرونی',
            description: 'مواد تشکیل دهنده',
            image: null,
            price: '130000',
            quantity: 1,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'پیتزا ماره مونتی',
            description: 'مواد تشکیل دهنده',
            image: null,
            price: '150000',
            quantity: 4,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'آب پرتقال',
            description: 'مواد تشکیل دهنده',
            image: null,
            price: '80000',
            quantity: 1,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'لیموناد',
            description: 'مواد تشکیل دهنده',
            image: null,
            price: '90000',
            quantity: 2,
        },

    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم سفارش پیتزا'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <ProductList header={'منو'} product={productData} />
                <DatePickers header={'تاریخ تحویل'}/>
                <FullName header={'نام'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن'}/>
                <LongText header={'یادداشت های اضافی'}/>
            </Grid>
        </Grid>
    )
}

export default PizzaOrderForm