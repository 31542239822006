import {Grid} from "@mui/material";
import FullName from "./FullName";
import Email from "./Email";
import Phone from "./Phone";
import MultiChoice from "./MultiChoice";
import SingleChoice from "./SingleChoice";
import Heading from "./Heading";
import ShortText from "./ShortText";
import LongText from "./LongText";
import Address from "./Address";
import ProductList from "./ProductList";
import React from "react";
import Number from "./Number";

const NewServerRequestForm = () => {


    const cpuData = [
        {
            id: 1,
            label: '2',
            checked: false
        },
        {
            id: 2,
            label: '4',
            checked: false
        },
        {
            id: 3,
            label: '8',
            checked: false
        },
    ]


    const serverType = [
        {
            id: 1,
            label: 'سرور برنامه',
            checked: false
        },
        {
            id: 2,
            label: 'سرور پایگاه داده',
            checked: false
        },
        {
            id: 3,
            label: 'فایل / سرور چاپ',
            checked: false
        },
        {
            id: 4,
            label: 'وب سرور',
            checked: false
        },
    ]



    const environmentData = [
        {
            id: 1,
            label: 'تولید',
            checked: false
        },
        {
            id: 2,
            label: 'توسعه دهنده / تست',
            checked: false
        },
    ]


    const serverData = [
        {
            id: 1,
            label: 'ماشین مجازی',
            checked: false
        },
        {
            id: 2,
            label: 'سرور فیزیکی',
            checked: false
        },
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'درخواست سرور جدید'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <Heading header={'اطلاعات عمومی'}/>
                <FullName header={'نام درخواست کننده'}/>
                <Email header={'ایمیل درخواست کننده'}/>
                <Phone header={'شماره تلفن درخواست کننده'}/>
                <MultiChoice header={'نوع سرور (همه موارد اعمال شده را بررسی کنید)'} options={serverType}/>
                <SingleChoice header={'محیط (یکی را انتخاب کنید)'} options={environmentData}/>
                <Heading header={'سیستم مورد نیاز'}/>
                <SingleChoice header={'نوع سرور'} options={serverData}/>

                <ShortText header={'نام سرور'}/>
                <SingleChoice header={'تعداد CPU ها'} options={cpuData}/>
                <ShortText header={'مقدار رم (گیگابایت)'}/>
                <LongText header={'دستورالعمل ها یا درخواست های ویژه'}/>
                <Heading header={'فضای دیسک مورد نیاز (گیگابایت)'}/>
                <Number header={'C: (OS-Install) درایو:'}/>
                <Number header={'D (نصب) درایو'}/>
                <Number header={'F (داده) درایو'}/>
                <Number header={'درایو G (گزارشات)'}/>
                <Number header={'درایو H (داده‌های موقت)'}/>
                <Number header={'درایو J (گزارشات موقت)'}/>
                <Number header={'درایو R (پشتیبان‌ها)'}/>
            </Grid>
        </Grid>
    )
}



export default NewServerRequestForm