import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import DropDown from "./DropDown";
import LongText from "./LongText";
import React from "react";
import Phone from "./Phone";
import Email from "./Email";
import SingleChoice from "./SingleChoice";
import Appointment from "./Appointment";

const TelemedicineAppointmentForm = () => {

    const  typesOfTherapyData  =  [
        {
            id: 1,
            label: "درمان شخص محور (PCT)",
            checked: false
        },
        {
            id: 2,
            label: "درمان شناختی رفتاری (CBT)",
            checked: false
        },
        {
            id: 3,
            label: "فیزیوتراپی",
            checked: false
        },
        {
            id: 4,
            label: "کاردرمانی",
            checked: false
        },
        {
            id: 5,
            label: "درمان روانکاوی یا روان پویایی",
            checked: false
        },
        {
            id: 6,
            label: "درمان وجودی",
            checked: false
        },
    ]

    const stillUsingData = [
        'بله',
        'خیر',
    ]


    const healthInsurance = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]

    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم قرار ملاقات پزشکی از راه دور'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام بیمار'}/>
                <Phone header={'شماره تلفن'}/>
                <Email header={'ایمیل'}/>
                <SingleChoice header={'انواع درمان'} options={typesOfTherapyData}/>
                <Appointment header={'لطفا یک تاریخ قرار را انتخاب کنید'}/>
                <Heading header={'تاریخچه پزشکی'}/>
                <LongText header={'تشخیص/ آلرژی'} />
                <LongText header={'داروها در صورت مصرف'} />
                <DropDown header={'هنوز در حال استفاده هستید؟'} options={stillUsingData}/>
                <LongText header={'یادداشت های اضافی'} />
                <SingleChoice header={'آیا بیمه درمانی دارید؟'} options={healthInsurance}/>
                <LongText header={'یادداشت های اضافی'} />
            </Grid>
        </Grid>
    )
}

export default TelemedicineAppointmentForm;