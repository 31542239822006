import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../Component/Navbar'
import HeaderSetting from '../Component/HeaderSetting'
import { Grid, Typography } from '@mui/material'
import { Calculate, CloseSetting, ConditionArrow, ConditionPage, ConditionSetting, DeleteConditionSetting, DisableConditionSetting, Hide, Require, Show, TickSetting } from '../Component/Icons'
import SettingDrawer from '../Component/SettingDrawer'
import { Link } from 'react-router-dom'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FilteredValuesContext from '../Context/FilteredValuesContext'
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { toast } from 'react-toastify'
import CustomToast from "../Component/CustomToast/CustomToast";



const ConditionList = () => {


    const { filteredSelectValuesList, setFilteredSelectValuesList } = useContext(FilteredValuesContext);

    const [condition, setCondition] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [hoveredOptionIndex, setHoveredOptionIndex] = useState(null);
    const [hoveredOptionListIndex, setHoveredOptionListIndex] = useState(null);
    const [openIndex, setOpenIndex] = useState(null);
    const [disabledConditions, setDisabledConditions] = useState([]);
    const [able, setAble] = useState(false)


    const handleMouseEnter = (index) => setHoveredOptionIndex(index);
    const handleMouseLeave = () => setHoveredOptionIndex(null);


    const handleListMouseEnter = (index) => setHoveredOptionListIndex(index);
    const handleListMouseLeave = () => setHoveredOptionListIndex(null);


    const handleDisableCondition = (index) => {
        setAble(true)
        const conditionToDisable = filteredSelectValuesList[index];
        const newList = filteredSelectValuesList.filter((_, i) => i !== index);
        setFilteredSelectValuesList(newList);
        setDisabledConditions([...disabledConditions, conditionToDisable]);
    };

    const handleEnableCondition = (index) => {
        setAble(false)
        const conditionToEnable = disabledConditions[index];
        const newDisabledList = disabledConditions.filter((_, i) => i !== index);
        setDisabledConditions(newDisabledList);
        setFilteredSelectValuesList([...filteredSelectValuesList, conditionToEnable]);
    };


    const handleOpenOption = (index) => {
        setOpenIndex(prevIndex => (prevIndex === index ? null : index));
    };

    const handleCloseOption = () => {
        setOpenIndex(null);
    };


    const handleDeleteCondition = (index) => {
        const newList = filteredSelectValuesList.filter((_, i) => i !== index);
        setFilteredSelectValuesList(newList);
        toast(<CustomToast message={'شرط با موفقیت حذف شد'} state={'success'} />)

    };


    const handleDeleteDisableCondition = (index) => {
        const newList = disabledConditions.filter((_, i) => i !== index);
        setDisabledConditions(newList);
        toast(<CustomToast message={'شرط با موفقیت حذف شد'} state={'success'} />)

    };

    useEffect(() => {
        // Wrap the setState call inside a conditional to prevent infinite updates
        const newList = filteredSelectValuesList.filter(item => item.length > 0);
        if (newList.length !== filteredSelectValuesList.length || condition !== (newList.length > 0)) {
            setFilteredSelectValuesList(newList);
            setCondition(newList.length > 0);
        }
    }, [filteredSelectValuesList]);



    return (
        <Grid>
            <Grid display={{ xs: 'none', xxs: 'block' }}>
                <Navbar state={true} active={2} />
            </Grid>
            <Grid display={{ xs: 'block', xxs: 'none' }} >
                <HeaderSetting title={'شرایط جدید'} subTitle={'در صورت برآورده شدن یک شرط، به طور خودکار یک عمل را راه اندازی کنید'} link={'/Setting'} />
            </Grid>
            <Grid mt={{ xxs: '48px' }} position={'relative'} display={'flex'} alignItems={'flex-start'} justifyContent={'center'}>
                <Grid display={{ xs: 'none', sm: 'block' }}>
                    <SettingDrawer isActive={3} />
                </Grid>
                <Grid display={{ xs: 'none', xxs: 'block', sm: 'none' }}>
                    <SettingDrawer isActive={3} />
                </Grid>
                <Grid className='main' position={'absolute'} left={{ xs: '10px', xxs: '20px' }} mx={'auto'} width={{ xs: '100%', xxs: '75%', sm: '80%', md: '65%' }} mt={{ xs: '0px', xxs: '150px' }} pr={{ xs: '30px', xxs: '60px' }} pl={{ xs: '10px', xxs: '20px' }}>
                    <Grid mb={'24px'} className='NewCondition' display={{ xs: 'none', xxs: 'flex' }} alignItems={'center'} gap={'16px'}>
                        <Grid width={'40px'} height={'40px'} bgcolor={'rgb(10, 21, 81)'} borderRadius={'4px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
                            <ConditionPage />
                        </Grid>
                        <Grid>
                            <Typography variant='h5' fontWeight={700} color={'rgb(10, 21, 81)'} lineHeight={'24px'} >شرایط جدید</Typography>
                            <Typography variant='h6' fontWeight={500} color={'rgb(111, 118, 167)'}>در صورت برآورده شدن یک شرط، به طور خودکار یک عمل را راه اندازی کنید</Typography>
                        </Grid>
                    </Grid>
                    <Grid className='OptionCondition' mb={'14px'}>
                        <Link to={'/Setting/Conditional'}>
                            <Grid display={'flex'} alignItems={'center'} justifyContent={'center'} gap={'4px'} width={'100%'} p={'8px 0px'} bgcolor={'rgba(0,117,227,1)'} borderRadius={'4px'} >
                                <AddCircleIcon style={{ color: 'white' }} />
                                <Typography variant='h6' fontWeight={500} color={'white'}>افزودن شرط</Typography>
                            </Grid>
                        </Link>
                    </Grid>
                    <Grid width={'100%'} height={'1px'} bgcolor={'#c8ceed'} mb={'14px'}></Grid>
                    <Grid>
                        {
                            condition &&
                            filteredSelectValuesList.map((item, index) => {
                                const ifConditionIndex = item.findIndex(condition => condition.name.includes('ifCondition'));
                                const stateConditionIndex = item.findIndex(condition => condition.name.includes("StateCondition"));
                                const valueConditionIndex = item.findIndex(condition => condition.name.includes("valueCondition"));
                                const doConditionIndex = item.findIndex(condition => condition.name.includes("DoCondition"));
                                const fieldConditionIndex = item.findIndex(condition => condition.name.includes("FieldCondition"));
                                const fromConditionIndex = item.findIndex(condition => condition.name.includes("fromValue"));
                                const toConditionIndex = item.findIndex(condition => condition.name.includes("toValue"));
                                const summaryValueIndex = item.findIndex(condition => condition.name.includes("summaryValue"));
                                const calculateValueIndex = item.findIndex(condition => condition.name.includes("CalculateValue"));
                                return (
                                    <Grid
                                        className='ConditionsList'
                                        border={'1px solid #c8ceed'} borderRadius={'4px'} display={'flex'} alignItems={'center'} maxHeight={'68px'} mb={'16px'}
                                        onMouseEnter={() => setHoveredIndex(index)}
                                        onMouseLeave={() => setHoveredIndex(null)}
                                    >
                                        <Grid className='Nav' width={'26px'} p={'4px 0px'} bgcolor={item[doConditionIndex]?.value === 'پنهان کردن صفحه' || item[doConditionIndex]?.value === "نمایش پیام سفارشی" || item[doConditionIndex]?.value === "هدایت به URL" ? '#58b966':'#09f'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} borderRadius={'0px 4px 4px 0px'} height={'68px'}>
                                            <Grid display={item[doConditionIndex]?.value === 'نشان دادن' || item[doConditionIndex]?.value === 'نمایش چندگانه' ? 'block' : 'none'}>
                                                <Show fill={'white'} />
                                            </Grid>
                                            <Grid
                                                display={item[doConditionIndex]?.value === 'پنهان کردن'
                                                    || item[doConditionIndex]?.value === 'پنهان کردن چندگانه' ? 'block' : 'none'}>
                                                <Hide fill={'white'} />
                                            </Grid>
                                            <Grid
                                                display={item[doConditionIndex]?.value === 'کپی مقدار فیلد' ||
                                                    item[doConditionIndex]?.value === 'چندین مقدار فیلد را کپی کنید' ||
                                                    item[doConditionIndex]?.value === 'محاسبه مقدار فیلد' ? 'block' : 'none'}>
                                                <Calculate fill={'white'} />
                                            </Grid>
                                            <Grid
                                                display={item[doConditionIndex]?.value === 'لازم بودن'
                                                    || item[doConditionIndex]?.value === 'مورد نیاز نیست'
                                                    || item[doConditionIndex]?.value === "نیاز به چندین گزینه"
                                                    || item[doConditionIndex]?.value === "نیاز به چندین گزینه نداشته باشید"
                                                    || item[doConditionIndex]?.value === 'غیر فعال کردن' || item[doConditionIndex]?.value === 'فعال کردن'
                                                    || item[doConditionIndex]?.value === 'نمایش چندگانه' || item[doConditionIndex]?.value === 'ماسک را تنظیم کنید' ? 'block' : 'none'}>
                                                <Require fill={'white'} />
                                            </Grid>
                                            <Grid 
                                            display={
                                                item[doConditionIndex]?.value === 'پنهان کردن صفحه' || 
                                                item[doConditionIndex]?.value === "نمایش پیام سفارشی" || 
                                                item[doConditionIndex]?.value === "هدایت به URL" ? 'block':'none'
                                            }
                                            >
                                                <TickSetting fill={'white'}/>
                                            </Grid>
                                            <Grid>
                                                <Typography variant='subtitle1' color={'white'}>{index + 1}</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid width={'100%'} className='condition' p={{xs:'10px 12px'}} bgcolor={'white'} borderRadius={'4px 0px 0px 4px'} height={'68px'} position={'relative'}>
                                            <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                                <Grid width={'16px'} height={'16px'} style={{ rotate: '180deg' }}>
                                                    <ConditionArrow fill={item[doConditionIndex]?.value === 'پنهان کردن صفحه' || item[doConditionIndex]?.value === "نمایش پیام سفارشی" || item[doConditionIndex]?.value === "هدایت به URL" ? '#58b966':'#09f'} />
                                                </Grid>
                                                <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                                    <Typography variant='h6' color={item[doConditionIndex]?.value === 'پنهان کردن صفحه' || item[doConditionIndex]?.value === "نمایش پیام سفارشی" || item[doConditionIndex]?.value === "هدایت به URL" ? '#58b966':'#09f'} fontWeight={700}>اگر</Typography>
                                                    <Typography variant='h6' color={'block'} fontWeight={500}>{item[ifConditionIndex]?.value}</Typography>
                                                    <Typography variant='h6' color={item[doConditionIndex]?.value === 'پنهان کردن صفحه' || item[doConditionIndex]?.value === "نمایش پیام سفارشی" || item[doConditionIndex]?.value === "هدایت به URL" ? '#58b966':'#09f'} fontWeight={700}>{item[stateConditionIndex]?.value}</Typography>
                                                    <Typography display={valueConditionIndex === -1 ? 'none' : 'block'} variant='h6' color={'block'} fontWeight={500}>{`"${item[valueConditionIndex]?.value}"`}</Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid>
                                                <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                                    <Grid pt={'8px'}>
                                                        <Grid display={item[doConditionIndex]?.value === 'نشان دادن' || item[doConditionIndex]?.value === 'نمایش چندگانه' ? 'block' : 'none'}>
                                                            <Show fill={'#09f'} />
                                                        </Grid>
                                                        <Grid
                                                            display={item[doConditionIndex]?.value === 'پنهان کردن'
                                                                || item[doConditionIndex]?.value === 'پنهان کردن چندگانه' ? 'block' : 'none'}>
                                                            <Hide fill={'#09f'} />
                                                        </Grid>
                                                        <Grid
                                                            display={item[doConditionIndex]?.value === 'کپی مقدار فیلد' ||
                                                                item[doConditionIndex]?.value === 'چندین مقدار فیلد را کپی کنید' ||
                                                                item[doConditionIndex]?.value === 'محاسبه مقدار فیلد' ? 'block' : 'none'}>
                                                            <Calculate fill={'#09f'} />
                                                        </Grid>
                                                        <Grid
                                                            display={item[doConditionIndex]?.value === 'لازم بودن'
                                                                || item[doConditionIndex]?.value === 'مورد نیاز نیست'
                                                                || item[doConditionIndex]?.value === "نیاز به چندین گزینه"
                                                                || item[doConditionIndex]?.value === "نیاز به چندین گزینه نداشته باشید"
                                                                || item[doConditionIndex]?.value === 'غیر فعال کردن' || item[doConditionIndex]?.value === 'فعال کردن'
                                                                || item[doConditionIndex]?.value === 'نمایش چندگانه' || item[doConditionIndex]?.value === 'ماسک را تنظیم کنید' ? 'block' : 'none'}>
                                                            <Require fill={'#09f'} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                                        <Typography variant='h6' color={item[doConditionIndex]?.value === 'پنهان کردن صفحه' || item[doConditionIndex]?.value === "نمایش پیام سفارشی" || item[doConditionIndex]?.value === "هدایت به URL" ? '#58b966':'#09f'} fontWeight={700}>{item[doConditionIndex]?.value}</Typography>
                                                        <Grid display={fromConditionIndex === -1 ? 'flex' : 'none'} alignItems={'center'} gap={'8px'}>
                                                            {
                                                                Array.isArray(item[fieldConditionIndex]?.value) ?
                                                                    item[fieldConditionIndex]?.value.map(
                                                                        (title, index) => (
                                                                            <Typography pl={'0px'} variant='h6' color={'black'} fontWeight={500}>{`{${item[fieldConditionIndex]?.value[index]}}`}</Typography>

                                                                        )
                                                                    )
                                                                    :
                                                                    <Typography variant='h6' color={item[doConditionIndex]?.value === 'پنهان کردن صفحه' || item[doConditionIndex]?.value === "نمایش پیام سفارشی" || item[doConditionIndex]?.value === "هدایت به URL" ? '#58b966':'#09f'} fontWeight={700}>{item[fieldConditionIndex]?.value}</Typography>
                                                            }
                                                        </Grid>
                                                        <Grid display={fromConditionIndex !== -1 ? 'flex' : 'none'} alignItems={'center'} gap={'8px'}>
                                                            {
                                                                Array.isArray(item[fieldConditionIndex]?.value) ?
                                                                    item[fieldConditionIndex]?.value.map(
                                                                        (title, index) => (
                                                                            <Typography pl={'0px'} variant='h6' color={'block'} fontWeight={500}>{`{${item[fromConditionIndex]?.value[index]}}`}</Typography>

                                                                        )
                                                                    )
                                                                    :
                                                                    <Typography variant='h6' color={'block'} fontWeight={500}>{`{${item[fromConditionIndex]?.value}}`}</Typography>
                                                            }
                                                            <Typography variant='h6' color={item[doConditionIndex]?.value === 'پنهان کردن صفحه' || item[doConditionIndex]?.value === "نمایش پیام سفارشی" || item[doConditionIndex]?.value === "هدایت به URL" ? '#58b966':'#09f'} fontWeight={700}>به</Typography>
                                                            <Typography variant='h6' color={'block'} fontWeight={500}>{item[toConditionIndex]?.value}</Typography>
                                                        </Grid>
                                                        <Grid display={calculateValueIndex !== -1 ? 'flex' : 'none'} alignItems={'center'} gap={'8px'}>
                                                            <Typography variant='h6' color={'block'} fontWeight={700}>{item[calculateValueIndex]?.value}</Typography>
                                                            <Typography variant='h6' color={item[doConditionIndex]?.value === 'پنهان کردن صفحه' || item[doConditionIndex]?.value === "نمایش پیام سفارشی" || item[doConditionIndex]?.value === "هدایت به URL" ? '#58b966':'#09f'} fontWeight={700}>به</Typography>
                                                            <Typography variant='h6' color={'block'} fontWeight={500}>{item[summaryValueIndex]?.value}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                className='option'
                                                style={{
                                                    position: 'absolute',
                                                    left: '10px',
                                                    top: '6px',
                                                    display: hoveredIndex === index ? 'flex' : 'none',
                                                    zIndex: 10
                                                }}
                                            >
                                                <Grid width={'40px'} height={'40px'} bgcolor={'rgba(0,117,227,1)'} borderRadius={'4px'} display={openIndex !== index && hoveredIndex === index ? 'flex' : 'none'} alignItems={'center'} justifyContent={'center'} onClick={() => { handleOpenOption(index) }}>
                                                    <ConditionSetting fill={'white'} />
                                                    {/* <SettingsIcon style={{color:'white'}}/> */}
                                                </Grid>
                                                <Grid width={'40px'} height={'40px'} bgcolor={'rgba(0,117,227,1)'} borderRadius={'4px'} display={openIndex === index ? 'flex' : 'none'} alignItems={'center'} justifyContent={'center'} onClick={handleCloseOption}>
                                                    <CloseSetting fill={'white'} />
                                                </Grid>
                                            </Grid>
                                            <Grid width={'100%'} height={'100%'} position={'absolute'} right={0} top={0} bgcolor={'#3498db'} display={openIndex === index ? 'flex' : 'none'} alignItems={'center'} justifyContent={'center'} style={{ opacity: 0.8 }} gap={'48px'} zIndex={9}>
                                                <Grid width={'80px'} height={'60px'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
                                                    onMouseEnter={() => handleListMouseEnter(index + 1)}
                                                    onMouseLeave={handleListMouseLeave}
                                                    bgcolor={hoveredOptionListIndex === index + 1 ? '#ffffff4d' : 'transparent'}
                                                    borderRadius={hoveredOptionListIndex === index + 1 ? '10px' : '0px'}
                                                    onClick={() => handleDeleteCondition(index)} style={{ cursor: 'pointer' }}>
                                                    <DeleteConditionSetting fill={'white'} />
                                                    <Typography color={'white'} fontWeight={500} variant='h6' lineHeight={0} pt={'16px'}>حذف</Typography>
                                                </Grid>
                                                <Grid width={'80px'} height={'60px'} display={!able ? 'flex' : 'none'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
                                                    onMouseEnter={() => handleListMouseEnter(index + 2)}
                                                    onMouseLeave={handleListMouseLeave}
                                                    bgcolor={hoveredOptionListIndex === index + 2 ? '#ffffff4d' : 'transparent'}
                                                    borderRadius={hoveredOptionListIndex === index + 2 ? '10px' : '0px'} style={{ cursor: 'pointer' }} onClick={() => handleDisableCondition(index)}>
                                                    <DisableConditionSetting fill={'white'} />
                                                    <Typography color={'white'} fontWeight={500} variant='h6' lineHeight={0} pt={'16px'}>غیرفعال کردن</Typography>
                                                </Grid>
                                                <Grid width={'80px'} height={'60px'} display={able ? 'flex' : 'none'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
                                                    onMouseEnter={() => handleListMouseEnter(index + 3)}
                                                    onMouseLeave={handleListMouseLeave}
                                                    bgcolor={hoveredOptionListIndex === index + 3 ? '#ffffff4d' : 'transparent'}
                                                    borderRadius={hoveredOptionListIndex === index + 3 ? '10px' : '0px'} style={{ cursor: 'pointer' }} onClick={() => handleEnableCondition(index)}>
                                                    <PlayArrowIcon style={{ color: 'white' }} fontSize='large' />
                                                    <Typography color={'white'} fontWeight={500} variant='h6' lineHeight={0} pt={'16px'}>فعال کردن</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )

                            })
                        }
                        {
                            disabledConditions.length > 0 &&
                            <Grid mt={'48px'}>
                                <Typography mb={'12px'} variant='h5' fontWeight={700} color={'rgb(10, 21, 81)'}>شرط های غیرفعال</Typography>
                                {
                                    disabledConditions.map((item, index) => {
                                        const ifConditionIndex = item.findIndex(condition => condition.name.includes('ifCondition'));
                                        const stateConditionIndex = item.findIndex(condition => condition.name.includes("StateCondition"));
                                        const valueConditionIndex = item.findIndex(condition => condition.name.includes("valueCondition"));
                                        const doConditionIndex = item.findIndex(condition => condition.name.includes("DoCondition"));
                                        const fieldConditionIndex = item.findIndex(condition => condition.name.includes("FieldCondition"));
                                        const fromConditionIndex = item.findIndex(condition => condition.name.includes("fromValue"));
                                        const toConditionIndex = item.findIndex(condition => condition.name.includes("toValue"));
                                        return (
                                            <Grid
                                                className='ConditionsList'
                                                border={'1px solid #c8ceed'} borderRadius={'4px'} display={'flex'} alignItems={'center'} maxHeight={'68px'} mb={'16px'}
                                                onMouseEnter={() => setHoveredIndex(index)}
                                                onMouseLeave={() => setHoveredIndex(null)}
                                            >
                                                <Grid className='Nav' width={'26px'} p={'4px 0px'} bgcolor={'#09f'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} borderRadius={'0px 4px 4px 0px'} height={'68px'}>
                                                    <Grid display={item[doConditionIndex]?.value === 'نشان دادن' || item[doConditionIndex]?.value === 'نمایش چندگانه' ? 'block' : 'none'}>
                                                        <Show fill={'white'} />
                                                    </Grid>
                                                    <Grid
                                                        display={item[doConditionIndex]?.value === 'پنهان کردن'
                                                            || item[doConditionIndex]?.value === 'پنهان کردن چندگانه' ? 'block' : 'none'}>
                                                        <Hide fill={'white'} />
                                                    </Grid>
                                                    <Grid
                                                        display={item[doConditionIndex]?.value === 'کپی مقدار فیلد' ||
                                                            item[doConditionIndex]?.value === 'چندین مقدار فیلد را کپی کنید' ||
                                                            item[doConditionIndex]?.value === 'محاسبه مقدار فیلد' ? 'block' : 'none'}>
                                                        <Calculate fill={'white'} />
                                                    </Grid>
                                                    <Grid
                                                        display={item[doConditionIndex]?.value === 'لازم بودن'
                                                            || item[doConditionIndex]?.value === 'مورد نیاز نیست'
                                                            || item[doConditionIndex]?.value === "نیاز به چندین گزینه"
                                                            || item[doConditionIndex]?.value === "نیاز به چندین گزینه نداشته باشید"
                                                            || item[doConditionIndex]?.value === 'غیر فعال کردن' || item[doConditionIndex]?.value === 'فعال کردن'
                                                            || item[doConditionIndex]?.value === 'نمایش چندگانه' || item[doConditionIndex]?.value === 'ماسک را تنظیم کنید' ? 'block' : 'none'}>
                                                        <Require fill={'white'} />
                                                    </Grid>
                                                    <Grid>
                                                        <Typography variant='subtitle1' color={'white'}>{index + 1}</Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid width={'100%'} className='condition' p={'12px 36px 10px 54px'} bgcolor={'white'} borderRadius={'4px 0px 0px 4px'} height={'68px'} position={'relative'}>
                                                    <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                                        <Grid width={'16px'} height={'16px'} style={{ rotate: '180deg' }}>
                                                            <ConditionArrow fill={'#09f'} />
                                                        </Grid>
                                                        <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                                            <Typography variant='h6' color={'#09f'} fontWeight={700}>اگر</Typography>
                                                            <Typography variant='h6' color={'block'} fontWeight={500}>{item[ifConditionIndex]?.value}</Typography>
                                                            <Typography variant='h6' color={'#09f'} fontWeight={700}>{item[stateConditionIndex]?.value}</Typography>
                                                            <Typography display={valueConditionIndex === -1 ? 'none' : 'block'} variant='h6' color={'block'} fontWeight={500}>{`"${item[valueConditionIndex]?.value}"`}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid>
                                                        <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                                            <Grid pt={'8px'}>
                                                                <Grid display={item[doConditionIndex]?.value === 'نشان دادن' || item[doConditionIndex]?.value === 'نمایش چندگانه' ? 'block' : 'none'}>
                                                                    <Show fill={'#09f'} />
                                                                </Grid>
                                                                <Grid
                                                                    display={item[doConditionIndex]?.value === 'پنهان کردن'
                                                                        || item[doConditionIndex]?.value === 'پنهان کردن چندگانه' ? 'block' : 'none'}>
                                                                    <Hide fill={'#09f'} />
                                                                </Grid>
                                                                <Grid
                                                                    display={item[doConditionIndex]?.value === 'کپی مقدار فیلد' ||
                                                                        item[doConditionIndex]?.value === 'چندین مقدار فیلد را کپی کنید' ||
                                                                        item[doConditionIndex]?.value === 'محاسبه مقدار فیلد' ? 'block' : 'none'}>
                                                                    <Calculate fill={'#09f'} />
                                                                </Grid>
                                                                <Grid
                                                                    display={item[doConditionIndex]?.value === 'لازم بودن'
                                                                        || item[doConditionIndex]?.value === 'مورد نیاز نیست'
                                                                        || item[doConditionIndex]?.value === "نیاز به چندین گزینه"
                                                                        || item[doConditionIndex]?.value === "نیاز به چندین گزینه نداشته باشید"
                                                                        || item[doConditionIndex]?.value === 'غیر فعال کردن' || item[doConditionIndex]?.value === 'فعال کردن'
                                                                        || item[doConditionIndex]?.value === 'نمایش چندگانه' || item[doConditionIndex]?.value === 'ماسک را تنظیم کنید' ? 'block' : 'none'}>
                                                                    <Require fill={'#09f'} />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid display={'flex'} alignItems={'center'} gap={'8px'}>
                                                                <Typography variant='h6' color={'#09f'} fontWeight={700}>{item[doConditionIndex]?.value}</Typography>
                                                                <Grid display={fromConditionIndex === -1 ? 'flex' : 'none'} alignItems={'center'} gap={'8px'}>
                                                                    {
                                                                        Array.isArray(item[fieldConditionIndex]?.value) ?
                                                                            item[fieldConditionIndex]?.value.map(
                                                                                (title, index) => (
                                                                                    <Typography pl={'0px'} variant='h6' color={'black'} fontWeight={500}>{`{${item[fieldConditionIndex]?.value[index]}}`}</Typography>

                                                                                )
                                                                            )
                                                                            :
                                                                            <Typography variant='h6' color={'#09f'} fontWeight={700}>{item[fieldConditionIndex]?.value}</Typography>
                                                                    }
                                                                </Grid>
                                                                <Grid display={fromConditionIndex !== -1 ? 'flex' : 'none'} alignItems={'center'} gap={'8px'}>
                                                                    {
                                                                        Array.isArray(item[fieldConditionIndex]?.value) ?
                                                                            item[fieldConditionIndex]?.value.map(
                                                                                (title, index) => (
                                                                                    <Typography pl={'0px'} variant='h6' color={'block'} fontWeight={500}>{`{${item[fromConditionIndex]?.value[index]}}`}</Typography>

                                                                                )
                                                                            )
                                                                            :
                                                                            <Typography variant='h6' color={'block'} fontWeight={500}>{`{${item[fromConditionIndex]?.value}}`}</Typography>
                                                                    }
                                                                    <Typography variant='h6' color={'#09f'} fontWeight={700}>به</Typography>
                                                                    <Typography variant='h6' color={'block'} fontWeight={500}>{item[toConditionIndex]?.value}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        className='option'
                                                        style={{
                                                            position: 'absolute',
                                                            left: '10px',
                                                            top: '6px',
                                                            display: hoveredIndex === index ? 'flex' : 'none',
                                                            visibility: hoveredIndex === index ? 'visible' : 'hidden',
                                                            opacity: hoveredIndex === index ? 1 : 0,
                                                            transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',
                                                            zIndex: 10
                                                        }}
                                                    >
                                                        <Grid width={'40px'} height={'40px'} bgcolor={'rgba(0,117,227,1)'} borderRadius={'4px'} display={openIndex ? 'none' : 'flex'} alignItems={'center'} justifyContent={'center'} onClick={handleOpenOption}>
                                                            <ConditionSetting fill={'white'} />
                                                        </Grid>
                                                        <Grid width={'40px'} height={'40px'} bgcolor={'rgba(0,117,227,1)'} borderRadius={'4px'} display={openIndex ? 'flex' : 'none'} alignItems={'center'} justifyContent={'center'} onClick={handleCloseOption}>
                                                            <CloseSetting fill={'white'} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid width={'100%'} height={'100%'} position={'absolute'} right={0} top={0} bgcolor={'#3498db'} display={openIndex ? 'flex' : 'none'} alignItems={'center'} justifyContent={'center'} style={{ opacity: 0.8 }} gap={'48px'}>
                                                        <Grid width={'80px'} height={'60px'} display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} className='DeleteConditionOption'
                                                            onMouseEnter={() => handleMouseEnter(index + 1)}
                                                            onMouseLeave={handleMouseLeave}
                                                            bgcolor={hoveredOptionIndex === index + 1 ? '#ffffff4d' : 'transparent'}
                                                            borderRadius={hoveredOptionIndex === index + 1 ? '10px' : '0px'}
                                                            onClick={() => handleDeleteDisableCondition(index)} style={{ cursor: 'pointer' }}>
                                                            <DeleteConditionSetting fill={'white'} />
                                                            <Typography color={'white'} fontWeight={500} variant='h6' lineHeight={0} pt={'16px'}>حذف</Typography>
                                                        </Grid>
                                                        <Grid width={'80px'} height={'60px'} display={!able ? 'flex' : 'none'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} className='DeleteConditionOption'
                                                            onMouseEnter={() => handleMouseEnter(index + 2)}
                                                            onMouseLeave={handleMouseLeave}
                                                            bgcolor={hoveredOptionIndex === index + 2 ? '#ffffff4d' : 'transparent'}
                                                            borderRadius={hoveredOptionIndex === index + 2 ? '10px' : '0px'}
                                                            style={{ cursor: 'pointer' }} onClick={() => handleDisableCondition(index)}>
                                                            <DisableConditionSetting fill={'white'} />
                                                            <Typography color={'white'} fontWeight={500} variant='h6' lineHeight={0} pt={'16px'}>غیرفعال کردن</Typography>
                                                        </Grid>
                                                        <Grid width={'80px'} height={'60px'} display={able ? 'flex' : 'none'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} className='DeleteConditionOption'
                                                            onMouseEnter={() => handleMouseEnter(index + 3)}
                                                            onMouseLeave={handleMouseLeave}
                                                            bgcolor={hoveredOptionIndex === index + 3 ? '#ffffff4d' : 'transparent'}
                                                            borderRadius={hoveredOptionIndex === index + 3 ? '10px' : '0px'}
                                                            style={{ cursor: 'pointer' }} onClick={() => handleEnableCondition(index)}>
                                                            <PlayArrowIcon style={{ color: 'white' }} fontSize='large' />
                                                            <Typography color={'white'} fontWeight={500} variant='h6' lineHeight={0} pt={'16px'}>فعال کردن</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )

                                    })
                                }
                            </Grid>
                        }
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default ConditionList