import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import Phone from "./Phone";
import LongText from "./LongText";
import React from "react";
import Address from "./Address";
import Number from "./Number";
import DatePickers from "./DatePicker";
import SingleChoice from "./SingleChoice";
import ProductList from "./ProductList";

const DeliveryOrderForm = ()=>{

    const preferredDeliveryTime = [
        { id: 1, label: 'صبح (8 صبح تا 12 بعد از ظهر)', checked: false },
        { id: 2, label: 'بعد از ظهر (از ساعت 12 تا 16)', checked: false },
        { id: 3, label: 'عصر (4 بعد از ظهر تا 8 بعد از ظهر)', checked: false },
    ]


    const paymentMethodData = [
        { id: 1, label: 'پرداخت آنلاین', checked: false },
        { id: 2, label: 'پرداخت درب منزل', checked: false },
    ]


    const productList = [
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '10000',
            quantity: 1,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '10000',
            quantity: 1,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '10000',
            quantity: 1,
        },
        {
            id: Math.floor(Math.random() * 1000) + 100,
            name: 'نام محصولات',
            description: 'توضیحات را وارد کنید',
            image: null,
            price: '10000',
            quantity: 1,
        },
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم سفارش تحویل'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>

                <Heading header={'اطلاعات مشتری:'}/>
                <FullName header={'نام و نام خانوادگی'}/>
                <Phone header={'شماره تلفن'}/>
                <Email header={'ایمیل'}/>
                <Address header={'آدرس تحویل'}/>
                <Heading header={'جزئیات سفارش:'}/>
                <Number header={'شماره سفارش'}/>
                <DatePickers header={'تاریخ سفارش'}/>
                <DatePickers header={'تاریخ تحویل'}/>
                <SingleChoice header={'زمان تحویل ترجیحی'} options={preferredDeliveryTime}/>
                <ProductList header={'اقلام قابل تحویل'} product={productList}/>
                <LongText header={'دستورالعمل های ویژه'}/>
                <SingleChoice header={'روش پرداخت'} options={paymentMethodData}/>

            </Grid>
        </Grid>
    )
}


export default DeliveryOrderForm