import React from 'react'
import ResetPasswordComponent from "../Component/Password/ResetPassword";

const ResetPassword = () => {

    return (
        <ResetPasswordComponent />
    )
}

export default ResetPassword