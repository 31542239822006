import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Phone from "./Phone";
import Email from "./Email";
import SingleChoice from "./SingleChoice";
import Appointment from "./Appointment";
import LongText from "./LongText";
import DropDown from "./DropDown";
import React from "react";
import Address from "./Address";
import Number from "./Number";

const CleaningServiceAgreementForm = ()=>{

    const numberOfRoomAndBathroom = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
    ]



    const contactWithYou = [
        'تلفن همراه',
        'تلفن محل کار',
        'تلفن منزل',
        'ایمیل',
    ]


    const typeOfService = [
        { id: 1, label: 'قرار ملاقات اولیه', checked: false },
        { id: 2, label: 'قرار ملاقات یک‌باره', checked: false },
        { id: 3, label: 'خدمات هفتگی', checked: false },
        { id: 4, label: 'خدمات دو هفته یک‌بار', checked: false },
        { id: 5, label: 'خدمات ماهانه', checked: false },
        { id: 6, label: 'نظافت با فشار بالا', checked: false },
        { id: 7, label: 'سایر', checked: false },
    ]


    const gainEntrance = [
        { id: 1, label: 'کلیدها در دفتر', checked: false },
        { id: 2, label: 'کلیدها نزد نگهبان ساختمان', checked: false },
        { id: 3, label: 'شخصی دسترسی را فراهم خواهد کرد', checked: false },
        { id: 4, label: 'سایر', checked: false },
    ]


    const notifiedData = [
        { id: 1, label: 'لطفاً برای من ایمیل بفرستید', checked: false },
        { id: 2, label: 'لطفاً با من تماس بگیرید', checked: false },
        { id: 3, label: 'لطفاً ایمیل یا تماس نگیرید، من با هرگونه جایگزینی مشکلی ندارم', checked: false },
    ]

    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'قرارداد خدمات نظافت منزل'} subHeader={'امیدواریم از خدمات ما لذت ببرید و شما را تشویق می کنیم که هر گونه بازخوردی را در اختیار ما قرار دهید.'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={"نام و نام خانوادگی"}/>
                <Appointment header={'تاریخ قرار ملاقات'}/>
                <Address header={'آدرس'}/>
                <Phone header={'تلفن خانه'}/>
                <Phone header={'تلفن همراه'}/>
                <Phone header={'تلفن محل کار'}/>
                <Email header={'ایمیل'}/>
                <Number header={'مساحت خانه'}/>
                <DropDown header={'تعداد اتاق خواب‌های خانه'} options={numberOfRoomAndBathroom}/>
                <DropDown header={'تعداد سرویس‌های بهداشتی در خانه'} options={numberOfRoomAndBathroom}/>
                <DropDown header={'در صورتی که نیاز باشد در حین انجام نظافت با شما تماس بگیریم، ترجیح می‌دهید از چه راهی با شما ارتباط برقرار کنیم؟'} options={contactWithYou}/>
                <SingleChoice header={'چه نوع خدماتی شامل حال شما می‌شود؟'} options={typeOfService}/>
                <SingleChoice header={'چگونه می‌توانیم وارد شویم؟'} options={gainEntrance}/>
                <LongText header={'دستورات ویژه'} />
                <SingleChoice header={'در صورتی که نیاز باشد مشاور نظافت شما به طور موقت یا دائمی تغییر کند، ترجیح می‌دهید چگونه به شما اطلاع داده شود؟'} options={notifiedData}/>
            </Grid>
        </Grid>
    )
}


export default CleaningServiceAgreementForm