import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import ShortText from "./ShortText";
import LongText from "./LongText";
import React from "react";
import Phone from "./Phone";
import DropDown from "./DropDown";

const ServiceEnquiryForm = () => {

    const enquiryData = [
        'دریافت نشده است',
        'ترتیب نادرست',
        'سفارش آفلاین',
    ]

    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'استعلام خدمات'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={' ایمیل'}/>
                <Phone header={'شماره تلفن'}/>
                <ShortText header={'شناسه سفارش'}/>
                <DropDown header={'نوع درخواست'} options={enquiryData}/>
                <LongText header={'جزئیات'}/>
            </Grid>
        </Grid>
    )
}


export default ServiceEnquiryForm;