import {Grid} from "@mui/material";
import React from "react";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import Phone from "./Phone";
import DatePickers from "./DatePicker";
import SingleChoice from "./SingleChoice";
import Time from "./Time";
import ShortText from "./ShortText";
import Number from "./Number";
import DropDown from "./DropDown";
import LongText from "./LongText";
import FileUpload from "./FileUpload";

const BakeryOrderInquiryForm = () => {

    const pickupOrDeliveryData = [
        {
            id: 1,
            label: "تحویل",
            checked: false
        },
        {
            id: 2,
            label: "دریافت",
            checked: false
        },
    ]


    const yesOrNoData = [
        {
            id: 1,
            label: "بله",
            checked: false
        },
        {
            id: 2,
            label: "خیر",
            checked: false
        },
    ]


    const tiersData = [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
    ]


    const icingData = [
        'خامه تازه',
        'کره کرم',
        'فوندانت',
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'فرم استعلام سفارش نانوایی'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تماس'} />
                <DatePickers header={'تاریخ مورد نیاز'}/>
                <SingleChoice header={'تحویل/دریافت'} options={pickupOrDeliveryData}/>
                <Time header={'زمان'}/>
                <ShortText header={'مناسبت'}/>
                <Number header={'تعداد سرویس‌ها'}/>
                <Number header={'تعداد کاپ‌کیک‌ها'}/>
                <Number header={'تعداد کیک‌ها'}/>
                <SingleChoice header={'بسته‌بندی تکی'} options={yesOrNoData}/>
                <DropDown header={'تعداد طبقات'} options={tiersData}/>
                <LongText header={'اطلاعات اضافی'}/>
                <FileUpload header={'افزودن تصویر'}/>
                <DropDown header={'آیسینگ'} options={icingData}/>
            </Grid>
        </Grid>
    )
}

export default BakeryOrderInquiryForm;
