import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Phone from "./Phone";
import MultiChoice from "./MultiChoice";
import LongText from "./LongText";
import ShortText from "./ShortText";
import React from "react";
import Email from "./Email";
import Captcha from "./Captcha";

const InvestorsQuestionnaire = ()=>{


    const priceData = [
        {
            id: 1,
            label: '$1,000 - $50,000',
            checked: false
        },
        {
            id: 2,
            label: '$50,001 - $75,000',
            checked: false
        },
        {
            id: 3,
            label: '$75,001 - $100,000',
            checked: false
        },
        {
            id: 4,
            label: 'بیش از 100000 دلار',
            checked: false
        },

    ]


    const howMuchHouseData = [
        {
            id: 1,
            label: '1 - 5',
            checked: false
        },
        {
            id: 2,
            label: '6 - 10',
            checked: false
        },
        {
            id: 3,
            label: '11 - 15',
            checked: false
        },
        {
            id: 4,
            label: 'بالای 15',
            checked: false
        },

    ]


    const typeEstate = [
        {
            id: 1,
            label: 'خانواده مجرد',
            checked: false
        },
        {
            id: 2,
            label: 'چند خانواده',
            checked: false
        },
        {
            id: 3,
            label: 'خانه شهری',
            checked: false
        },
        {
            id: 4,
            label: 'آپارتمان مالکیتی',
            checked: false
        },
        {
            id: 5,
            label: 'تجاری دیگر',
            checked: false
        },
        {
            id: 6,
            label: 'سایر',
            checked: false
        },

    ]



    const paymentMethod = [
        {
            id: 1,
            label: 'تأمین مالی',
            checked: false
        },
        {
            id: 2,
            label: 'نقد',
            checked: false
        },

    ]


    const buyOrHold = [
        {
            id: 1,
            label: 'بازسازی و فروش مجدد',
            checked: false
        },
        {
            id: 2,
            label: 'خرید و نگه داری',
            checked: false
        },

    ]


    const cosmeticData = [
        {
            id: 1,
            label: 'بازسازی کامل ',
            checked: false
        },
        {
            id: 2,
            label: 'تعمیرات ظاهری',
            checked: false
        },

    ]


    const howAboutUsData = [
        {
            id: 1,
            label: 'مرجع شخصی',
            checked: false
        },
        {
            id: 2,
            label: 'معرفی در روز بازدید',
            checked: false
        },
        {
            id: 3,
            label: 'تماس طبقه / ورود بدون قرار قبلی',
            checked: false
        },
        {
            id: 4,
            label: 'کارت پستال',
            checked: false
        },
        {
            id: 5,
            label: 'رسانه‌های اجتماعی',
            checked: false
        },
        {
            id: 6,
            label: 'ایمیل',
            checked: false
        },
        {
            id: 7,
            label: 'سایر',
            checked: false
        },

    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'پرسشنامه سرمایه گذار'} subHeader={'لطفا فرم زیر را پر کنید.'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام سرمایه گذار'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'تلفن همراه'}/>
                <ShortText header={'نام شرکت'}/>
                <ShortText header={'وب سایت شرکت'}/>
                <LongText header={'معیارهای سرمایه گذاری: ملک سرمایه گذاری ایده آل شما چگونه است؟'}/>
                <LongText header={'مناطق یا جوامعی که به دنبال سرمایه گذاری در آنها هستید.'}/>
                <MultiChoice header={'محدوده قیمت تقریبی'} options={priceData}/>
                <MultiChoice header={'سالی چند خانه می خرید؟'} options={howMuchHouseData}/>
                <MultiChoice header={'انواع املاک مورد علاقه شما'} options={typeEstate}/>
                <MultiChoice header={'آیا از فاینانس برای پروژه های خود استفاده می کنید یا پول نقد پرداخت می کنید؟'} options={paymentMethod}/>


                <LongText header={'چه چیزی برای شما یک معامله خوب است؟'}/>
                <LongText header={'آیا حداقل تعداد اتاق خواب و/یا حمام مورد نیاز شما یا حداقل متراژ مربع وجود دارد؟'}/>
                <MultiChoice header={'آیا به دنبال املاکی برای بازسازی و فروش مجدد هستید یا قصد خرید و نگهداری دارید؟'} options={buyOrHold}/>
                <MultiChoice header={'آیا مایل به انجام توانبخشی کامل هستید یا فقط به دنبال رویه های فیکس کننده آرایشی هستید؟'} options={cosmeticData}/>

                <LongText header={'چقدر زود به دنبال خرید هستید و معمولاً چقدر زمان برای بستن نیاز دارید؟'}/>
                <LongText header={'اطلاعات اضافی که دوست دارید بدانیم'}/>
                <MultiChoice header={'چطور در مورد ما شنیدید؟'} options={howAboutUsData}/>
                <Captcha header={'پیام را همانطور که نشان داده شده است وارد کنید'}/>
            </Grid>
        </Grid>
    )
}


export default InvestorsQuestionnaire;