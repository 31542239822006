import Drawer from "react-modern-drawer";
import {Box, Button, Grid, Typography} from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import DraftsIcon from "@mui/icons-material/Drafts";
import {items} from "../Constant/MainNavbarItems";
import React, {useContext} from "react";
import {DrawerContext} from "../Context/DrawerContext";

const MobileMainDrawer = ({isOpen , toggleDrawer}) => {


    const { selectDrawerItem, setSelectDrawerItem } = useContext(DrawerContext);


    return(
        <Drawer
            open={isOpen}
            onClose={toggleDrawer}
            direction='right'
            className=''
        >
            <Box width={{ xs: '100%' }} maxWidth={{ xs: '250px', xxs: '300px' }} height={'100vh'} sx={{ bgcolor: '#f3f3fe' }} p={'4px 8px'} borderLeft={'1px solid #c8ceed'}>
                <Grid width={'100%'} borderBottom={'1px solid #c8ceed'} pb={'18px'} mt={{ xs: '36px', md: '0px' }}>
                    <Typography variant='h5' color={'#6c73a8'} p={'11px 14px'} fontWeight={700}>فرم های من</Typography>
                    <Button onClick={() => { setSelectDrawerItem('AllForm') }} sx={{ bgcolor: 'transparent', '&:hover': '#6c73a8', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '4px', p: '11px 14px', width: '100%' }}>
                        <FolderIcon style={{ color: '#1976d2' }} fontSize='large' />
                        <Typography variant='h5' color={'#0a1551'}>همه فرم ها</Typography>
                    </Button>
                </Grid>
                <Grid p={'18px 0px'} borderBottom={'1px solid #c8ceed'}>
                    <Button onClick={() => { setSelectDrawerItem('Drafts') }} sx={{ bgcolor: 'transparent', '&:hover': '#6c73a8', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '4px', p: '11px 14px', width: '100%' }}>
                        <DraftsIcon fontSize='large' />
                        <Typography variant='h5' color={'#0a1551'}>پیش نویس های من</Typography>
                    </Button>
                </Grid>
                <Grid p={'18px 0px'}>
                    {
                        items.map(
                            item =>
                                <Button onClick={() => { setSelectDrawerItem(item.enTitle) }} key={item.id} sx={{ bgcolor: 'transparent', '&:hover': '#6c73a8', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '4px', p: '11px 14px', width: '100%' }}>
                                    {item.icon}
                                    <Typography variant='h5' color={'#0a1551'}>{item.title}</Typography>
                                </Button>
                        )
                    }
                </Grid>
            </Box>
        </Drawer>
    )
}

export default MobileMainDrawer