import {Grid} from "@mui/material";
import Heading from "./Heading";
import FullName from "./FullName";
import Email from "./Email";
import LongText from "./LongText";
import React from "react";
import Phone from "./Phone";
import ScaleRating from "./ScaleRating";
import SingleChoice from "./SingleChoice";

const TechnologySurveyForRemoteLearning = () => {


    const deviceData  = [
        {
            id: 1,
            label: "لپ تاپ",
            checked: false
        },
        {
            id: 2,
            label: "دسکتاپ",
            checked: false
        },
        {
            id: 3,
            label: "تبلت",
            checked: false
        },
        {
            id: 4,
            label: "تلفن همراه",
            checked: false
        },
    ]


    return(
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'بررسی فناوری برای آموزش از راه دور'}/>
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <FullName header={'نام و نام خانوادگی'}/>
                <Email header={'ایمیل'}/>
                <Phone header={'شماره تلفن'}/>
                <ScaleRating header={'1. لطفاً نرم افزار مورد استفاده در حین یادگیری از راه دور را ارزیابی کنید.'} bestTitle={'بسیار مفید'} worthTitle={'ضعیف'}/>
                <ScaleRating header={'2. لطفاً کیفیت صدا را در حین یادگیری از راه دور ارزیابی کنید.'} bestTitle={'خیلی خوب'} worthTitle={'ضعیف'}/>
                <ScaleRating header={'3. لطفاً کیفیت ویدیو را در حین یادگیری از راه دور ارزیابی کنید.'} bestTitle={'خیلی خوب'} worthTitle={'ضعیف'}/>
                <ScaleRating header={'4. می توانم هر موضوعی را که مدرس ذکر می کند بشنوم و پیگیری کنم.'} bestTitle={'موافقم'} worthTitle={'مخالفم'}/>
                <SingleChoice header={'5. لطفا دستگاهی را که بیشتر برای یادگیری از راه دور استفاده می کنید انتخاب کنید.'} options={deviceData}/>
                <ScaleRating header={'6. لطفا سرعت اینترنت خود را ارزیابی کنید.'} bestTitle={'سریع'} worthTitle={'آرام'}/>
                <ScaleRating header={'7. من در طول جلسات با مشکلات اتصال مواجه شده ام.'} bestTitle={'موافقم'} worthTitle={'مخالفم'}/>
                <LongText header={'8. یادداشت های اضافی / بازخورد'}/>
            </Grid>
        </Grid>
    )
}


export default TechnologySurveyForRemoteLearning;