import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useInputValues } from '../Context/InputValuesContext';
import { useErrorContext } from '../Context/ErrorContext';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';


const FullName = ({ id, header }) => {

    const { drawerData } = useFormContext();


    const { inputValues } = useInputValues();
    const { setMistake } = useErrorContext();
    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);




    const location = useLocation();
    const currentPage = location.pathname;
    const existingComponentIndex = drawerData.findIndex(component => component.id === id);


    let switchHeader = header ? header : drawerData[existingComponentIndex]?.state.nameValue ? drawerData[existingComponentIndex].state.nameValue : "نام و نام خانوادگی"
    let switchSubName = drawerData[existingComponentIndex]?.state.firstNameValue ? drawerData[existingComponentIndex].state.firstNameValue : "نام"
    let switchSubLastName = drawerData[existingComponentIndex]?.state.lastNameValue ? drawerData[existingComponentIndex].state.lastNameValue : "نام خانوادگی"
    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });
    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"


    const [condition, setCondition] = useState(false)
    const [nameValue, setNameValue] = useState(switchHeader);
    const [firstNameValue, setFirstNameValue] = useState(switchSubName);
    const [lastNameValue, setLastNameValue] = useState(switchSubLastName);
    const [firstNameInput, setFirstNameInput] = useState(drawerData[existingComponentIndex].state.mainFirstNameInput)
    const [lastNameInput, setLastNameInput] = useState(drawerData[existingComponentIndex].state.mainLastNameInput)
    const [border, setBorder] = useState(false)
    const [error, setError] = useState('');
    const [errorLastName, setErrorLastName] = useState('');



    const fillInputValue = () => {

        if (drawerData.length > 0) {
            if (drawerData[existingComponentIndex]) {
                drawerData[existingComponentIndex].state.nameValue = nameValue
                drawerData[existingComponentIndex].state.firstNameValue = firstNameValue
                drawerData[existingComponentIndex].state.lastNameValue = lastNameValue
                drawerData[existingComponentIndex].state.mainFirstNameInput = firstNameInput
                drawerData[existingComponentIndex].state.mainLastNameInput = lastNameInput
                drawerData[existingComponentIndex].state.readOnly = readOnly
            }

        }

    }


    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly


    const gridRef = useRef(null);


    let theme = useTheme()

    let HeadingColor = theme.palette.blue[700]

    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === "FullName") {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }


    const handleFindErorr = () => {
        let errorIndex = inputValues.findIndex(item => item.name === "ThisFieldCanOnlyContainLetters")
        if (errorIndex !== -1) {
            setError(inputValues[errorIndex].value)
            setMistake(true)
        } else {
            setError('')
            setMistake(false)
        }
    }


    const handleFindErorrLastName = () => {
        let errorIndex = inputValues.findIndex(item => item.name === "ThisFieldCanOnlyContainLetters")
        if (errorIndex !== -1) {
            setErrorLastName(inputValues[errorIndex].value)
            setMistake(true)
        } else {
            setErrorLastName('')
            setMistake(false)
        }
    }


    const handleNameChange = (event) => {
        setNameValue(event.target.value);
    };

    const handleFirstNameChange = (event) => {
        setFirstNameValue(event.target.value);
    };


    const handleLastNameChange = (event) => {
        setLastNameValue(event.target.value);
    };


    const handleFirstNameInput = (event) => {
        const value = event.target.value;
        setFirstNameInput(value)
        if (!/^[a-zA-Zآ-ی\s]+$/.test(value)) {
            handleFindErorr()
        } else {
            setError('');
        }
    }


    const handleLastNameInput = (event) => {
        const value = event.target.value;
        setLastNameInput(value)
        if (!/^[a-zA-Zآ-ی\s]+$/.test(value)) {
            handleFindErorrLastName()
        } else {
            setErrorLastName('');
        }
    }


    const handleHeadingFocus = (event) => {
        event.target.select();
    };


    const handleOutsideClick = (event) => {
        if (gridRef.current && !gridRef.current.contains(event.target)) {
            setBorder(false);
        }
    };


    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id, nameValue, firstNameInput, firstNameValue, lastNameInput, lastNameValue , lastNameValue , firstNameValue]);


    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])


    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px') : '0px'}
            borderRadius={'3px'}
            width={'100%'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'}
            bgcolor={'white'} ref={gridRef} onClick={() => { setBorder(true) }} border={border && drawerData.length > 0 && drawerData[existingComponentIndex] && !isReadOnly ? '2px solid #0293e2' : '2px solid transparent'}>
            <Grid pb={'14px'} display={currentPage === '/build/CardForm' ? 'flex' : 'block'} alignItems={'center'} justifyContent={'center'}>
                <input className="" placeholder={'یک عنوان وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                }}
                    value={nameValue}
                    onChange={handleNameChange}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                    readOnly={isReadOnly || condition}
                />
            </Grid>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={{ xs: '8px', xxs: '24px' }} pb={'8px'} width={'100%'}>
                <Grid width={'50%'}>
                    <input style={{
                        border: '1px solid #b8bdc9',
                        borderRadius: '3px',
                        padding: '10px',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor
                    }}
                        readOnly={condition}
                        value={firstNameInput}
                        onChange={handleFirstNameInput}
                    />
                </Grid>
                <Grid width={'50%'}>
                    <input style={{
                        border: '1px solid #b8bdc9',
                        borderRadius: '3px',
                        padding: '10px',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        fontSize: '14px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor
                    }}
                        readOnly={condition}
                        value={lastNameInput}
                        onChange={handleLastNameInput}
                    />
                </Grid>
            </Grid>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={{ xs: '8px', xxs: '24px' }}>
                <input className="" placeholder={'یک SubLabel وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: '#b8bdc9'
                }}
                    value={firstNameValue}
                    onChange={handleFirstNameChange}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                    readOnly={isReadOnly || condition}
                />
                <input className="" placeholder={'یک SubLabel تایپ کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: '#b8bdc9'
                }}
                    value={lastNameValue}
                    onChange={handleLastNameChange}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                    readOnly={isReadOnly || condition}
                />
            </Grid>
            <Grid display={'flex'} alignItems={'center'} justifyContent={'space-between'} gap={{ xs: '8px', xxs: '24px' }}>
            {error !== '' && firstNameInput !== ''&&<Typography variant='subtitle1' color={'#FF0000'} fontWeight={700}>{error}</Typography>}
            {errorLastName !== '' && lastNameInput !== ''&&<Typography variant='subtitle1' color={'#FF0000'} fontWeight={700}>{error}</Typography>}
            </Grid>
        </Grid>
    )
}
export default FullName
