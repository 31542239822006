import { PreviewCalculate, PreviewCondition, PreviewConfirm, PreviewEnable, PreviewMessage, PreviewSkip } from '../Component/Icons';



export const conditionData = [
    { id: 1, title: 'نمایش/پنهان کردن فیلد', subTilte: 'قابلیت مشاهده فیلدهای فرم خاص را تغییر دهید', color: '#09f', icon: <PreviewCondition fill={'white'} />, link: '/Setting/Conditional/ShowAndHide' },
    { id: 2, title: 'به روز رسانی/محاسبه فیلد', subTilte: 'مقدار یک فیلد را کپی کنید یا محاسبات پیچیده را انجام دهید', color: '#09f', icon: <PreviewCalculate fill={'white'} />, link: '/Setting/Conditional/UpdateAndCalculate' },
    { id: 3, title: 'فعال/ضروری/پنهان‌سازی فیلد', subTilte: 'یک فیلد را الزام یا غیرفعال کنید یا یک ماسک تنظیم کنید', color: '#09f', icon: <PreviewEnable fill={'white'} />, link: '/Setting/Conditional/EnableAndRequire' },
    { id: 4, title: 'رفتن به/مخفی کردن صفحه', subTilte: 'به صفحه خاصی پرش کنید یا آن را پنهان کنید', color: '#049e38', icon: <PreviewSkip fill={'white'} />, link: '/Setting/Conditional/Skip' },
    { id: 5, title: 'تغییر صفحه تشکر', subTilte: 'سفارشی‌سازی عملکرد صفحه تشکر شما', color: '#049e38', icon: <PreviewConfirm fill={'white'} />, link: '/Setting/Conditional/ThankYou' },
    { id: 6, title: 'گیرنده ایمیل را تغییر دهید', subTilte: 'برای افراد خاصی ایمیل بفرستید', color: '#9c4dd3', icon: <PreviewMessage fill={'white'} />, link: '/Setting/Conditional/EmailRecipient' },
]