import {
    AddCreateForm,
    ImportCreateForm,
    TemplateIcon,
} from "../Component/Icons";


export const templateData = [
    {
        id: 1,
        image: <AddCreateForm fill={"#6f76a7"} />,
        title: "از صفر شروع کنید",
        subTitle: "یک لوح خالی تمام چیزی است که نیاز دارید",
        link: "/createForm/Scratch",
    },
    {
        id: 2,
        image: <TemplateIcon />,
        title: "از قالب استفاده کنید",
        subTitle: "از بیش از 10000 فرم از پیش ساخته شده انتخاب کنید",
        link: "/createForm/Template",
    },
    {
        id: 3,
        image: <ImportCreateForm />,
        title: "وارد کردن فرم",
        subTitle: "یک فرم موجود را در چند ثانیه تبدیل کنید",
        link: "/createForm/ImportForm",
    },
];