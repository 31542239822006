import React, { useContext, useEffect, useRef, useState } from 'react'
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormContext } from '../Context/Context';
import { useLocation } from 'react-router-dom';
import { useInputValues } from '../Context/InputValuesContext';
import { useErrorContext } from '../Context/ErrorContext';
import { useReadOnly } from '../Context/ReadOnlyContext';
import { FinalValuesContext } from '../Context/FinalValuesContext';
import ConvertTitle from './ConvertTitle';

const Phone = ({ id, header }) => {

    const location = useLocation();
    const currentPage = location.pathname;


    const { drawerData } = useFormContext();
    const existingComponentIndex = drawerData.findIndex(component => component.id === id);
    const { inputValues } = useInputValues();
    const { setMistake } = useErrorContext();
    const { readOnly } = useReadOnly();
    const { finalSelectValues } = useContext(FinalValuesContext);




    let titleIndex = finalSelectValues.findIndex(item => item.name === "FieldCondition")
    let title = ConvertTitle({ title: finalSelectValues[titleIndex]?.value });

    let switchHeader = header ? header : drawerData[existingComponentIndex]?.state.phoneValue ? drawerData[existingComponentIndex].state.phoneValue : "شماره تلفن"

    let switchSubHeader = drawerData[existingComponentIndex]?.state.alertValue ? drawerData[existingComponentIndex].state.alertValue : "لطفا یک شماره تلفن معتبر وارد کنید."

    let isReadOnly = currentPage === '/Publish' || currentPage === "/Publish/CardForm"
    let dataIndex = drawerData.findIndex(item => item.title === title)
    let dataCondition = drawerData[dataIndex]?.state.readOnly


    const [phoneValue, setPhoneValue] = useState(switchHeader);
    const [phoneInput, setPhoneInput] = useState(drawerData[existingComponentIndex].state.mainPhoneInput);
    const [alertValue, setAlertValue] = useState(switchSubHeader);
    const [border, setBorder] = useState(false)
    const [error, setError] = useState('');
    const [condition, setCondition] = useState(false)



    const gridRef = useRef(null);


    const fillInputValue = () => {

        if (drawerData.length > 0) {

            if (drawerData[existingComponentIndex]) {
                drawerData[existingComponentIndex].state.phoneValue = phoneValue
                drawerData[existingComponentIndex].state.mainPhoneInput = phoneInput
                drawerData[existingComponentIndex].state.alertValue = alertValue
                drawerData[existingComponentIndex].state.readOnly = readOnly
            }

        }

    }




    let theme = useTheme()
    let HeadingColor = theme.palette.blue[700]


    const handleCondition = () => {
        if (currentPage === '/Publish') {
            if (readOnly || dataCondition) {
                if (title === 'Phone') {
                    setCondition(true)
                } else {
                    setCondition(false)
                }
            } else {
                setCondition(false)
            }
        } else {
            setCondition(false)
        }

    }


    const handleFindErorr = () => {
        let errorIndex = inputValues.findIndex(item => item.name === "ThisFieldCanOnlyContainNumericValues")
        if (errorIndex !== -1) {
            setError(inputValues[errorIndex].value)
            setMistake(true)
        } else {
            setError('')
            setMistake(false)
        }
    }

    const handlePhoneChange = (event) => {
        setPhoneValue(event.target.value);
    };

    const handlePhoneInputChange = (event) => {
        const value = event.target.value;
        const containsNonNumber = isNaN(value);
        const isValidPhoneNumber = value.startsWith('09');
        const isValidPhoneNumberLenght = value.length === 11

        setPhoneInput(value);

        if (containsNonNumber) {
            handleFindErorr()
        } else if (!isValidPhoneNumber) {
            setError('شماره تلفن باید با 09 شروع شود');
        } else if (!isValidPhoneNumberLenght) {
            setError('شماره تلفن باید 11 رقم باشد')
        } else {
            setError('');
        }
    };


    const handleAlertPhoneChange = (event) => {
        setAlertValue(event.target.value);
    };

    const handleHeadingFocus = (event) => {
        // Select the value of the input when it receives focus (click)
        event.target.select();
    };


    const handleOutsideClick = (event) => {
        if (gridRef.current && !gridRef.current.contains(event.target)) {
            setBorder(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);


    useEffect(() => {
        const fetchData = async () => {
            await fillInputValue();
        };

        fetchData();
    }, [id, phoneInput, phoneValue, alertValue]);


    useEffect(() => {
        handleCondition()
    }, [finalSelectValues, readOnly])





    return (
        <Grid
            mb={currentPage === '/build' || currentPage === '/Publish' ? '28px' : '50px'}
            p={drawerData.length > 0 ? (currentPage === '/build' || currentPage === '/Publish' ? '12px 10px' : '0px') : '0px'}
            borderRadius={'3px'}
            width={'100%'}
            mx={currentPage === '/build' || currentPage === '/Publish' ? '4px' : '0px'} bgcolor={'white'}
            ref={gridRef} onClick={() => {
                setBorder(true)
            }} border={border && drawerData.length > 0 && drawerData[existingComponentIndex]&& !isReadOnly ? '2px solid #0293e2' : '2px solid transparent'}>
            <Grid pb={'16px'}>
                <input className="customHead-input" placeholder={'یک عنوان وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '16px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: HeadingColor,
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={isReadOnly || condition}
                    value={phoneValue}
                    onChange={handlePhoneChange}
                    onFocus={isReadOnly ? null : handleHeadingFocus}
                />
            </Grid>
            <Grid pb={'4px'}>
                <input
                    className="" placeholder={'(000) 000-0000'}
                    type={"text"}
                    style={{
                        border: '1px solid #b8bdc9',
                        borderRadius: '3px',
                        padding: '10px',
                        userSelect: 'text',
                        wordWrap: 'break-word',
                        whiteSpace: 'pre-wrap',
                        width: '100%',
                        fontSize: '12px',
                        fontWeight: 700,
                        lineHeight: 1.45,
                        color: HeadingColor,
                        marginBottom: '8px',
                        appearance: 'none',
                        '-moz-appearance': 'textfield',
                    }}
                    readOnly={condition}
                    value={phoneInput}
                    onChange={handlePhoneInputChange}
                />
                {error !== '' && phoneInput !== '' && <Typography variant='subtitle1' color={'#FF0000'} fontWeight={700}>{error}</Typography>}
            </Grid>
            <Grid>
                <input className="" placeholder={'یک SubLabel وارد کنید'} style={{
                    border: 'none',
                    outline: 'none',
                    userSelect: 'text',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                    width: '100%',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: 1.45,
                    color: '#b8bdc9',
                    '::placeholder': {
                        color: '#b8bdc9', // Placeholder text color
                    },
                }}
                    readOnly={isReadOnly || condition}
                    value={alertValue}
                    onChange={handleAlertPhoneChange}
                />
            </Grid>
        </Grid>
    )
}
export default Phone
