import {Grid} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const CustomToast = ({ message, state }) => {
    return (
        <Grid display="flex" alignItems="start" justifyContent="end" gap="5px" style={{
            backgroundColor: 'white',
            color: state === 'success' ? 'green' : 'red',
            fontSize: '16px',
            padding: '15px 10px',
            fontWeight: 900,
        }}>
            {state === 'success' ? <CheckCircleIcon fontSize="large" /> : <ErrorIcon fontSize="large" />}
            {message}
        </Grid>
    );
};

export default CustomToast;