import { Grid } from '@mui/material'
import React from 'react'
import Heading from './Heading'
import DatePickers from './DatePicker'
import Time from './Time';
import Number from './Number';
import FullName from './FullName';
import DropDown from './DropDown';
import Phone from './Phone';
import Email from './Email';
import Address from './Address';
import SingleChoice from './SingleChoice';
import ShortText from './ShortText';
import LongText from './LongText';

const HospitalPatientRegistrationForm = () => {


    const genderData = ['آقا', 'خانم']


    const yesOrNoHomeData = [
        { id: 1, label: 'بله', checked: false },
        { id: 2, label: 'خیر', checked: false },
    ]


    const  maritalStatusData  = [
        'مجرد',
        'متاهل',
        'طلاق گرفته',
        'قانونی جدا شده است',
        'همسر فوت شده',
    ]


    return (
        <Grid p={{ xs: '40px 13px', md: '40px 52px' }} bgcolor={'white'} borderRadius={'20px'} width={'100%'} maxWidth={'700px'} boxShadow={'0 4px 4px rgba(87,100,126,.21)'} m={{ xs: '72px 0px', md: '72px 42px' }}>
            <Heading header={'ثبت نام بیمار جدید'} subHeader={'لطفا فرم زیر را پر کنید'} />
            <Grid className='states' display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'}>
                <DatePickers header={'تاریخ ثبت نام'} />
                <Time header={'زمان ثبت نام'} />
                <Number header={'شماره مراقبت های بهداشتی'} />
                <FullName header={'نام بیمار'} />
                <DropDown header={'جنسیت'} options={genderData} />
                <DatePickers header={'تاریخ تولد'} />

                <Phone header={'شماره تلفن'} />
                <Email header={'ایمیل'} />
                <Address />
                <DropDown header={'وضعیت تأهل'} options={maritalStatusData}/>

                <SingleChoice header={'آیا بیمار کمتر از 18 سال دارد؟'} options={yesOrNoHomeData} />
                <Heading header={'تماس اضطراری'} />
                <FullName header={'تماس اضطراری'} />
                <ShortText header={'نسبت'} />

                <Phone header={'شماره تماس'} />
                <FullName header={'نام پزشک خانواده'} />
                <Phone header={'شماره تلفن پزشک خانواده'} />
                <ShortText header={'داروخانه انتخاب شده'} />

                <Phone header={'شماره تلفن داروخانه'} />
                <Heading header={'تاریخچه سلامت'} />
                <ShortText header={'دلیل ثبت نام'} />
                <LongText header={'یادداشت های اضافی'} />

                <SingleChoice header={'در حال حاضر دارویی مصرف می کنید؟'} options={yesOrNoHomeData} />
                <Heading header={'اطلاعات بیمه'} />
                <ShortText header={'شرکت بیمه'} />
                <ShortText header={'شناسه بیمه'} />

                <FullName header={'نام دارنده بیمه نامه'} />
                <DatePickers header={'تاریخ تولد'} />
                
            </Grid>
        </Grid>
    )
}

export default HospitalPatientRegistrationForm